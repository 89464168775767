import { Injectable } from '@angular/core';
import { IDB_KEY, UserBasicVm, BLANK_USER_DATA } from '@mixidea-client/model';
import { IndexedDbService } from './service/indexed-db.service';

@Injectable()
export class IndexedDbApi {
  constructor(private indexedDbService: IndexedDbService) {}

  async store_users(user_id: string, user: UserBasicVm): Promise<void> {
    return await this.indexedDbService.store<UserBasicVm>(IDB_KEY.USER_MODEL, user_id, user);
  }

  async retrieve_users(user_id: string): Promise<UserBasicVm> {
    const user_data_vm = await this.indexedDbService.retrieve<UserBasicVm>(IDB_KEY.USER_MODEL, user_id);
    if (user_data_vm) {
      if (!user_data_vm.full_name || !user_data_vm.short_name || !user_data_vm.pict_src) {
        console.log('Indexed DB some value lack', user_data_vm);
      }
      return {
        full_name: user_data_vm.full_name || BLANK_USER_DATA.full_name,
        short_name: user_data_vm.short_name || BLANK_USER_DATA.short_name,
        pict_src: user_data_vm.pict_src || BLANK_USER_DATA.pict_src,
      };
    } else {
      return null;
    }
  }

  async remove_all_users() {
    return await this.indexedDbService.removeAll(IDB_KEY.USER_MODEL);
  }
}
