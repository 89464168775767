import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { FirebaseRtdService } from './service/firebase-rtd.service';

import {
  AuditriumInfo,
  AuditriumAllocationTemporalInfo,
  AllAuditriumAllocateUserDb,
  OrganizerMessage,
  DEBATE_STYLE,
  ALLOCATION_PROCESS,
  OrgnizerMessageSource,
  RoomProgress,
  LAYOUT_TYPE_IN_AUDITORIUM_ID,
} from '@mixidea-client/model';

@Injectable({
  providedIn: 'root',
})
export class AuditriumFirebaseService {
  constructor(private firebaseRtdService: FirebaseRtdService) {}

  monitorAuditrium(router_id: string): Observable<AuditriumInfo> {
    const path = '/event_related/auditrium/' + router_id;
    return this.firebaseRtdService.getObjectValue(path);
  }

  addAllocateTeamRoomUser(
    router_id: string,
    layout_type_id: LAYOUT_TYPE_IN_AUDITORIUM_ID,
    room_id: string,
    allocation_room_team: string,
    userid: string,
  ) {
    const value = true;
    const path = `/event_related/auditrium_allocation_temporal_room_users/${router_id}/${layout_type_id}/${room_id}/${allocation_room_team}/allocated_user/${userid}`;
    return this.firebaseRtdService.setObject(path, value);
  }

  addAllocateTeamRegisteredTeam(
    router_id: string,
    layout_type_id: LAYOUT_TYPE_IN_AUDITORIUM_ID,
    room_id: string,
    allocation_room_team: string,
    registered_teamid: string,
  ) {
    const value = true;
    const path = `/event_related/auditrium_allocation_temporal_room_users/${router_id}/${layout_type_id}/${room_id}/${allocation_room_team}/allocated_team/${registered_teamid}`;
    return this.firebaseRtdService.setObject(path, value);
  }

  removeAllocateTeamRoomUser(
    router_id: string,
    layout_type_id: LAYOUT_TYPE_IN_AUDITORIUM_ID,
    room_id: string,
    allocation_room_team: string,
    userid: string,
  ): Promise<void> {
    const path = `/event_related/auditrium_allocation_temporal_room_users/${router_id}/${layout_type_id}/${room_id}/${allocation_room_team}/allocated_user/${userid}`;
    return this.firebaseRtdService.removeObject(path);
  }

  removeAllocateTeamRegisteredTeam(
    router_id: string,
    layout_type_id: LAYOUT_TYPE_IN_AUDITORIUM_ID,
    room_id: string,
    allocation_room_team: string,
    teamid: string,
  ): Promise<void> {
    const path = `/event_related/auditrium_allocation_temporal_room_users/${router_id}/${layout_type_id}/${room_id}/${allocation_room_team}/allocated_team/${teamid}`;
    return this.firebaseRtdService.removeObject(path);
  }

  deleteAllocatedUsersInEachRoom(router_id: string, layout_type_id: LAYOUT_TYPE_IN_AUDITORIUM_ID, room_id: string): Promise<void> {
    const path = `/event_related/auditrium_allocation_temporal_room_users/${router_id}/${layout_type_id}/${room_id}`;
    return this.firebaseRtdService.removeObject(path);
  }

  deleteAllocatedUsersAll(router_id: string): Promise<void> {
    const path = `/event_related/auditrium_allocation_temporal_room_users/${router_id}/`;
    return this.firebaseRtdService.removeObject(path);
  }

  setAllocationProcess(router_id: string, process: ALLOCATION_PROCESS) {
    const path = `/event_related/auditrium_allocation_temporal/${router_id}/process`;
    return this.firebaseRtdService.setObject(path, process);
  }

  monitorAuditriumAllocationTemporal(router_id: string): Observable<AuditriumAllocationTemporalInfo> {
    const path = '/event_related/auditrium_allocation_temporal/' + router_id;
    return this.firebaseRtdService.getObjectValue(path);
  }

  monitorAuditriumAllocationTemporalRoomUsers(router_id: string): Observable<AllAuditriumAllocateUserDb> {
    const path = '/event_related/auditrium_allocation_temporal_room_users/' + router_id;
    return this.firebaseRtdService.getObjectValue(path);
  }

  set_allocation_roomuser(router_id: string, layout_type_id: LAYOUT_TYPE_IN_AUDITORIUM_ID, allocation_data: any): Promise<void> {
    const path = `/event_related/auditrium/${router_id}/allocation_rounds_infos/${layout_type_id}/`;
    return this.firebaseRtdService.setObject(path, allocation_data);
  }

  save_organizer_message(router_id: string, organizer_message_pure: OrgnizerMessageSource): Promise<void> {
    const path = `/event_related/auditrium-organizer-message-2/${router_id}/source/`;
    return this.firebaseRtdService.setObject(path, organizer_message_pure);
  }

  get_organizer_message(router_id: string): Observable<OrganizerMessage> {
    const path = `/event_related/auditrium-organizer-message-2/${router_id}/`;
    return this.firebaseRtdService.getObjectValue(path);
  }

  getGameInAuditriumProgress(router_id: string): Observable<RoomProgress> {
    const path = `/event_related/game-in-auditrium-progress/${router_id}/`;
    return this.firebaseRtdService.getObjectValue(path);
  }

  setTemporalInfoRoomInfoMaxNumber(router_id: string, number: number) {
    const value = number;
    const path = `/event_related/auditrium_allocation_temporal/${router_id}/max_number_room_num_in_auditrium/`;
    return this.firebaseRtdService.setObject(path, value);
  }

  changeAllocationDefaultDebStyle(router_id: string, default_deb_style: DEBATE_STYLE) {
    const value = default_deb_style;
    const path = `/event_related/auditrium_allocation_temporal/${router_id}/default_deb_style/`;
    return this.firebaseRtdService.setObject(path, value);
  }

  changeDebateStyleEachRoom(router_id: string, room_id: string, deb_style: DEBATE_STYLE) {
    const path = `/event_related/auditrium_allocation_temporal/${router_id}/eachroom_debstyle/${room_id}`;
    return this.firebaseRtdService.setObject(path, deb_style);
  }
}
