export const ARTICLETYPE_ONLINE_LIVEVIDEO_clientrecognition = 'ARTICLETYPE_ONLINE_LIVEVIDEO_clientrecognition';
export const ARTICLETYPE_ONLINE_LIVEVIDEO_serverrecognition = 'ARTICLETYPE_ONLINE_LIVEVIDEO_serverrecognition';
export const ARTICLETYPE_ONLINE_LIVEVIDEO_serverrecognition_2 = 'ARTICLETYPE_ONLINE_LIVEVIDEO_serverrecognition_2';
export const ARTICLETYPE_ONLINE_LIVEVIDEO_prepdoc = 'ARTICLETYPE_ONLINE_LIVEVIDEO_prepdoc';
export const ARTICLETYPE_WRITTENDEBATE = 'ARTICLETYPE_WRITTENDEBATE';
export const ARTICLETYPE_WRITTENDEBATE2 = 'ARTICLETYPE_WRITTENDEBATE2';

export enum ARTICLETYPE {
  ONLINE_LIVEVIDEO_clientrecognition = 'ARTICLETYPE_ONLINE_LIVEVIDEO_clientrecognition',
  ONLINE_LIVEVIDEO_serverrecognition = 'ARTICLETYPE_ONLINE_LIVEVIDEO_serverrecognition',
  ONLINE_LIVEVIDEO_serverrecognition_2 = 'ARTICLETYPE_ONLINE_LIVEVIDEO_serverrecognition_2',
  ONLINE_LIVEVIDEO_headresschrome = 'ARTICLETYPE_ONLINE_LIVEVIDEO_headresschrome',
  ONLINE_LIVEVIDEO_prepdoc = 'ARTICLETYPE_ONLINE_LIVEVIDEO_prepdoc',
  WRITTENDEBATE = 'ARTICLETYPE_WRITTENDEBATE',
  WRITTENDEBATE2 = 'ARTICLETYPE_WRITTENDEBATE2',
}
