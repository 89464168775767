import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IDB_KEY, UserBasicVm, BLANK_USER_DATA } from '@mixidea-client/model';

@Injectable()
export class IndexedDbService {
  private _db: IDBDatabase;
  get db(): IDBDatabase {
    return this._db;
  }
  set db(idb: IDBDatabase) {
    this._db = idb;
    idb.onabort = (error) => {
      console.log('IDBDatabase.onabort', error);
      this._db = null;
    };
    idb.onerror = (error) => {
      console.log('IDBDatabase.onerror', error);
      this._db = null;
    };
    idb.onversionchange = (error) => {
      console.log('IDBDatabase.onversionchange', error);
    };
  }

  constructor() {}

  private open_db(): Promise<IDBDatabase> {
    return new Promise((resolve, reject) => {
      if (this.db) {
        resolve(this.db);
      } else if (!window.indexedDB) {
        resolve(null);
      } else {
        let idb: IDBDatabase;
        const open_request = indexedDB.open(IDB_KEY.IDB_NAME, 1);
        open_request.onupgradeneeded = (event) => {
          // console.log('IDBOpenDBRequest.onupgradeneeded', event);
          const event_request = event.target as IDBRequest;
          idb = event_request.result;
          this.db = idb;
          const store = this.db.createObjectStore('model-user');
          resolve(this.db);
        };
        open_request.onsuccess = (event) => {
          // console.log('IDBOpenDBRequest.onsuccess', event);
          const request = event.target as IDBRequest;
          idb = request.result;
          this.db = idb;
          resolve(this.db);
        };
        open_request.onerror = (event) => {
          console.log('IDBOpenDBRequest.onerror', event);
          resolve(null);
        };
      }
    });
  }

  private add<T>(db: IDBDatabase, model_name: string, value: T, key: any): Promise<void> {
    return new Promise((resolve, reject) => {
      const transaction = db.transaction(model_name, 'readwrite');
      const store = transaction.objectStore(model_name);
      const request = store.add(value, key);
      request.onsuccess = (event) => {
        const event_request = event.target as IDBRequest;
        // console.log(event_request.result);
        resolve();
      };
      request.onerror = (event) => {
        console.log('Indexed DB Error', event);
        reject(event);
      };
    });
  }

  private get<T>(db: IDBDatabase, model_name: string, key: any): Promise<T> {
    return new Promise((resolve, reject) => {
      const transaction = db.transaction(model_name, 'readonly');
      const store = transaction.objectStore(model_name);
      const request = store.get(key);
      request.onsuccess = (event) => {
        const event_request = event.target as IDBRequest;
        // console.log(event_request.result);
        resolve(event_request.result);
      };
      request.onerror = (event) => {
        console.log('Indexed DB Error', event);
        reject(event);
      };
    });
  }

  private clear(db: IDBDatabase, model_name: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const transaction = db.transaction(model_name, 'readwrite');
      const store = transaction.objectStore(model_name);
      const request = store.clear();
      request.onsuccess = (event) => {
        const event_request = event.target as IDBRequest;
        // console.log(event_request.result);
        resolve(event_request.result);
      };
      request.onerror = (event) => {
        console.log('Indexed DB Error', event);
        reject(event);
      };
    });
  }

  async store<T>(model: string, key: string, value: T): Promise<void> {
    try {
      const db = await this.open_db();
      if (!db) {
        return;
      }
      return await this.add<T>(db, model, value, key);
    } catch (error) {
      console.log('Indexed DB Error', error);
    }
  }

  async retrieve<T>(model: string, key: string): Promise<T> {
    try {
      const db = await this.open_db();
      if (!db) {
        return null;
      }
      return await this.get<T>(db, model, key);
    } catch (error) {
      console.log('Indexed DB Error', error);
    }
  }

  async removeAll(model: string): Promise<void> {
    try {
      const db = await this.open_db();
      if (!db) {
        return null;
      }
      return await this.clear(db, model);
    } catch (error) {
      console.log('Indexed DB Error', error);
    }
  }
}
