import { Injectable } from '@angular/core';

import { AngularFirestore } from '@angular/fire/compat/firestore';

export interface BatchObject {
  deleteArr: BatchUnit[];
  updateArr: BatchUnit[];
  setArr: BatchUnit[];
}

export interface BatchPath {
  collection_path: string;
  doc_id: string;
}

export interface BatchUnit {
  path: BatchPath[];
  obj?: any;
}

@Injectable({
  providedIn: 'root',
})
export class FirestorePureService {
  constructor(private angularFirestore: AngularFirestore) {}

  async batch_common_obj(batch_obj: BatchObject): Promise<void> {
    const batch = this.angularFirestore.firestore.batch();

    batch_obj.deleteArr.forEach((delete_obj: BatchUnit) => {
      let ref = null;
      delete_obj.path.forEach((path: BatchPath) => {
        ref = ref || this.angularFirestore.firestore;
        ref = ref.collection(path.collection_path).doc(path.doc_id);
      });
      batch.delete(ref);
    });

    batch_obj.updateArr.forEach((update_obj: BatchUnit) => {
      let ref = null;
      update_obj.path.forEach((path: BatchPath) => {
        ref = ref || this.angularFirestore.firestore;
        ref = ref.collection(path.collection_path).doc(path.doc_id);
      });
      batch.update(ref, update_obj.obj);
    });

    batch_obj.setArr.forEach((set_obj: BatchUnit) => {
      let ref = null;
      set_obj.path.forEach((path: BatchPath) => {
        ref = ref || this.angularFirestore.firestore;
        if (path.doc_id) {
          ref = ref.collection(path.collection_path).doc(path.doc_id);
        } else {
          ref = ref.collection(path.collection_path).doc();
        }
      });
      batch.set(ref, set_obj.obj);
    });

    return batch.commit();
  }
}
