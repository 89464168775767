export enum VIDEO_QUALITY {
  LOW = 'LOW',
  MIDDLE = 'MIDDLE',
  HIGH = 'HIGH',
  BIGHIGH = 'BIGHIGH',
}

export const MAX_FRAME_RATE = 12;
export const MAX_FRAME_RATE_AUDIO_MUTED = 2;
export const MAX_FRAME_RATE_AUDIO_MUTED_MANY_PEOPLE = 1;

export interface VIDEO_QUALITY_PARAMETER {
  WIDTH: number;
  HEIGHT: number;
  FRAMERATE: number;
}

export const VIDEO_SIZE = {
  RECEIVE: {
    [VIDEO_QUALITY.LOW]: {
      WIDTH: 240,
      HEIGHT: 240,
    },
    [VIDEO_QUALITY.MIDDLE]: {
      WIDTH: 240,
      HEIGHT: 240,
    },
    [VIDEO_QUALITY.HIGH]: {
      WIDTH: 240,
      HEIGHT: 240,
    },
    [VIDEO_QUALITY.BIGHIGH]: {
      WIDTH: 480,
      HEIGHT: 480,
    },
  },
  SEND: {
    [VIDEO_QUALITY.LOW]: {
      WIDTH: 120,
      HEIGHT: 120,
      FRAMERATE: 1,
    },
    [VIDEO_QUALITY.MIDDLE]: {
      WIDTH: 240,
      HEIGHT: 240,
      FRAMERATE: 6,
    },
    [VIDEO_QUALITY.HIGH]: {
      WIDTH: 240,
      HEIGHT: 240,
      FRAMERATE: 24,
    },
    [VIDEO_QUALITY.BIGHIGH]: {
      WIDTH: 480,
      HEIGHT: 480,
      FRAMERATE: 30,
    },
  },
};

// export const LS_KEY_DISABLE_VIDEO = 'LS_KEY_DISABLE_VIDEO';
