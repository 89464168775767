import { Pipe, PipeTransform } from '@angular/core';

import { ROLE_SIDE_MAPPING } from '@mixidea-client/model';

@Pipe({
  name: 'roleSide',
  pure: true,
})
export class RoleSidePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (ROLE_SIDE_MAPPING[value]) {
      return ROLE_SIDE_MAPPING[value];
    }
  }
}
