export interface JistiData {
  num: number;
  room_id: string;
}

export enum TIMER_STATUS {
  SUSPENDED = 'SUSPENDED',
  STOPPED = 'STOPPED',
  PLAY = 'PLAY',
  RESUMED = 'RESUMED',
}

export interface JistiTimer {
  start_time?: number;
  suspended_duration?: string;
  resume_previous_duration?: number;
  status?: TIMER_STATUS;
}
