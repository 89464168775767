import { VIDEO_QUALITY } from '../constant/video';

export enum WEBRTC_PLATFORM {
  AGORA = 'AGORA',
  SKYWAY = 'SKYWAY',
}

export enum WEBRTC_COMMUNICATION_TYPE {
  SFU = 'SFU',
  FULLMESH = 'FULLMESH',
}

export enum WEBRTC_MEDIA_DEVICE {
  AUDIO_ONLY = 'AUDIO_ONLY',
  AUDIO_AND_VIDEO = 'AUDIO_AND_VIDEO',
}

export interface WebrtcCondition {
  media_device: WEBRTC_MEDIA_DEVICE;
  platform: WEBRTC_PLATFORM;
  api_id: number;
  large_event: boolean;
  initially_receive_only_mode: boolean;
  auditorium_default: boolean;
  // use_video_auditorium: boolean;
  video_quality: VIDEO_QUALITY;
  webrtc_communication_type: WEBRTC_COMMUNICATION_TYPE;
}
