export interface PreparationDocumentAllDb {
  motion?: string;
  proposition?: PreparationDocument;
  opposition?: PreparationDocument;
  gov?: PreparationDocument;
  opp?: PreparationDocument;
  OG?: PreparationDocument;
  OO?: PreparationDocument;
  CG?: PreparationDocument;
  CO?: PreparationDocument;
}

// export interface PreparationDocumentAllVm {
//   prep_doc_na_gov?: PreparationDocumentVm, prep_doc_na_opp?: PreparationDocumentVm,
//   prep_doc_asian_prop?: PreparationDocumentVm, prep_doc_asian_opp?: PreparationDocumentVm,
//   prep_doc_bp_og?: PreparationDocumentVm,  prep_doc_bp_oo?: PreparationDocumentVm,
//   prep_doc_bp_cg?: PreparationDocumentVm,  prep_doc_bp_co?: PreparationDocumentVm
// }

/****************** */

export interface PreparationDocument {
  intro?: PrepDocEach;
  argument?: { [key: string]: PrepArg };
}

export interface PrepArg {
  signpost?: PrepDocEach;
  main_context?: PrepDocEach;
  refute?: PrepDocEach;
}

export interface PrepDocEach {
  editor?: { [key: string]: boolean };
  text?: string;
}

/****************** */

// export interface PreparationDocumentVm {
//   intro?: PrepDocEachVm;
//   argument?: PrepArgVm[];
//   arg_num?: number;
// }

// export interface PreparationDocumentVm {
//   intro?: PrepDocEachVm;
//   argument?:  {[key: string]: PrepArgVm};
//   arg_num?: number;
// }

// export interface PrepArgVm {
//   signpost?: PrepDocEachVm;
//   main_context?: PrepDocEachVm;
//   refute?: PrepDocEachVm;
// }

// // type UserId = string;

// // export interface PrepDocEachVm {
// //   editor?: UserId[];
// //   text?: string;
// // }

// export interface PrepDocEachVm {
//   editor?: {[key: string]: boolean};
//   text?: string;
// }

/****************** */

// export const PREP_DOC_EACH_BLANK: PrepDocEachDb = {
//   editor: {},
//   text: ''
// }

// export const PREP_ARG_BLANK: PrepArgDb = {
//   signpost: PREP_DOC_EACH_BLANK,
//   main_context: PREP_DOC_EACH_BLANK
// }

// export const PREP_DOC_BLANK: PreparationDocumentDb = {
//   intro: PREP_DOC_EACH_BLANK,
//   argument: {}
// }

/****************** */

// export const PREPDOC_ARG_MAINCONTEXT = 'main_context';
// export const PREPDOC_ARG_SIGNPOST = 'signpost';
// export const PREPDOC_ARG_REFUTE = 'refute';
// export const PREPDOC_INTRODUCTION = 'introduction';

export enum PREPDOC {
  ARG_MAINCONTEXT = 'main_context',
  ARG_SIGNPOST = 'signpost',
  ARG_REFUTE = 'refute',
  INTRODUCTION = 'introduction',
}
