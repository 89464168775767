export enum TEAM_NAME {
  NA_GOV = 'NA_GOV',
  NA_OPP = 'NA_OPP',
  PDA_GOV = 'PDA_GOV',
  PDA_OPP = 'PDA_OPP',
  WSC_GOV = 'WSC_GOV',
  WSC_OPP = 'WSC_OPP',
  WSDC_PROPOSITION = 'WSDC_PROPOSITION',
  WSDC_OPPOSITION = 'WSDC_OPPOSITION',
  WSDC_SHORT_PROPOSITION = 'WSDC_SHORT_PROPOSITION',
  WSDC_SHORT_OPPOSITION = 'WSDC_SHORT_OPPOSITION',
  ASIAN_PROPOSITION = 'ASIAN_PROPOSITION',
  ASIAN_OPPOSITION = 'ASIAN_OPPOSITION',
  BP_OG = 'BP_OG',
  BP_OO = 'BP_OO',
  BP_CG = 'BP_CG',
  BP_CO = 'BP_CO',
  PHS_PROPOSITION = 'PHS_PROPOSITION',
  PHS_OPPOSITION = 'PHS_OPPOSITION',
  Audience = 'Audience',
  Judge = 'Judge',
  WrittenDebate_PROPOSITION = 'proposition',
  WrittenDebate_OPPOSITION = 'opposition',
}
