import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'showTime',
  pure: true,
})
export class ShowTimePipe implements PipeTransform {
  transform(time_spent_seconds: number, args?: any): any {
    if (time_spent_seconds < 1) {
      return '0:00';
    }
    const speech_time_seconds = time_spent_seconds % 60;
    const speech_time_minutes = Math.floor(time_spent_seconds / 60);
    if (speech_time_seconds < 10) {
      return String(speech_time_minutes) + ':0' + String(speech_time_seconds);
    } else {
      return String(speech_time_minutes) + ':' + String(speech_time_seconds);
    }
  }
}
