import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { EachMessageChatOrPostWithKeyWithOwn } from '@mixidea-client/model';

@Component({
  selector: 'app-each-base',
  template: '',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EachBaseComponent implements OnInit {
  private _chat_item: EachMessageChatOrPostWithKeyWithOwn;
  chat_type = 'other';

  constructor() {}

  ngOnInit() {
    this.extended_init();
  }

  @Input()
  set chat_item(chat_item: EachMessageChatOrPostWithKeyWithOwn) {
    this._chat_item = chat_item;
    if (this._chat_item && chat_item.is_own_message) {
      this.chat_type = 'own';
    }
  }

  get chat_item() {
    return this._chat_item;
  }

  extended_init() {}
}
