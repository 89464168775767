import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// import { ThenableReference} from '@firebase/database-types';

import { FirebaseRtdApi } from '@mixidea-client/firebase-rtd-lib';
import { FirebaseStoragedApi } from '@mixidea-client/firebase-rtd-lib';
import { UserauthService } from './userauth.service';
// import { SoundPlayService } from '../service/sound-play.service';
import {
  CHAT_TYPE,
  CHAT_TEAM,
  CHAT_TEAM_TYPE,
  EachMessageChatOrPost,
  EachMessageChatOrPostWithKey,
  OgpChatOrPostWithKey,
  AttachmentChatOrPostWithKey,
} from '@mixidea-client/model';

@Injectable()
export class ChatService {
  constructor(
    private firebaseRtdApi: FirebaseRtdApi,
    private firebaseStoragedApi: FirebaseStoragedApi,
    private user_auth: UserauthService,
  ) {}

  get_event_chat_all(
    event_id: string,
    ascending = true,
  ): Observable<EachMessageChatOrPostWithKey[] | AttachmentChatOrPostWithKey[] | OgpChatOrPostWithKey[]> {
    return this.get_event_chat_team(event_id, CHAT_TEAM.ALL, ascending);
  }

  get_event_chat_team(
    event_id: string,
    chat_team: CHAT_TEAM_TYPE,
    ascending = true,
  ): Observable<EachMessageChatOrPostWithKey[] | AttachmentChatOrPostWithKey[] | OgpChatOrPostWithKey[]> {
    const own_userid = this.user_auth.get_own_userid();
    if (ascending) {
      return this.firebaseRtdApi.getEventPost(event_id).pipe(
        map((chat_items) => {
          return chat_items.filter((chat_item) => chat_item.team === chat_team);
        }),
        map((chat_items) => {
          if (chat_items && chat_items.length > 0 && chat_items[chat_items.length - 1].user_id === own_userid) {
            chat_items[chat_items.length - 1].own_chat = true;
          }
          return chat_items;
        }),
      );
    } else {
      return this.firebaseRtdApi.getEventPostDescendent(event_id).pipe(
        map((chat_items) => {
          return chat_items.filter((chat_item) => chat_item.team === chat_team);
        }),
      );
    }
  }

  delete_chat(event_id, key) {
    this.firebaseRtdApi.deletePost(event_id, key);
  }

  attach_file(event_id: string, file): PromiseLike<any> {
    return this.firebaseStoragedApi.storeFileEventChatAttachment(event_id, file).then((download_url: string) => {
      if (download_url) {
        const user_id = this.user_auth.get_own_userid();
        const file_name = file.name;
        return this.firebaseRtdApi.pushFileInfoEventChat(event_id, user_id, file_name, download_url);
      } else {
        alert('fail to upload. pleas try again');
      }
    });
  }

  push_event_chat(event_id: string, team: CHAT_TEAM_TYPE, message: string): PromiseLike<void> {
    const message_converted = message.replace(/\r?\n/g, '<br>');
    const post: EachMessageChatOrPost = {
      type: CHAT_TYPE.MESSAGE,
      team: team,
      user_id: this.user_auth.get_own_userid(),
      content: message_converted,
      date: Date.now(),
    };
    return this.firebaseRtdApi.pushEventPost(event_id, post);
  }
}
