import { Injectable } from '@angular/core';

import firebase from 'firebase/compat/app';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  OwnUser,
  UserFbToken,
  UserTwitterToken,
  LS_KEY_USER_NAME_SET,
  LS_KEY_LOGGEDIN,
  UserNameInputData,
  EMPTY_OWN_USER,
  getBlankNavigationExtra,
} from '@mixidea-client/model';
import { FirebaseRtdApi, FirebaseRtdPureService } from '@mixidea-client/firebase-rtd-lib';

import { SetUsernameModalComponent } from '../../shared/set-username-modal/set-username-modal.component';

import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { take, filter, map } from 'rxjs/operators';

import { LoggerService, LogParam, LOG_LEVEL, LOG_TECH, LOG_MODULE, LOG_TYPE } from '@mixidea-client/logger';

enum LOGIN_TYPE_ORIGIN {
  REDIRECT = 'REDIRECT',
  TWITTER_POPUP = 'TWITTER_POPUP',
  FACEBOOK_POPUP = 'FACEBOOK_POPUP',
}

export enum UserLoginType {
  TWITTER = 'TWITTER',
  FACEBOOK = 'FACEBOOK',
  PASSWORD = 'PASSWORD',
}

@Injectable()
export class UserauthService {
  private own_user_subject$: BehaviorSubject<OwnUser>;
  own_user$: Observable<OwnUser>;

  constructor(
    public afAuth: AngularFireAuth,
    private logger: LoggerService,
    private firebaseRtdApi: FirebaseRtdApi,
    private firebaseRtdPureService: FirebaseRtdPureService,
    public dialog: MatDialog,
    private router: Router,
  ) {
    this.own_user_subject$ = new BehaviorSubject(EMPTY_OWN_USER);
    this.own_user$ = this.own_user_subject$.asObservable();

    afAuth.authState.subscribe((auth: any) => {
      if (auth && auth.uid) {
        const full_name = auth.displayName || ' ';
        const split_name_arr = full_name.split(' ');
        const photoURL = auth.providerData[0].photoURL || '';
        const own_user_id = auth.uid;

        const own_user: OwnUser = {
          id: own_user_id,
          loggedIn: true,
          full_name: full_name,
          short_name: split_name_arr[0],
          pict_src: photoURL,
        };
        this.own_user_subject$.next(own_user);
        this.logger.set_own_user(own_user);
        this.open_username_input(own_user_id, auth);
      } else {
        // important: firebase auth logout sometimes happen even if user is logged in, so deleting information caused error.
        // info is deleted only when user logout intension
        /*
        this.own_user_id = null;
        this.own_user = { id:null, fb_id:null, loggedIn:false,full_name:"",short_name:"",pict_src:"" };
        */

        this.send_log('<<auth state changed>> logout but not reflected to own_user_subject', LOG_LEVEL.INFO);
      }
    });

    firebase
      .auth()
      .getRedirectResult()
      .then((result: any) => {
        // console.log(`%c getRedirectResult called ${JSON.stringify(result)}`, "color: green");

        this.register_result(result, LOGIN_TYPE_ORIGIN.REDIRECT);
      })
      .catch((err: Error) => {
        this.send_log(`getRedirectResult failure case ${err.name} - ${err.message} `, LOG_LEVEL.ERROR);
        alert(`login failure: ${err.message}`);
      });

    // this.own_user_subject$.subscribe((user_data: OwnUser) => {
    //   if (user_data.loggedIn) {
    //     const log_obj: LogParam = {
    //       message: '<<auth state changed>> user has logged in',
    //       level: LOG_LEVEL.LOG,
    //       tech: LOG_TECH.authenticate,
    //       type: LOG_TYPE.Keypoint,
    //       file_name: 'userauth.service',
    //       module: LOG_MODULE.Core,
    //     };
    //     this.logger.write(log_obj);
    //   } else {
    //     const log_obj: LogParam = {
    //       message: '<<auth state changed>> user has no data',
    //       level: LOG_LEVEL.LOG,
    //       tech: LOG_TECH.authenticate,
    //       type: LOG_TYPE.Keypoint,
    //       file_name: 'userauth.service',
    //       module: LOG_MODULE.Core,
    //     };
    //     this.logger.write(log_obj);
    //   }
    // });

    this.firebaseRtdPureService.is_internet_connected$().subscribe((value: boolean) => {
      if (value === true) {
        // this.send_log('<<connection state changed>> connected', LOG_LEVEL.WARN);
        // this.logger.retry_sending_log();
      } else {
        // const log_obj: LogParam = {
        //   message: '<<connection state changed>> disconnected',
        //   level: LOG_LEVEL.WARN,
        //   tech: LOG_TECH.network,
        //   type: LOG_TYPE.Keypoint,
        //   file_name: 'logger.service',
        //   module: LOG_MODULE.Core,
        // };
        // this.logger.write(log_obj);
      }
    });
  }

  get_login_type$(): Observable<UserLoginType | null> {
    return this.afAuth.authState.pipe(map((auth) => get_login_type(auth)));
  }

  open_username_input(own_userid: string, auth: any) {
    if (!localStorage.getItem(LS_KEY_USER_NAME_SET)) {
      const providerId = auth.providerData[0].providerId;
      const is_twitter_login = providerId.indexOf('twitter') !== -1;
      const is_password_login = providerId.indexOf('password') !== -1;
      if (is_twitter_login) {
        this.firebaseRtdApi
          .getUserInputNameOnce(own_userid)
          .pipe(take(1))
          .subscribe((data) => {
            if (data) {
              localStorage.setItem(LS_KEY_USER_NAME_SET, 'true');
            } else {
              const own_user_id = this.get_own_userid();
              const data: UserNameInputData = { own_userid };
              this.dialog.open(SetUsernameModalComponent, { disableClose: true, data: data, width: '320px' });
            }
          });
      } else if (is_password_login) {
        // displanName is set on the server side during the signin process and it has not done at the last phase of registration.
        // If it does not exist, go to sign in process and register the info again.
        if (!auth.displayName) {
          this.send_log('displayName not set for mail login user', LOG_LEVEL.LOG);
          const navigationExtra2 = getBlankNavigationExtra();
          this.router.navigate(['/mail-login/signin'], navigationExtra2);
        }
      } else {
        const is_facebook_login = providerId.indexOf('facebook') !== -1;
        if (is_facebook_login) {
          localStorage.setItem(LS_KEY_USER_NAME_SET, 'true');
        }
      }
    }
  }

  is_loggedin(): boolean {
    const own_user = this.own_user_subject$.getValue();
    if (own_user && own_user.loggedIn) {
      return true;
    }
    return false;
  }

  get_own_userid(): string {
    const own_user = this.own_user_subject$.getValue();
    if (own_user && own_user.id) {
      return own_user.id;
    }
    return '';
  }

  get_own_userid$(): Observable<string> {
    return this.own_user$.pipe(
      filter((own_user) => !!(own_user && own_user.id)),
      map((own_user) => own_user.id),
      take(1),
    );
  }

  get_own_user$(): Observable<OwnUser> {
    return this.own_user$;
  }

  logout() {
    firebase.auth().signOut();
    //  this.own_user = { id: null, fb_id: null, loggedIn: false, full_name: '', short_name: '', pict_src: '' };
    this.own_user_subject$.next({
      id: null,
      loggedIn: false,
      full_name: '',
      short_name: '',
      pict_src: '',
    });
    // this.own_profile = { cover: { id: null } };

    this.send_log('<<auth operation>> logout', LOG_LEVEL.LOG);

    localStorage.removeItem(LS_KEY_USER_NAME_SET);
    localStorage.removeItem(LS_KEY_LOGGEDIN);

    setTimeout(() => {
      location.reload();
    }, 50);
  }

  createUserWithEmailAndPassword(email, password) {
    return firebase.auth().createUserWithEmailAndPassword(email, password);
  }

  loginTwitterWithRedirect() {
    firebase
      .auth()
      .signInWithRedirect(new firebase.auth.TwitterAuthProvider())
      .then((result: any) => {
        console.log('sign in redirect', result);
      });

    localStorage.removeItem(LS_KEY_USER_NAME_SET);
    localStorage.setItem(LS_KEY_LOGGEDIN, 'true');
  }

  loginFacebookWithRedirect = () => {
    firebase
      .auth()
      // .signInWithPopup(new firebase.auth.FacebookAuthProvider())
      .signInWithRedirect(new firebase.auth.FacebookAuthProvider())
      .then((result: any) => {
        /* this code is never called if signInWithRedirect is used */
        console.log('sign in redirect', result);
        // let full_name = '';
      });
    // .catch((err) => {
    //   /* this code is never called if signInWithRedirect is used */

    // });
    localStorage.removeItem(LS_KEY_USER_NAME_SET);
    localStorage.setItem(LS_KEY_LOGGEDIN, 'true');
  };

  loginTwitterWithPopup = () => {
    console.log(` %c loginTwitterWithPopup`, 'color:green');
    firebase
      .auth()
      .signInWithPopup(new firebase.auth.TwitterAuthProvider())
      .then((result: any) => {
        // console.log(` %c sign in succeed ${JSON.stringify(result)}`,  "color:green");

        this.register_result(result, LOGIN_TYPE_ORIGIN.TWITTER_POPUP);
      })
      .catch((err) => {
        this.logout();
        this.send_log(`<<auth operation>> loginTwitterWithPopup failure ${err.name} - ${err.message}`, LOG_LEVEL.ERROR);
        alert(`loginTwitterWithPopup fail: ${err.message}`);
        // this.close_loginmodal();
      });
  };

  loginFacebookWithPopup = () => {
    console.log(` %c loginFacebookWithPopup`, 'color:green');
    firebase
      .auth()
      .signInWithPopup(new firebase.auth.FacebookAuthProvider())
      .then((result: any) => {
        // console.log(` %c sign in succeed ${JSON.stringify(result)}`,  "color:green");
        this.register_result(result, LOGIN_TYPE_ORIGIN.FACEBOOK_POPUP);
      })
      .catch((err: Error) => {
        this.logout();
        this.send_log(`<<auth operation>> loginFacebookWithPopup failure ${err.name} - ${err.message} `, LOG_LEVEL.ERROR);
        alert(` loginFacebookWithPopup fail: ${err.message}`);
        // this.close_loginmodal();
      });
  };

  loginByEmail(email, password) {
    localStorage.removeItem(LS_KEY_USER_NAME_SET);

    return firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((userCredential) => {
        console.log('userCredential', userCredential);
        localStorage.setItem(LS_KEY_LOGGEDIN, 'true');
      })
      .catch((error: Error) => {
        throw error;
      });
  }

  private register_result = (result: any, login_type_origin: LOGIN_TYPE_ORIGIN) => {
    if (!result) {
      return;
    }
    let full_name = '';
    if (result.user) {
      full_name = result.user.displayName;
      const split_name_arr = full_name.split(' ');
      const own_user_id = result.user.uid;
      const photoURL = result.user.photoURL;
      const own_user = {
        id: own_user_id,
        loggedIn: true,
        full_name: full_name,
        short_name: split_name_arr[0],
        pict_src: photoURL,
      };
      this.own_user_subject$.next(own_user);

      switch (login_type_origin) {
        case LOGIN_TYPE_ORIGIN.FACEBOOK_POPUP:
          this.send_log(`<<auth operation>> loginFacebookWithPopup succeed ${full_name}`, LOG_LEVEL.LOG);
          break;
        case LOGIN_TYPE_ORIGIN.TWITTER_POPUP:
          this.send_log(`<<auth operation>> loginTwitterWithPopup succeed ${full_name}`, LOG_LEVEL.LOG);
          break;
        case LOGIN_TYPE_ORIGIN.REDIRECT:
          this.send_log(`<<auth operation>> getRedirectResult  called ${full_name}`, LOG_LEVEL.LOG);
          break;
      }
      localStorage.setItem(LS_KEY_LOGGEDIN, 'true');
    }
    if (result.credential) {
      const additionalUserInfo = result.additionalUserInfo || {};
      const providerId = additionalUserInfo.providerId;
      const accessToken = result.credential.accessToken;
      const secret = result.credential.secret;
      const provider_uid = result.user.providerData[0].uid;

      this.register_user(accessToken, secret, full_name, providerId, provider_uid);
    }
  };

  private register_user = (accessToken, secret, full_name, providerId: string, provider_uid: string) => {
    const is_twitter_login = providerId.indexOf('twitter') !== -1;
    const is_facebook_login = providerId.indexOf('facebook') !== -1;
    let login_type = '';

    let register_promise: Promise<any>;

    if (is_facebook_login) {
      login_type = 'facebook';
      const own_fb_token: UserFbToken = {
        full_name: full_name,
        token: accessToken,
        provider_uid,
        token_updated: new Date(),
      };
      register_promise = this.firebaseRtdApi.setUserFbToken(this.get_own_userid(), own_fb_token);
    } else if (is_twitter_login) {
      login_type = 'twitter';
      const own_twitter_token: UserTwitterToken = {
        full_name: full_name,
        token: accessToken,
        secret: secret,
        provider_uid,
        token_updated: new Date(),
      };
      register_promise = this.firebaseRtdApi.setUserTwitterToken(this.get_own_userid(), own_twitter_token);
    }

    if (register_promise) {
      register_promise
        .then(() => {
          this.send_log(`<<auth operation>> user ${login_type} token registration done`, LOG_LEVEL.LOG);
        })
        .catch((err: Error) => {
          this.logout();
          this.send_log(`<<auth operation>> setting ${login_type} token failed ${err.name} - ${err.message}  `, LOG_LEVEL.ERROR);
          alert('registration failed. Please login again');
        });
    }
  };

  send_log(message: string, level: LOG_LEVEL) {
    const log_obj: LogParam = {
      message,
      level,
      tech: LOG_TECH.authenticate,
      type: LOG_TYPE.Keypoint,
      file_name: 'userauth.service',
      module: LOG_MODULE.Core,
    };
    this.logger.write(log_obj);
  }

  /*

  retrieve_graph_profiledata(token){
    console.log(token);

    const graph_request_url =
      "https://graph.facebook.com/v2.7/me?access_token=" + token +
      "&fields=cover,picture,id,third_party_id,link&redirect=true";
    console.log(graph_request_url);

    this.http.get(graph_request_url)
      .toPromise()
      .then((response : any)=>{
        console.log("retrieve_graph_profiledata",response);
        if(response._body){
          const graph_data = JSON.parse(response._body);
          console.log(graph_data);
          if(graph_data.cover){
            this.register_user_profile_data("cover_src", graph_data.cover.source);
          }
        }
      })
      .catch((error)=>{
        console.log(error);
      });;
  }

*/
  /*

  register_user_profile_data(key, value){
    console.log(key, value);
    let obj = {};
    obj[key]=value;
    const userProfileObservable = this.af_db.object('/users/profile/' + this.own_user_id);
    userProfileObservable.update(obj).then(
      ()=>{
        this.retrieve_profile();
      }
    );
  }
*/
}

function get_login_type(auth: any): UserLoginType | null {
  if (!auth) {
    return null;
  }
  let login_type: UserLoginType | null = null;
  const providerId = auth.providerData[0].providerId;
  const is_twitter_login = providerId.indexOf('twitter') !== -1;
  const is_password_login = providerId.indexOf('password') !== -1;
  const is_facebook_login = providerId.indexOf('facebook') !== -1;
  if (is_facebook_login) {
    login_type = UserLoginType.FACEBOOK;
  } else if (is_twitter_login) {
    login_type = UserLoginType.TWITTER;
  } else if (is_password_login) {
    login_type = UserLoginType.PASSWORD;
  }

  return login_type;
}
