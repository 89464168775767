import { LAYOUT_TYPE_IN_AUDITORIUM_ID } from '../interface/firebase-room-user';

export enum LAYOUT_TYPE {
  AUDITRIUM = 'AUDITRIUM',
  GAME = 'GAME',
  GAME_IN_AUDITRIUM = 'GAME_IN_AUDITRIUM',
  PRESENTATION = 'PRESENTATION',
  PRESENTATION_IN_AUDITRIUM = 'PRESENTATION_IN_AUDITRIUM',
  JISTI_IN_AUDITRIUM = 'JISTI_IN_AUDITRIUM',
  JISTI = 'JISTI',
}

export function getLayoutType(room_type: string): LAYOUT_TYPE {
  switch (room_type) {
    case LAYOUT_TYPE.AUDITRIUM:
      return LAYOUT_TYPE.AUDITRIUM;
    case LAYOUT_TYPE.GAME:
      return LAYOUT_TYPE.GAME;
    case LAYOUT_TYPE.GAME_IN_AUDITRIUM:
      return LAYOUT_TYPE.GAME_IN_AUDITRIUM;
    case LAYOUT_TYPE.PRESENTATION:
      return LAYOUT_TYPE.PRESENTATION;
    case LAYOUT_TYPE.PRESENTATION_IN_AUDITRIUM:
      return LAYOUT_TYPE.PRESENTATION_IN_AUDITRIUM;
    case LAYOUT_TYPE.JISTI_IN_AUDITRIUM:
      return LAYOUT_TYPE.JISTI_IN_AUDITRIUM;
    case LAYOUT_TYPE.JISTI:
      return LAYOUT_TYPE.JISTI;
    default:
      return LAYOUT_TYPE.GAME;
  }
}

export function getEventIdForGameInAuditorium(router_id: string, room_num_in_auditrium: string): string {
  return `${router_id}_${LAYOUT_TYPE_IN_AUDITORIUM_ID.GAME}_${room_num_in_auditrium}`;
}

export function getEventIdForPresentationInAuditorium(router_id: string, room_num_in_auditrium: string): string {
  return `${router_id}_${LAYOUT_TYPE_IN_AUDITORIUM_ID.PRESENTATION}_${room_num_in_auditrium}`;
}

export function getEventIdForJistiInAuditorium(router_id: string, room_num_in_auditrium: string): string {
  return `${router_id}_${LAYOUT_TYPE_IN_AUDITORIUM_ID.JISTI}_${room_num_in_auditrium}`;
}

export interface RoomInAuditriumData {
  room_num_in_auditrium: string | number;
}
