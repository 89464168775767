import firebase from 'firebase/compat/app';

export interface PriceCommon {
  userid: string;
  user_name: string;
  customer_id: string;
  price_id: string;
  description?: string;
  payment_type?: string;
  period_start_timestamp?: firebase.firestore.Timestamp;
  period_end_timestamp?: firebase.firestore.Timestamp;
  cancel_at_timestamp?: firebase.firestore.Timestamp;
  updated_timestamp: firebase.firestore.Timestamp;
  price_user_status: MEMBERSHIP_STATUS;
}

export interface PricePaymentHistory extends PriceCommon {
  eventType: string;
  amount?: string;
  currency?: string;
  product?: string;
  subscription?: string;
  quantity?: string;
  price_type?: string;
  payment_type?: string;
  line?: any;
  item?: any;
}

export interface PriceUserPaymentStatus extends PriceCommon {
  price_payment_history: PricePaymentHistory;
}

export interface PriceUserPaymentStatusVm extends PriceUserPaymentStatus, WithTimestampPayment {}

export interface PricePaymentHistoryVm extends PricePaymentHistory, WithTimestampPayment {}

export interface WithTimestampPayment {
  period_start_timestamp?: firebase.firestore.Timestamp;
  period_end_timestamp?: firebase.firestore.Timestamp;
  cancel_at_timestamp?: firebase.firestore.Timestamp;
  updated_timestamp: firebase.firestore.Timestamp;
  cancel_at_date?: Date;
  period_start_date?: Date;
  period_end_date?: Date;
  updated_date?: Date;
}

export enum PAYMENT_TYPE {
  SUBSCRIPTION = 'SUBSCRIPTION',
  SHOT_FEE = 'SHOT_FEE',
}

export enum MEMBERSHIP_STATUS {
  REGISTERED = 'REGISTERED',
  CANCEL_PLANNED = 'CANCEL_PLANNED',
  CANCELLED = 'CANCELLED',
}

export enum TRIAL {
  NONE = 'NONE',
  END_OF_MONTH = 'END_OF_MONTH',
}

export interface ProductStripe {
  id: string;
  payment_type: PAYMENT_TYPE;
  product_name: string;
  target: string;
  description: string;
  price_detail: string;
  image_url: string;
  stripe_price_id_subscription?: string;
  trial_end: TRIAL | null;
  stripe_coupon_id: string | null;
  shot_payment: { amount: number } | null;
  price_arr: PriceForPartialProductInfo[];

  explanation_document_url: string;
}

export interface PriceForPartialProductInfo {
  add_invoice_items: string[];
  price_detail: string;
  payment_suppliment_info: string | null;
}

export interface StripeRequiredInfo {
  stripe_price_id_subscription: string;
  add_invoice_items: string[];
  trial_end: TRIAL;
  payment_type: PAYMENT_TYPE;
  stripe_coupon_id?: string;
  shot_payment?: { amount: number };
}

export interface SinglePaymentURL {
  title: string;
  shown_price: string;
  url: string;
}

export interface StripeUser {
  userid?: string;
  customer_id?: string;
  user_name?: string;
  email?: string;
  login_type?: string;
  added_timestamp?: firebase.firestore.Timestamp;
}
