<section class="container">
  <h2 class="h4">Please input your name</h2>

  <div class="Spacer -h16"></div>

  <div class="form-group mb-2">
    <label for="twitter-set-firstname">
      First name
      <span class="text-danger small">Required</span>
    </label>
    <input id="twitter-set-firstname" class="form-control" [(ngModel)]="firstName" placeholder="first name with alphabet" required />
    <p class="text-secondary mb-0">
      <small>With Alphabet</small>
    </p>
  </div>

  <div class="form-group">
    <label for="twitter-set-lastname">
      Last name
      <span class="text-danger small">Required</span>
    </label>
    <input id="twitter-set-lastname" class="form-control" [(ngModel)]="lastName" placeholder="last name with alphabet" required />
    <p class="text-secondary">
      <small>With Alphabet</small>
    </p>
  </div>

  <div class="Spacer -h8"></div>

  <div class="form-group mb-2">
    <label for="twitter-set-local-lastname">{{ lastNameLocalLabel }}</label>
    <input id="twitter-set-local-lastname" class="form-control" [(ngModel)]="lastNameLocal" />
    <p class="text-secondary mb-0">
      <small>With local language</small>
    </p>
  </div>
  <div class="form-group">
    <label for="twitter-set-local-firstname">{{ firstNameLocalLabel }}</label>
    <input class="form-control" id="twitter-set-local-firstname" [(ngModel)]="firstNameLocal" />
    <p class="text-secondary">
      <small>With local language</small>
    </p>
  </div>

  <p class="text-danger">You cannot edit your name later.</p>

  <button (click)="save()" class="btn btn-primary btn-block">Save</button>

  <div class="Spacer -h64"></div>
</section>
