import { Injectable } from '@angular/core';

import { ReplaySubject } from 'rxjs';
import { take } from 'rxjs/operators';

import { UserauthService } from './../../core/service/userauth.service';

import { AngularFireDatabase, AngularFireObject } from '@angular/fire/compat/database';

@Injectable()
export class GroupService {
  grouplist_subject;
  synched = false;

  constructor(private user_auth: UserauthService, private af_db: AngularFireDatabase) {
    this.grouplist_subject = new ReplaySubject(1);
  }

  get_grouplist_subject() {
    if (!this.synched) {
      if (!this.user_auth.is_loggedin()) {
        this.user_auth.get_own_user$().subscribe((own_user) => {
          const own_user_id = own_user.id;
          this.retrieve_group_info(own_user_id);
        });
      } else {
        this.retrieve_group_info(this.user_auth.get_own_userid());
      }
    }

    return this.grouplist_subject.asObservable();
  }

  retrieve_group_info(own_user_id) {
    if (!own_user_id) {
      return;
    }

    // it is better to change the path name like '/users/group_involved'.

    const grouop_member_ref: AngularFireObject<any> = this.af_db.object('users/group_members/' + own_user_id);
    grouop_member_ref
      .snapshotChanges()
      .pipe(take(1))
      .subscribe((group_member_action) => {
        const group_member = group_member_action.payload.val();
        console.log(group_member);
        this.synched = true;
        this.grouplist_subject.next(group_member);
      });
  }

  // this is used when you want to sync again after initial sync.
  update_group() {}
}
