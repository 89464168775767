export interface GroupTypeOptions {
  name: string;
  subType: Array<{ name: string }>;
}

export const GROUP_TYPE_CLUB: GroupTypeOptions = {
  name: 'CLUB',
  subType: [{ name: 'HIGH_SCHOOL_CLUB' }, { name: 'UNIVERSITY_CLUB' }, { name: 'LOCAL_COMUNITY_CLUB' }],
};
export const GROUP_TYPE_COMPANY: GroupTypeOptions = {
  name: 'COMPANY',
  subType: [],
};
export const GROUP_TYPE_COMMINITY: GroupTypeOptions = {
  name: 'COMMUNITY',
  subType: [],
};
export const GROUP_TYPE_UNION: GroupTypeOptions = {
  name: 'UNION',
  subType: [],
};

export const GROUP_TYPE_SCHOOL: GroupTypeOptions = {
  name: 'SCHOOL',
  subType: [{ name: 'HIGH_SCHOOL' }, { name: 'UNIVERSITY' }],
};

export const GROUP_TYPE_WORKSHOP: GroupTypeOptions = {
  name: 'WORKSHOP',
  subType: [{ name: 'PAID' }, { name: 'FREE' }],
};
