export enum DEVICE_SUPPORT_TYPE {
  IOS_OLD_VERSION = 'IOS_OLD_VERSION',
  IOS_WEBVIEW = 'IOS_WEBVIEW',
  IOS_SAFARIVIEW = 'IOS_SAFARIVIEW',
  IOS_SAFARI = 'IOS_SAFARI', // it works
  IOS_UNKNOWN_APP = 'IOS_UNKNOWN_APP',
  ANDROID_WEBVIEW = 'ANDROID_WEBVIEW',
  ANDROID_CHROME = 'ANDROID_CHROME', // it works
  ANDROID_UNKNOWN = 'ANDROID_UNKNOWN', // it works
  MAC_OLD_SAFARI = 'MAC_OLD_SAFARI',
  PC_UNSUPPORTED_BROWSERS = 'PC_UNSUPPORTED_BROWSERS',
  PC_SUPPORTED_BROWSERS = 'PC_SUPPORTED_BROWSERS',
}

export const BROWSER_TYPE = {
  CHROME: 'CHROME',
  CHROME_ANDROID: 'CHROME_ANDROID',
  SAFARI: 'SAFARI',
  IOS_SAFARI: 'IOS_SAFARI',
  FIREFOX: 'FIREFOX',
  EDGE: 'EDGE',
  IE: 'IE',
};

export interface WebrtcSupportedEnvCheck {
  is_supported: boolean;
  is_ios: boolean;
  is_ios_oldversion: boolean;
  is_ios_oldversion_src: string;
  is_safari_oldversion: boolean;
  is_safari_oldversion_src: string;
  safari_version: number;
  ios_version: number;
  is_ios_supported_browser: boolean;
  is_unsupported_browser: boolean;
  unsupported_browser_name: string;
  unsupported_browser_image_src: string;
  ios_open_safari_image_src: string;
  is_android: boolean;
  android_open_chrome_src: string;
  open_alert_dialog_immediately: boolean;
  device_support_type: DEVICE_SUPPORT_TYPE;
}
export interface IosInfo {
  ios_img_src?: string;
  current_ios_version?: number;
}

export const DEFAULT_WEBRTC_SUPPORTED_ENVCHECK: WebrtcSupportedEnvCheck = {
  is_supported: false,
  is_ios: false,
  is_ios_oldversion: false,
  is_ios_oldversion_src: '',
  safari_version: 0,
  is_safari_oldversion: false,
  is_safari_oldversion_src: '',
  ios_version: 0,
  is_ios_supported_browser: false,
  is_unsupported_browser: false,
  unsupported_browser_name: '',
  unsupported_browser_image_src: '',
  ios_open_safari_image_src: '',
  is_android: false,
  android_open_chrome_src: '',
  open_alert_dialog_immediately: false,
  device_support_type: null,
};
