import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'showMinutesHour',
  pure: true,
})
export class ShowMinutesHourPipe implements PipeTransform {
  transform(time_spent_seconds: number, args?: any): any {
    if (time_spent_seconds) {
      const speech_time_minutes_whole = Math.floor(time_spent_seconds / 60);
      const speech_time_minutes = speech_time_minutes_whole % 60;
      const speech_time_hours = Math.floor(time_spent_seconds / 60 / 60);
      return String(speech_time_hours) + 'hours ' + String(speech_time_minutes) + 'minutes';
    } else {
      return '';
    }
  }
}
