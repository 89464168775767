import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { UserauthService } from './../../core/service/userauth.service';

import { LoggerService, LogParam, LOG_LEVEL, LOG_TECH, LOG_MODULE, LOG_TYPE } from '@mixidea-client/logger';

import { environment } from './../../../environments/environment';

import { OwnUser, getBlankNavigationExtra, SESSION_KEY_CREATE_USER_PREVIOUS_PAGE } from '@mixidea-client/model';

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

export const MODAL_SIZE = '300px';

@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.scss'],
})
export class LoginModalComponent implements OnInit, OnDestroy {
  term_of_service_url = environment.term_of_service_url;
  own_user_subscription: Subscription;

  redirectLogin = true;
  constructor(
    private user_auth: UserauthService,
    public dialogRef: MatDialogRef<LoginModalComponent>,
    private logger: LoggerService,
    private router: Router,
  ) {}

  ngOnInit() {
    console.log('LoginModalComponent init');
    this.own_user_subscription = this.user_auth.get_own_user$().subscribe((own_user: OwnUser) => {
      if (own_user.id) {
        this.hide_login_modal();
      }
    });
  }

  login_fb() {
    this.send_log('login_fb with redirect called');
    this.user_auth.loginFacebookWithRedirect();
  }

  login_fb_with_popup() {
    this.send_log('login_fb_with_popup with pupup called');
    this.user_auth.loginFacebookWithPopup();
  }

  login_twitter() {
    this.send_log('login_twitter with redirect called');
    this.user_auth.loginTwitterWithRedirect();
  }

  login_twitter_with_popup() {
    this.send_log('login_twitter_with_popup with pupup called');
    this.user_auth.loginTwitterWithPopup();
  }

  hide_login_modal() {
    this.dialogRef.close();
  }

  use_popup() {
    this.redirectLogin = false;
  }

  goto_mail_logion() {
    const pathname = location.pathname;
    sessionStorage.setItem(SESSION_KEY_CREATE_USER_PREVIOUS_PAGE, pathname);
    const navigationExtra2 = getBlankNavigationExtra();
    this.router.navigate(['/mail-login'], navigationExtra2);
    this.hide_login_modal();
  }

  send_log(message) {
    const log_obj: LogParam = {
      message,
      level: LOG_LEVEL.LOG,
      tech: LOG_TECH.authenticate,
      type: LOG_TYPE.Keypoint,
      file_name: 'login-modal.component',
    };
    this.logger.write(log_obj);
  }

  ngOnDestroy() {
    this.send_log('login modal has been hidden');

    if (this.own_user_subscription) {
      this.own_user_subscription.unsubscribe();
    }
  }
}
