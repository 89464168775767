export const ONLINE_DEBATE_LIVEVIDEO = 'ONLINE_DEBATE_LIVEVIDEO';
export const ONLINE_DEBATE_WRITTEN = 'ONLINE_DEBATE_WRITTEN';
export const ONLINE_TOURNAMENT_LIVEVIDEO = 'ONLINE_TOURNAMENT_LIVEVIDEO';
export const ONLINE_TOURNAMENT_WRITTEN = 'ONLINE_TOURNAMENT_WRITTEN';

export enum EVENT_TYPE {
  DEBATE_LIVEVIDEO = 'ONLINE_DEBATE_LIVEVIDEO',
  DEBATE_WRITTEN = 'ONLINE_DEBATE_WRITTEN',
  TOURNAMENT_LIVEVIDEO = 'ONLINE_TOURNAMENT_LIVEVIDEO',
  TOURNAMENT_WRITTEN = 'ONLINE_TOURNAMENT_WRITTEN',
}
