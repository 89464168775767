import firebase from 'firebase/compat/app';

export interface LineData {
  admin_data?: LineDataontextFromAdmin;
  line_data?: LineDataContextFromLineUser;
  forward_message_line_app?: boolean;
  time?: firebase.firestore.Timestamp;
  date?: Date;
  origin?: MESSAGE_ORIGIN;
  admin_userid?: string;
  image_url?: string;
}

export interface LineDataontextFromAdmin {
  text?: string;
  video_url?: string;
}

export interface LineDataContextFromLineUser {
  message?: LineDataMessageText | LineDataMessageImage;
  mode?: string;
  replyToken?: string;
  source?: LineDataSource;
  timestamp?: number;
  type?: string;
}
export interface LineDataSource {
  type?: string;
  userId?: string;
}

export interface LineDataMessageText {
  id: string;
  text: string;
  type: LineDataMessageType;
}

export interface LineDataMessageImage {
  contentProvider: { type: string };
  id: string;
  type: LineDataMessageType;
}

type LineDataMessageType = 'image' | 'text';

export enum MESSAGE_ORIGIN {
  ADMIN_DASHBOARD = 'ADMIN_DASHBOARD',
  USER_LINE = 'USER_LINE',
  LOGIN_TRIGGER = 'LOGIN_TRIGGER',
}

export interface LineUserWithId extends LineUser {
  id: string;
}

export interface LineUser {
  line_profile?: LineProfile;
  users?: [];
  last_updated: firebase.firestore.Timestamp;
}

export interface LineProfile {
  access_token: string;
  expire_in: string;
  id_token: IdToken;
  refresh_token;
  scope: string;
  token_type: string;
}

export interface IdToken {
  amr: any;
  aud: string;
  exp: string;
  iat: string;
  iss: string;
  name: string;
  nonce: string;
  sub: string;
}
