import { GAME_STATUS } from './../constant/game-status';

export enum GAME_STATUS_DIRECTION {
  PREVIOUS,
  NEXT,
}

export interface GameStatusCandidate {
  direction: GAME_STATUS_DIRECTION;
  game_status: GAME_STATUS;
  user_id: string;
}
