import { Component, OnInit, Input } from '@angular/core';

import { Observable } from 'rxjs';

import { ModelUserService } from './../../../core/service/model-user.service';
import { UserBasicVm } from '@mixidea-client/model';

@Component({
  selector: 'app-user-show-base',
  template: '',
  styleUrls: [],
})
export class UserShowBaseComponent implements OnInit {
  user$: Observable<UserBasicVm>;
  @Input()
  set user_id(user_id: string) {
    this.user$ = this.user_model.get_user$(user_id);
  }
  constructor(private user_model: ModelUserService) {}

  ngOnInit() {
    this.extended_init();
  }

  extended_init() {}
}
