import { Injectable } from '@angular/core';

import { FirebaseStorageService } from './service/firebase-storage.service';

import { take } from 'rxjs/operators';

@Injectable()
export class FirebaseStoragedApi {
  constructor(private firebaseStorageService: FirebaseStorageService) {}

  // approve withdrawal by email link access

  storeFileEventChatAttachment(event_id: string, file: File): Promise<string> {
    const path = 'eventchat_attachment/' + event_id + '/' + file.name;
    return (
      this.firebaseStorageService
        .store_file(path, file)
        // .pipe(take(1))
        // .toPromise()
        .then((downloadURL) => {
          return downloadURL;
        })
    );
  }

  storeFileGoupPostAttachment(group_id: string, file: File): Promise<string> {
    const path = 'group_post_attachment/' + group_id + '/' + file.name;
    return (
      this.firebaseStorageService
        .store_file(path, file)
        // .pipe(take(1))
        // .toPromise()
        .then((downloadURL) => {
          return downloadURL;
        })
    );
  }

  storeFileEventPostAttachment(event_id: string, file: File): Promise<string> {
    return this.storeFileEventChatAttachment(event_id, file);
  }

  storeFileGroupImg(group_id: string, file_name: string, file: File): Promise<string> {
    const path = `group-2/${group_id}/${file_name}`;

    return (
      this.firebaseStorageService
        .store_file(path, file)
        // .pipe(take(1))
        // .toPromise()
        .then((downloadURL) => {
          return downloadURL;
        })
    );
  }

  storeFileTeacherIntroductionVideo(teacher_id: string, file_name: string, file: File): Promise<string> {
    const path = `teacher_profile/${teacher_id}/${file_name}`;

    return (
      this.firebaseStorageService
        .store_file(path, file)
        // .pipe(take(1))
        // .toPromise()
        .then((downloadURL) => {
          return downloadURL;
        })
    );
  }

  storeFileGrouCommentAttachmentMain(group_id: string, maincomment_id: string, file: File): Promise<string> {
    const path = `group_maincomment/${group_id}/${maincomment_id}/${file.name}`;

    return (
      this.firebaseStorageService
        .store_file(path, file)
        // .pipe(take(1))
        // .toPromise()
        .then((downloadURL) => {
          return downloadURL;
        })
    );
  }

  storeFileGrouCommentAttachmentSub(group_id: string, maincomment_id: string, subcomment_id: string, file: File): Promise<string> {
    const path = `group_subcomment/${group_id}/${maincomment_id}-${subcomment_id}/${file.name}`;

    return (
      this.firebaseStorageService
        .store_file(path, file)
        // .pipe(take(1))
        // .toPromise()
        .then((downloadURL) => {
          return downloadURL;
        })
    );
  }

  storeFileEventCommentAttachmentMain(event_id: string, maincomment_id: string, file: File): Promise<string> {
    const path = `event_maincomment/${event_id}/${maincomment_id}/${file.name}`;
    // console.log('storeFileEventCommentAttachmentMain', path);

    return (
      this.firebaseStorageService
        .store_file(path, file)
        // .pipe(take(1))
        // .toPromise()
        .then((downloadURL) => {
          console.log('downloadURL', downloadURL);
          return downloadURL;
        })
    );
  }

  storeFileEventCommentAttachmentSub(
    event_id: string,
    maincomment_id: string,
    subcomment_id: string,
    file_name: string,
    file: File,
  ): Promise<string> {
    const path = `event_subcomment/${event_id}/${maincomment_id}-${subcomment_id}/${file_name}`;

    return (
      this.firebaseStorageService
        .store_file(path, file)
        // .pipe(take(1))
        // .toPromise()
        .then((downloadURL) => {
          return downloadURL;
        })
    );
  }

  storeFilePresentationPDF(router_id: string, file_name: string, file: File, presentation_room_in_auditorium: boolean): Promise<string> {
    let path = `presentation/${router_id}/${file_name}`;
    if (presentation_room_in_auditorium) {
      const auditrium_room_num = 1;
      path = `presentation-in-auditorium/${router_id}/${auditrium_room_num}/${file_name}`; // temporarily only 1 exist
    }

    return this.firebaseStorageService.store_file(path, file).then((downloadURL) => {
      return downloadURL;
    });
  }

  storeFileUserProfile(user_id: string, file_name: string, file: File): Promise<string> {
    const path = `user_profile_original/${user_id}/${file_name}`;
    return this.firebaseStorageService.store_file(path, file).then((downloadURL) => {
      return downloadURL;
    });
  }
}
