import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';

/* module */
import { CoreModule } from './core/core.module';
// import {EventModule} from './event/event.module';
// import {ArticleModule} from './article/article.module'
// import {LiveVideoDebateModule} from './livevideo-debate/livevideo-debate.module';
// import {WrittendebateArticle2Module} from './article2/writtendebate-article2/writtendebate-article2.module';
import { SharedModule } from './shared/shared.module';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideFirebaseApp, getApp, initializeApp } from '@angular/fire/app';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { FIREBASE_OPTIONS } from '@angular/fire/compat';
import { SETTINGS as AUTH_SETTINGS } from '@angular/fire/compat/auth';
import { environment } from './../environments/environment';

/* Routing Module */
import { AppRoutingModule } from './app.routing';

import { Angulartics2Module } from 'angulartics2';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    //   ModalModule.forRoot(),
    //   EventModule,
    //    ArticleModule,
    //    LiveVideoDebateModule,
    //    WrittendebateArticle2Module,
    AppRoutingModule,
    SharedModule,
    CoreModule.forRoot(),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideFirestore(() => getFirestore()),
    BrowserAnimationsModule,
    Angulartics2Module.forRoot(),
  ],
  providers: [
    { provide: FIREBASE_OPTIONS, useValue: environment.firebase },
    { provide: AUTH_SETTINGS, useValue: { appVerificationDisabledForTesting: true } },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
