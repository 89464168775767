import { TEAM_NAME } from '../constant/team-enum';
import { ROOM_NAME_BASE } from './../constant/room';

export interface RoomUserRecognition {
  [userid: string]: string | boolean;
}

export interface FirebaseRoomUserOnlyUserDb {
  [userid: string]: RoomUserRecognition;
}

1;
export interface FirebaseRoomUserDb {
  [room_name: string]: FirebaseRoomUserOnlyUserDb;
}

type userid = string;

export interface FirebaseRoomUser {
  users: {
    [ROOM_NAME_BASE.MAIN]?: userid[];
    [ROOM_NAME_BASE.PREP_AUDIENCE]?: userid[];
    [ROOM_NAME_BASE.REFLEC_JUDGE]?: userid[];
    [ROOM_NAME_BASE.AUDITRIUM]?: userid[];
    [ROOM_NAME_BASE.PRESENTATION]?: userid[];
    [ROOM_NAME_BASE.JISTI]?: userid[];
    [TEAM_NAME.NA_GOV]?: userid[];
    [TEAM_NAME.NA_OPP]?: userid[];
    [TEAM_NAME.PDA_GOV]?: userid[];
    [TEAM_NAME.PDA_OPP]?: userid[];
    [TEAM_NAME.WSC_GOV]?: userid[];
    [TEAM_NAME.WSC_OPP]?: userid[];
    [TEAM_NAME.ASIAN_PROPOSITION]?: userid[];
    [TEAM_NAME.ASIAN_OPPOSITION]?: userid[];
    [TEAM_NAME.WSDC_PROPOSITION]?: userid[];
    [TEAM_NAME.WSDC_OPPOSITION]?: userid[];
    [TEAM_NAME.WSDC_SHORT_PROPOSITION]?: userid[];
    [TEAM_NAME.WSDC_SHORT_OPPOSITION]?: userid[];
    [TEAM_NAME.BP_OG]?: userid[];
    [TEAM_NAME.BP_OO]?: userid[];
    [TEAM_NAME.BP_CG]?: userid[];
    [TEAM_NAME.BP_CO]?: userid[];
    [TEAM_NAME.PHS_PROPOSITION]?: userid[];
    [TEAM_NAME.PHS_OPPOSITION]?: userid[];
  };
  num: number;
}

export const ROOM_SHOWNAME = {
  [ROOM_NAME_BASE.MAIN]: `main room`,
  [ROOM_NAME_BASE.PREP_AUDIENCE]: `audience room`,
  [ROOM_NAME_BASE.REFLEC_JUDGE]: `judge room`,
  [ROOM_NAME_BASE.AUDITRIUM]: `auditrium`,
  [ROOM_NAME_BASE.PRESENTATION]: `presentation`,
  [ROOM_NAME_BASE.JISTI]: `jisti`,
  [TEAM_NAME.NA_GOV]: `gov room`,
  [TEAM_NAME.NA_OPP]: `opp room`,
  [TEAM_NAME.PDA_GOV]: `gov room`,
  [TEAM_NAME.PDA_OPP]: `opp room`,
  [TEAM_NAME.WSC_GOV]: `gov room`,
  [TEAM_NAME.WSC_OPP]: `opp room`,
  [TEAM_NAME.ASIAN_PROPOSITION]: `prop room`,
  [TEAM_NAME.ASIAN_OPPOSITION]: `opp room`,
  [TEAM_NAME.WSDC_PROPOSITION]: `prop room`,
  [TEAM_NAME.WSDC_OPPOSITION]: `opp room`,
  [TEAM_NAME.WSDC_SHORT_PROPOSITION]: `prop room`,
  [TEAM_NAME.WSDC_SHORT_OPPOSITION]: `opp room`,
  [TEAM_NAME.BP_OG]: `OG room`,
  [TEAM_NAME.BP_OO]: `OO room`,
  [TEAM_NAME.BP_CG]: `CG room`,
  [TEAM_NAME.BP_CO]: `CO room`,
  [TEAM_NAME.PHS_PROPOSITION]: `prop room`,
  [TEAM_NAME.PHS_OPPOSITION]: `opp room`,
};

export const DEFAULT_FIREBASE_ROOM_USER: FirebaseRoomUser = {
  users: {},
  num: 0,
};

export type UserId = string;

export interface ChildroomInAuditriumRoomUserDb {
  [room_name: string]: UserId[];
}

export enum LAYOUT_TYPE_IN_AUDITORIUM_ID {
  GAME = '1',
  PRESENTATION = 'p',
  JISTI = 'j',
}

interface AuditriumInformation {
  layout_type_id: LAYOUT_TYPE_IN_AUDITORIUM_ID | null;
  room_num_in_auditrium: string | null;
}

// const game_in_auditrium_room_name = `${layout_type_id}_${room_num_in_auditrium}`;
//  assume game_in_auditrium_room_name have this structure

export function get_auditrium_info(game_in_auditrium_room_name: string): AuditriumInformation {
  const auditrium_info = game_in_auditrium_room_name.split('_');

  if (auditrium_info && auditrium_info[0] && auditrium_info[1]) {
    const layout_type_id: LAYOUT_TYPE_IN_AUDITORIUM_ID = auditrium_info[0] as LAYOUT_TYPE_IN_AUDITORIUM_ID;
    const room_num_in_auditrium = auditrium_info[1];

    return { layout_type_id: layout_type_id, room_num_in_auditrium };
  } else {
    return { layout_type_id: null, room_num_in_auditrium: null };
  }
}

export function getChildroomInAuditriumShowNameFromRoomId(room_id: string) {
  const arr = room_id.split('_');
  if (arr && arr[1]) {
    return getChildroomInAuditriumShowName(arr[0] as LAYOUT_TYPE_IN_AUDITORIUM_ID, arr[1]);
  } else {
    return '';
  }
}

export function getChildroomInAuditriumShowName(layout_type_id: LAYOUT_TYPE_IN_AUDITORIUM_ID, value: string): string {
  if (layout_type_id === LAYOUT_TYPE_IN_AUDITORIUM_ID.PRESENTATION) {
    return `Presen ${value}`;
  } else if (layout_type_id === LAYOUT_TYPE_IN_AUDITORIUM_ID.JISTI) {
    return `Jisti ${value}`;
  } else {
    const number = 100 + Number(value);
    return String(number);
  }
}
