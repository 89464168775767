import { Participate } from '../constant/participate';
import { EVENT_TYPE } from '../constant/event_type';
import { GroupDataWithId } from './group-data';
import { PresentationInAuditoriumData, PresentationData } from './presentation';
import { VIDEO_QUALITY } from '../constant/video';
import { RegisteredTeamNum } from './registered-team';
import firebase from 'firebase/compat/app';
/*string here is userid */

export interface ParticipantSubcollectionData {
  type?: Participate;
  // start_timestamp?: firebase.firestore.Timestamp;
  set_by?: string;
}

export interface ParticipantSubcollectionDataWithId extends ParticipantSubcollectionData {
  id: string;
}

export interface OrganizerSubcollectionData {
  set_by?: string;
}

export interface OrganizerSubcollectionDataWithId extends OrganizerSubcollectionData {
  id: string;
}

// interface DateAndTimestamp extends firebase.firestore.Timestamp, Date {
// }

export enum EVENT_APPLICATION_QUALIFICATION {
  ANYONE = 'ANYONE',
  GROUP_MEMBER = 'GROUP_MEMBER',
  SCHOOL_MEMBER = 'SCHOOL_MEMBER',
  INVITED = 'INVITED',
}

export enum ARTICLE_SHARED_RANGE {
  PUBLIC = 'PUBLIC',
  GROUP = 'GROUP',
  PARTICIPANT = 'PARTICIPANT',
  ORGANIZER = 'ORGANIZER',
}

export enum TOURNAMENT_TAB {
  TABBY_CAT = 'TABBY_CAT',
  TABBR = 'TABBR',
  NO = 'NO',
}

export interface EventData {
  created_by?: string;
  duration?: number;
  participants_going?: userid[];
  participants_invited?: userid[];
  participants_cannotgo?: userid[];
  all_related_users?: userid[];
  title?: string;
  motion?: string;
  type?: EVENT_TYPE;

  start_timestamp?: firebase.firestore.Timestamp;
  article_shared_range?: ARTICLE_SHARED_RANGE;
  application_qualification?: EVENT_APPLICATION_QUALIFICATION;
  recording_activation?: boolean;
  associated_group?: GroupDataWithId; // possibly cyclic injection

  timezone?: number;
  hide_event?: boolean;
  use_auditrium?: boolean;
  exist_organizer?: boolean;
  use_preparation_ready_status?: boolean;
  mandatory_select_registered_team?: boolean;
  additional_organizer_room?: boolean;
  motion_only_by_organizer?: boolean;
  allocation_only_by_organizer?: boolean;
  remove_previous_round_data?: boolean;
  only_organizer_talk?: boolean;
  // presentation_room_in_auditorium?: boolean;
  presentation?: PresentationData;
  presentation_in_auditorium?: PresentationInAuditoriumData;
  large_event?: boolean;
  initially_receive_only_mode?: boolean;
  use_video?: boolean;
  presentation_control_only_organizer?: boolean;
  tab_type?: TOURNAMENT_TAB;
  video_quality?: VIDEO_QUALITY;
  zoom_lesson?: boolean;
  jisti_lesson?: boolean;
  one_on_one?: boolean;
  // use_video_auditorium?: boolean;
  /* for backward compatiblity */
  // date_time_start?: number; // just for old unnecessary code
  // date_time_finish?: number; // just for old unnecessary code
  // finish_datetime?:  firebase.firestore.Timestamp; // just for old unnecessary code
  registered_team_num_obj?: RegisteredTeamNum;
}

export interface EventDataWithId extends EventData {
  id?: string;
}

export interface EventDataTimeDateConverted extends EventData {
  start_date?: Date;
}

export interface EventDataTimeDateConvertedWithId extends EventDataTimeDateConverted {
  id?: string;
}

export interface ViewModelPartOfEventData {
  is_event_owner?: boolean;
  have_join_qualification?: boolean;
  own_user_id?: string;
  assigned?: boolean;
}

export interface EventDataVm extends EventDataTimeDateConverted, ViewModelPartOfEventData {}

export interface EventDataVmWithId extends EventDataVm {
  id?: string;
}

export interface ZoomData {
  id: string;
  created_at: Date;
  duration: number; // minutes
  h323_password: string;
  host_email: string;
  host_id: string;
  join_url: string;
  password: string;
  pre_schedule: boolean;
  pstn_password: string;
  settings: any;
  start_time: string;
  start_url: string;
  status: string;
  timezone: string;
  topic: string;
  type: number;
  uuid: string;
}

export enum EVENT_TIMING {
  PAST = 'PAST',
  ONGOING = 'ONGOING', // within 2 hours after start
  UPCOMING = 'UPCOMING', // in 2 hour before start
  FUTURE = 'FUTURE',
  // UNKNOWN = 'UNKNOWN',
}

export const GAME_DURATION = 1000 * 60 * 60 * 3;
export const UPCOMING_DURATION = 1000 * 60 * 60 * 6;
export const MAX_EVENTLIST_GROUP = 7;
export const MAX_PAST_EVENTLIST_GROUP = 2;

export interface EventDataMyAndOther {
  my_event: EventDataVmWithId[];
  other_event: EventDataVmWithId[];
}

export interface EventRemainingTime {
  remainint_time?: number;
  is_already_finished?: boolean;
  is_already_started?: boolean;
  is_in_one_hour?: boolean;
  is_in_three_hours?: boolean;
  is_in_one_day?: boolean;
}

export interface EventDataWithIdWithOwntype extends EventDataTimeDateConverted {
  own_participate_type?: Participate;
}

type userid = string;

export interface ParticipantInfo {
  participants_going?: userid[];
  participants_maybe?: userid[];
  participants_invited?: userid[];
  participants_propoistion?: userid[];
  participants_opposition?: userid[];
  own_participate_status?: Participate;
}

export const DEFAULT_PARTICIPATE_INFO = {
  participants_going: [],
  participants_maybe: [],
  participants_invited: [],
  participants_propoistion: [],
  participants_opposition: [],
  own_participate_status: null,
};

export interface EventDataWithParticipateInfo extends ParticipantInfo, EventData {}

export interface GroupDataOnevent {
  group_id?: string;
  group_name: string;
}
