export enum SEARCH_TYPE {
  EVENT_INVITE = 'SEARCH_TYPE_EVENT_INVITE',
  PASS_SELECTEDUSER = 'SEARCH_TYPE_PASS_SELECTEDUSER',
  ADD_ORGANIZER = 'ADD_ORGANIZER',
}

export enum SEARCH_STATUS {
  ALREADYSELECTED = 'SEARCH_STATUS_ALREADYSELECTED',
  TEMPORALYSELECTED = 'SEARCH_STATUS_TEMPORALYSELECTED',
  NOTSELECTED = 'SEARCH_STATUS_NOTSELECTED',
}

export interface SelectedUsersResult {
  user_added?: Array<string>;
  user_deleted?: Array<string>;
  selected_users_result?: Array<string>;
}

export interface SearchUsersModalopenData {
  search_type: string;
  temporaly_selected_users?: Array<string>;
  alreaey_selected_users?: Array<string>;
  event_id?: string;
}
