<header class="header">
  <div class="header-logo">
    <a class="navbar-brand" (click)="goto_home()">
      <img src="https://storage.googleapis.com/mixidea_resource/m-logotype2.svg" alt="MIXIDEA" height="30" />
    </a>
  </div>

  <ul class="header-tools">
    <li class="header-tool -school-link">
      <a class="AnchorBold" (click)="goto_school()" href="javascript:void(0)">School</a>
    </li>
    <li class="header-tool -group-link">
      <a class="AnchorBold" (click)="goto_grouplist()" href="javascript:void(0)">Group</a>
    </li>
    <li class="header-tool -event-link">
      <a class="AnchorBold" (click)="goto_eventlist()" href="javascript:void(0)">Event</a>
    </li>

    <li class="header-tool">
      <ng-container *ngIf="own_user.loggedIn">
        <ng-container *ngIf="own_user.pict_src; else setProfile">
          <a (click)="goto_dashboard()">
            <img src="{{ own_user.pict_src }}" alt="" class="user-icon" />
          </a>
        </ng-container>
        <ng-template #setProfile>
          <button (click)="set_profile_picture()">set profile picture</button>
        </ng-template>
      </ng-container>

      <ng-container *ngIf="!own_user.loggedIn && initialized && !show_login_delay">
        <button class="btn is-login" type="button" (click)="show_login_modal()">
          <!-- <i class="fab fa-facebook mr-2" aria-hidden="true"></i> -->
          Login
        </button>
      </ng-container>
    </li>
  </ul>
</header>
