<div>
  <div class="close-div">
    &nbsp;
    <button type="button" class="close" (click)="hide_line_modal()">
      <span class="fas fa-times" aria-label="close"></span>
    </button>
  </div>

  <div class="register-message">講義の実施予定などは、Line公式アカウントから通知されますので、登録をお願いいたします。</div>
  <button class="login-btn" (click)="select_line_login()">
    <img src="https://storage.googleapis.com/mixidea_resource/line_btn_login_base.png" />
  </button>
</div>
