<div style="padding: 24px">
  <h2 class="Heading">Presentation Upload</h2>

  <p>
    Only <strong class="PdfStrong"><span class="fas fa-file-pdf" aria-hidden="true"></span> PDF file</strong> is available for presentation.
  </p>

  <ng-container *ngIf="!under_upload; else underUpload">
    <ng-container *ngIf="current_file; else noCurrentFile">
      <p>
        <span class="current-file">{{ current_file }}</span> has been already uploaded.
      </p>
      <p>Uploading another file, overwritte previous one</p>
      <div>
        <input type="file" accept="application/pdf" (change)="selectFile($event)" name="switch pdf file" />
      </div>
    </ng-container>
    <ng-template #noCurrentFile>
      <div>
        <label for="upload-presentation-modal-input-file">Upload new file</label>
        <input
          id="upload-presentation-modal-input-file"
          type="file"
          accept="application/pdf"
          (change)="selectFile($event)"
          name="select pdf file"
        />
      </div>
    </ng-template>

    <div class="upload-container">
      <button class="btn btn-primary" (click)="uploadFile()" [disabled]="!file_name">Upload</button>
    </div>
  </ng-container>

  <ng-template #underUpload> under upload <span class="fas fa-spinner fa-pulse" aria-hidden="true"></span> </ng-template>
</div>
