export enum LOG_KEY {
  origin = 'origin',
  level = 'level',
  tech = 'tech',
  user_name = 'user_name',
  file_name = 'file_name',
  message = 'message',
  type = 'type',
  server_time = 'server_time',
  client_time = 'client_time',
  event_id = 'event_id',
  user_id = 'user_id',
  teacher_id = 'teacher_id',
  module = 'module',
  element = 'element',
  send_type = 'send_type',
  browser = 'browser',
  useragent = 'useragent',
  trace = 'trace',
}

export enum LOG_ORIGIN {
  CLIENT = 'client',
  SERVER = 'server',
}

export enum LOG_LEVEL {
  ERROR = 'error',
  WARN = 'warn',
  INFO = 'info',
  DEBUG = 'debug',
  LOG = 'log',
  DISCONNECTMONITOR = 'disconnect_monitor',
}

export enum LOG_TECH {
  skyway = 'skyway',
  agora = 'agora',
  usermedia = 'usermedia',
  firebase = 'firebase',
  socket = 'socket',
  transcript = 'transcript',
  recording = 'recording',
  snapshot = 'snapshot',
  authenticate = 'authenticate',
  line = 'line',
  business = 'business',
  network = 'network',
  SpeechRecognition = 'speech_recognition',
  RXJS = 'rxjs',
  routeguard = 'routeguard',
  reflesh = 'reflesh',
  retry = 'retry_connection',
  modal = 'modal',
  form = 'form',
  Global = 'global',
  localrecord = 'local-record',
}

export enum LOG_MODULE {
  WrittendebateArticle = 'WrittendebateArticle',
  LiveVideoDebate = 'LiveVideoDebate',
  AgoraLib = 'AgoraLib',
  VideocllEnvCheck = 'VideocllEnvCheck',
  Core = 'Core',
  Event = 'Event',
  SharedArticle = 'SharedArticle',
  CloudFunction = 'CloudFunction',
  MailLogin = 'MailLogin',
  Line = 'Line',
}

export enum LOG_TYPE {
  ComponentInit = 'ComponentInit',
  RouterParamSubscript = 'RouterParamSubscript',
  ComponentChange = 'ComponentChange',
  Subscription = 'Subscription',
  ServiceConst = 'ServiceConst',
  UserAction = 'UserAction',
  Keypoint = 'Keypoint',
  LayoutInit = 'LayoutInit',
  PageView = 'PageView',
  Capability = 'Capability',
  DataRetrieve = 'DataRetrieve',
  Router = 'Router',
  CloudFunction = 'CloudFunction',
  ALL = 'all',
}
