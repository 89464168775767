import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { map, filter, take } from 'rxjs/operators';
import {
  UserProfile,
  UserBasicVm,
  UserBasicDb,
  convert_user_DbToVm,
  UserFbToken,
  BLANK_USER_DATA,
  EachMessageChatOrPost,
  EachMessageChatOrPostWithKey,
  AttachmentChatOrPost,
  Participate,
  ArticleDataWithKey,
  JistiTimer,
} from '@mixidea-client/model';

import { FirebaseRtdService } from './service/firebase-rtd.service';
import { CHAT_TYPE, CHAT_TEAM, CHAT_TEAM_TYPE } from '@mixidea-client/model';
@Injectable()
export class FirebaseRtdApi {
  constructor(private firebaseRtdService: FirebaseRtdService) {}

  // approve withdrawal by email link access
  getUserProfile(user_id: string): Observable<UserProfile> {
    const path = '/users/fb_graph/' + user_id;
    return this.firebaseRtdService.getObjectValueOnce<UserProfile>(path).pipe(filter((data) => !!data));
  }

  getUserInputNameOnce(user_id) {
    const path = '/users/input_name/' + user_id;
    return this.firebaseRtdService.getObjectValueOnce<UserProfile>(path).pipe(take(1));
  }

  getAllUserBasic(): Observable<any[]> {
    const path = '/users/user_basic/';
    return this.firebaseRtdService.getSnapshotList<any[]>(path);
    // .catch(() => {
    //   return Observable.of(BLANK_USER_DATA);
    // });
  }

  getUserBasic(user_id: string): Observable<UserBasicVm> {
    const path = '/users/user_basic/' + user_id;
    return this.firebaseRtdService
      .getObjectValueOnce<UserBasicDb>(path)
      .pipe(map((user_data_db: UserBasicDb) => convert_user_DbToVm(user_data_db, user_id)));
    // .catch(() => {
    //   return Observable.of(BLANK_USER_DATA);
    // });
  }

  getUserPrivilege(user_id: string): Observable<boolean> {
    const path = '/users/privilege/' + user_id;
    return this.firebaseRtdService.getObjectValueOnce<boolean>(path);
  }

  setUserFbToken(user_id: string, token_obj: UserFbToken): Promise<void> {
    const path = '/users/fb_token/' + user_id;
    return this.firebaseRtdService.updateObject(path, token_obj);
  }

  setUserInputName(
    own_userid: string,
    first_name: string,
    last_name: string,
    first_local_name: string,
    last_local_name: string,
  ): Promise<void> {
    const user_name_obj = {
      first_name,
      last_name,
      first_local_name,
      last_local_name,
    };
    const path = '/users/input_name/' + own_userid;
    return this.firebaseRtdService.updateObject(path, user_name_obj);
  }

  setUserInputNameMailLogin(
    own_userid: string,
    first_name: string,
    last_name: string,
    first_local_name: string,
    last_local_name: string,
  ): Promise<void> {
    const user_name_obj = {
      first_name,
      last_name,
      first_local_name,
      last_local_name,
    };
    const path = '/users/input_name_mail_login/' + own_userid;
    return this.firebaseRtdService.updateObject(path, user_name_obj);
  }

  setUserTwitterToken(user_id: string, token_obj: UserFbToken): Promise<void> {
    const path = '/users/twitter_token/' + user_id;
    return this.firebaseRtdService.updateObject(path, token_obj);
  }

  getEventPost(event_id: string): Observable<EachMessageChatOrPostWithKey[]> {
    const path = '/event_related/event_chat_2/' + event_id;
    return this.firebaseRtdService.getSnapshotList<EachMessageChatOrPostWithKey>(path);
  }

  getEventPostDescendent(event_id: string): Observable<EachMessageChatOrPostWithKey[]> {
    const path = '/event_related/event_chat_2/' + event_id;
    return this.firebaseRtdService.getSnapshotListDescendent<EachMessageChatOrPostWithKey>(path);
  }

  deletePost(event_id: string, key: string): Promise<void> {
    const path = `/event_related/event_chat_2/${event_id}/${key}`;
    return this.firebaseRtdService.removeObject(path);
  }

  getGroupPostDescendent(group_id: string): Observable<EachMessageChatOrPostWithKey[]> {
    const path = '/group/group_post_2/' + group_id;
    return this.firebaseRtdService.getSnapshotListDescendent<EachMessageChatOrPostWithKey>(path);
  }

  pushFileInfoEventChat(event_id: string, user_id: string, file_name: string, downloadURL: string): PromiseLike<void> {
    const obj: AttachmentChatOrPost = {
      type: CHAT_TYPE.ATTACHMENT,
      team: CHAT_TEAM.ALL,
      user_id: user_id,
      date: Date.now(),
      content: file_name,
      attachment_url: downloadURL,
    };
    const path = '/event_related/event_chat_2/' + event_id;

    return this.firebaseRtdService.pushList<AttachmentChatOrPost>(path, obj);
  }

  pushFileInfoEventPost(event_id: string, user_id: string, file_name: string, downloadURL: string): PromiseLike<void> {
    return this.pushFileInfoEventChat(event_id, user_id, file_name, downloadURL);
    // const obj: EachMessageChatOrPost = {
    //   user_id : user_id,
    //   attachment_name: file_name,
    //   attachment_url: downloadURL
    // }
    // const path = '/event_related/event_chat/' +  event_id + '/main';

    // return this.firebaseRtdService.push<EachMessageChatOrPost>(path, obj);
  }

  pushFileInfoGoupPost(group_id: string, user_id: string, file_name: string, downloadURL: string): PromiseLike<void> {
    const obj: AttachmentChatOrPost = {
      type: CHAT_TYPE.ATTACHMENT,
      team: CHAT_TEAM.ALL,
      user_id: user_id,
      date: Date.now(),
      content: file_name,
      attachment_url: downloadURL,
    };
    const path = '/group/group_post_2/' + group_id;

    return this.firebaseRtdService.pushList<AttachmentChatOrPost>(path, obj);
  }

  pushEventPost(event_id: string, post: EachMessageChatOrPost): PromiseLike<void> {
    const path = '/event_related/event_chat_2/' + event_id;
    return this.firebaseRtdService.pushList<EachMessageChatOrPost>(path, post);
  }

  pushGroupPost(group_id: string, post_message: string, user_id: string): PromiseLike<void> {
    const path = 'group/group_post_2/' + group_id;
    const obj: EachMessageChatOrPost = {
      type: CHAT_TYPE.MESSAGE,
      team: CHAT_TEAM.ALL,
      user_id: user_id,
      date: Date.now(),
      content: post_message,
    };
    return this.firebaseRtdService.pushList<EachMessageChatOrPost>(path, obj);
  }

  get_eventlist_once(): Observable<any[]> {
    const path = '/event_related/event/';
    return this.firebaseRtdService.getSnapshotListDescendent<any>(path);
  }

  get_event_once$(event_id: string): Observable<any> {
    const path = '/event_related/event/' + event_id;
    return this.firebaseRtdService.getObjectValueOnce<any>(path);
  }

  get_event$(event_id: string): Observable<any> {
    const path = '/event_related/event/' + event_id;
    return this.firebaseRtdService.getObjectValue<any>(path);
  }

  setParticipateOnEventcontext(event_id: string, own_user_id: string, participate_type: Participate): Promise<void> {
    const path = '/event_related/event/' + event_id + '/participants/' + own_user_id;
    return this.firebaseRtdService.setObject(path, participate_type);
  }

  getArticlelist$(): Observable<ArticleDataWithKey[]> {
    const path = 'event_related/article/';
    return this.firebaseRtdService.getSnapshotListDescendent<ArticleDataWithKey>(path);
  }

  getMyArticleList$(own_user_id: string): Observable<ArticleDataWithKey[]> {
    const path = '/users/article/' + own_user_id;
    return this.firebaseRtdService.getSnapshotListDescendent<ArticleDataWithKey>(path);
  }

  getJistiTimer$(event_id: string): Observable<JistiTimer> {
    const path = '/event_related/jisti_timer/' + event_id;
    return this.firebaseRtdService.getObjectValue<JistiTimer>(path);
  }

  setJistiTimer(event_id: string, jisti_time): Promise<void> {
    const path = '/event_related/jisti_timer/' + event_id;
    return this.firebaseRtdService.setObject(path, jisti_time);
  }
}
