<div class="Wrapper">
  <ng-container *ngIf="user$ | async as user">
    <ng-container *ngIf="usershow_order === USERSHOW_ORDER_ICON_NAME.LEFTNAME_RIGHTICON; else notLeftName">
      <div class="Name -reverse" [ngStyle]="{ fontSize: font_size || '14px' }">
        {{ user?.full_name }}
      </div>
      <div class="Image">
        <img
          class="circle"
          loading="lazy"
          [ngStyle]="{ width: width || '50px', height: height || '50px', 'vertical-align': vertical_align }"
          [src]="user?.pict_src"
        />
      </div>
    </ng-container>
    <ng-template #notLeftName>
      <div class="Image">
        <img
          class="circle"
          [ngStyle]="{ width: width || '50px', height: height || '50px', 'vertical-align': vertical_align }"
          [src]="user?.pict_src"
        />
      </div>
      <div class="Name" [ngStyle]="{ fontSize: font_size || '14px' }">
        {{ user?.full_name }}
      </div>
    </ng-template>
  </ng-container>
</div>
