import { Injectable } from '@angular/core';

import { AngularFireDatabase, AngularFireObject, AngularFireList } from '@angular/fire/compat/database';
// import firebase from 'firebase/compat/app';

import { Observable } from 'rxjs';
import { take, map } from 'rxjs/operators';

@Injectable()
export class FirebaseRtdService {
  constructor(private af_db: AngularFireDatabase) {}

  // this should be fixed to set keys for return value;

  getObjectSnapshotOnce<T>(path): Observable<T> {
    const ref: AngularFireObject<T> = this.af_db.object(path);
    return ref.snapshotChanges().pipe(
      take(1),
      map((snapshot: any) => {
        return snapshot.payload.val();
      }),
    );
  }

  getObjectValueOnce<T>(path): Observable<T> {
    const ref: AngularFireObject<T> = this.af_db.object(path);
    return ref.valueChanges().pipe(take(1));
  }

  getObjectValue<T>(path): Observable<T> {
    const ref: AngularFireObject<T> = this.af_db.object(path);
    return ref.valueChanges();
  }

  updateObject(path, data): Promise<void> {
    const updateObservable = this.af_db.object(path);
    return updateObservable.update(data);
  }

  setObject(path, data): Promise<void> {
    const setObservable = this.af_db.object(path);
    return setObservable.set(data);
  }

  removeObject(path): Promise<void> {
    const removeObservable = this.af_db.object(path);
    return removeObservable.remove();
  }

  getSnapshotList<T>(path): Observable<T[]> {
    const ref: AngularFireList<T> = this.af_db.list(path);
    return ref.snapshotChanges().pipe(
      map((changes: any[]) => {
        return changes.map((c) => ({ key: c.payload.key, ...c.payload.val() }));
      }),
    );
  }

  getSnapshotListDescendent<T>(path): Observable<T[]> {
    const ref: AngularFireList<T[]> = this.af_db.list(path, (referrence) => referrence.orderByKey().limitToFirst(1000));
    return ref.snapshotChanges().pipe(
      map((changes: any[]) => {
        const list_descendent: T[] = [];
        changes.forEach((snapshot) => {
          list_descendent.unshift({ key: snapshot.payload.key, ...snapshot.payload.val() });
        });
        return list_descendent;
      }),
    );
  }

  pushList<T>(path, data): PromiseLike<any> {
    const ref: AngularFireList<T> = this.af_db.list(path);
    return ref.push(data);
  }
}
