

import { StudyContentCategory, StudyContentGroup  } from '../interface/study';

export const CONTENT_CAteGORY_ARR: StudyContentCategory[] = [
  {
    id: '1',
    title: 'debate skill',
  },
  {
    id: '2',
    title: 'matter',
  },
];


export const STUDY_CONTENT_GROUP_DATA_ARR: StudyContentGroup[] = [
  {
    id: "001",
    content_category_id: "1",
    title: "AREA",
    content_group_thumbnail: "https://storage.googleapis.com/valid-weaver-296705.appspot.com/group-2/CfEdpimOJ7oXnWxETkxB/icon_emblem.jpg",
    description: "スピーチを論理的に表現する方法を学ぼう"
  },
  {
    id: "002",
    content_category_id: "2",
    title: "feminism",
    content_group_thumbnail: "https://storage.googleapis.com/valid-weaver-296705.appspot.com/group-2/CfEdpimOJ7oXnWxETkxB/icon_emblem.jpg",
    description: "feminismについて学ぼう"
  },
  {
    id: "003",
    content_category_id: "1",
    title: "反論",
    content_group_thumbnail: "https://storage.googleapis.com/valid-weaver-296705.appspot.com/group-2/CfEdpimOJ7oXnWxETkxB/icon_emblem.jpg",
    description: "相手のスピーチを聞いて、エンゲージできるようになろう"
  },
  {
    id: "004",
    content_category_id: "1",
    title: "Practical",
    content_group_thumbnail: "https://storage.googleapis.com/valid-weaver-296705.appspot.com/group-2/CfEdpimOJ7oXnWxETkxB/icon_emblem.jpg",
    description: "Practical(mechanism + impact)について学ぼう"
  },
  {
    id: "005",
    content_category_id: "1",
    title: "準備型",
    content_group_thumbnail: "https://storage.googleapis.com/valid-weaver-296705.appspot.com/group-2/CfEdpimOJ7oXnWxETkxB/icon_emblem.jpg",
    description: "調査した内容でArgumentを作ってみよう"
  },
  {
    id: "006",
    content_category_id: "1",
    title: "セットアップ",
    content_group_thumbnail: "https://storage.googleapis.com/valid-weaver-296705.appspot.com/group-2/CfEdpimOJ7oXnWxETkxB/icon_emblem.jpg",
    description: "ラウンドの前提を整えよう"
  },
  {
    id: "007",
    content_category_id: "2",
    title: "Choice",
    content_group_thumbnail: "https://storage.googleapis.com/valid-weaver-296705.appspot.com/group-2/CfEdpimOJ7oXnWxETkxB/icon_emblem.jpg",
    description: "Choice(自由と制限)について学ぼう"
  },
  {
    id: "008",
    content_category_id: "1",
    title: "文章全体構成",
    content_group_thumbnail: "https://storage.googleapis.com/valid-weaver-296705.appspot.com/group-2/CfEdpimOJ7oXnWxETkxB/icon_emblem.jpg",
    description: "文章全体構成"
  },
  {
    id: "009",
    content_category_id: "2",
    title: "education",
    content_group_thumbnail: "https://storage.googleapis.com/valid-weaver-296705.appspot.com/group-2/CfEdpimOJ7oXnWxETkxB/icon_emblem.jpg",
    description: "education"
  },
  {
    id: "010",
    content_category_id: "1",
    title: "inntroduction summary",
    content_group_thumbnail: "https://storage.googleapis.com/valid-weaver-296705.appspot.com/group-2/CfEdpimOJ7oXnWxETkxB/icon_emblem.jpg",
    description: "inntroduction summary"
  },
  {
    id: "011",
    content_category_id: "1",
    title: "Freedom",
    content_group_thumbnail: "https://storage.googleapis.com/valid-weaver-296705.appspot.com/group-2/CfEdpimOJ7oXnWxETkxB/icon_emblem.jpg",
    description: "自由英作文"
  }
]