import { Injectable } from '@angular/core';

import { AngularFireDatabase } from '@angular/fire/compat/database';
// import firebase from 'firebase/compat/app';

import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable()
export class FirebaseRtdPureService {
  internet_connected$: BehaviorSubject<boolean> = null;

  constructor(private af_db: AngularFireDatabase) {}

  update_multiple_object(updateObject: Object): Promise<void> {
    return this.af_db.database.ref().update(updateObject);
  }

  private start_connection_subscription(): Observable<boolean> {
    // console.log('start_connection_subscription');
    this.internet_connected$ = this.internet_connected$ || new BehaviorSubject(false);

    const connectedRef = this.af_db.database.ref('.info/connected');
    connectedRef.on('value', (snap) => {
      if (snap.val() === true) {
        console.log('internet detected');
        this.internet_connected$.next(true);
      } else {
        console.log('internet not detected');
        this.internet_connected$.next(false);
      }
    });
    return this.internet_connected$.asObservable();
  }

  is_internet_connected$(): Observable<boolean> {
    if (this.internet_connected$) {
      return this.internet_connected$.asObservable().pipe(distinctUntilChanged());
    } else {
      return this.start_connection_subscription().pipe(distinctUntilChanged());
    }
  }

  removeWhenDisconnected(remove_path) {
    const remove_user_item = this.af_db.database.ref(remove_path);
    remove_user_item.onDisconnect().remove();
  }
}
