export enum GROUP_MEMBER_TYPE {
  ADMINISTRATOR = 'ADMINISTRATOR',
  CREATOR = 'CREATOR',
  MEMBER = 'MEMBER',
  FOLLOWER = 'FOLLOWER',
  INVITED = 'INVITED',
  REQUESTED = 'REQUESTED',
}

// export const GROUP_INVITE_PRIVILEGE_admin_only = 'GROUP_INVITE_PRIVILEGE_admin_only';
// export const GROUP_INVITE_PRIVILEGE_member = 'GROUP_INVITE_PRIVILEGE_member';

// export enum GROUP_INVITE_PRIVILEGE {
//   admin_only = 'GROUP_INVITE_PRIVILEGE_admin_only',
//   member = 'GROUP_INVITE_PRIVILEGE_member',
// }

export enum GROUP_MEMBERADD_PRIVILEGE {
  Administrator = 'GROUP_MEMBERADD_PRIVILEGE_administrator',
  member = 'GROUP_MEMBERADD_PRIVILEGE_member',
  anyone = 'GROUP_MEMBERADD_PRIVILEGE_anyone',
}
