import { Injectable } from '@angular/core';

import { ARTICLETYPE, ARTICLE_SHARED_RANGE } from '@mixidea-client/model';
import {
  RecordspeechInfoDb,
  RecordspeechInfoDbFunctionExtend,
  ArticleDataVmWithId,
  ArticleVm,
  ArticleDb,
  ArticleDbWithId,
  GroupData,
} from '@mixidea-client/model';
import { FirestoreService, QueryParam, ORDERBY_DIRECTION } from './services/firestore.service';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import firebase from 'firebase/compat/app';

@Injectable({
  providedIn: 'root',
})
export class FirestoreHeadlesschromeService {
  constructor(private firestoreService: FirestoreService) {}

  async setSpeechInfo(article_id: string, speech_id: string, speech_data: RecordspeechInfoDb): Promise<void> {
    const path = `livevideo-articles-2/${article_id}/speech/${speech_id}`;
    return this.firestoreService.setObject(path, speech_data);
  }

  async updateSpeechInfo(article_id: string, speech_id: string, speech_data: RecordspeechInfoDb): Promise<void> {
    const path = `livevideo-articles-2/${article_id}/speech/${speech_id}`;
    return this.firestoreService.updateObject(path, speech_data);
  }

  set_motion_article(article_id: string, motion: string): Promise<void> {
    const data: ArticleDb = {
      motion: motion,
      article_type: ARTICLETYPE.ONLINE_LIVEVIDEO_headresschrome,
      timestamp: firebase.firestore.Timestamp.fromDate(new Date()),
    };
    const path = `livevideo-articles-2/${article_id}`;
    return this.firestoreService.setMergeObject(path, data);
  }

  set_participant_article(article_id: string, participant_arr: string[]): Promise<void> {
    const data: ArticleDb = {
      participant: participant_arr,
      article_type: ARTICLETYPE.ONLINE_LIVEVIDEO_headresschrome,
      timestamp: firebase.firestore.Timestamp.fromDate(new Date()),
    };
    const path = `livevideo-articles-2/${article_id}`;
    return this.firestoreService.setMergeObject(path, data);
  }

  set_article_shared_range(article_id: string, article_shared_range: ARTICLE_SHARED_RANGE): Promise<void> {
    const data = { article_shared_range: article_shared_range };
    const path = `livevideo-articles-2/${article_id}`;
    return this.firestoreService.setMergeObject(path, data);
  }

  set_associated_group(article_id: string, associated_group: GroupData): Promise<void> {
    const data = { associated_group: associated_group };
    const path = `livevideo-articles-2/${article_id}`;
    return this.firestoreService.setMergeObject(path, data);
  }

  getLivevideoArticleSpeech(article_id: string): Observable<RecordspeechInfoDbFunctionExtend[]> {
    const path = `livevideo-articles-2/${article_id}/speech/`;
    return this.firestoreService.getSnapshotList<RecordspeechInfoDbFunctionExtend>(path);
  }

  getLivevideoArticle(article_id: string): Observable<ArticleVm> {
    const path = `livevideo-articles-2/${article_id}/`;
    return this.firestoreService.getObjectValue<ArticleDb>(path).pipe(map(firebaseTimestampDateMappingArticle));
  }

  // this is only for creating blank data
  setEventArticleUpdateTime(router_id: string): Promise<void> {
    const path = `event-article-info/${router_id}/`;
    const obj = { update_time: firebase.firestore.Timestamp.fromDate(new Date()) };

    return this.firestoreService.setMergeObject(path, obj);
  }

  addArticleIds(router_id: string, article_id: number): Promise<void> {
    const path = `event-article-info/${router_id}/`;
    const article_array_union = { articles: firebase.firestore.FieldValue.arrayUnion(article_id) };

    return this.firestoreService.updateObject(path, article_array_union);
  }

  setEventArticleCount(router_id: string, event_id: string, count: number): Promise<void> {
    const value = { count: count };
    const path = `event-article-info/${router_id}/count/${event_id}`;
    return this.firestoreService.setObject(path, value);
  }

  async getEventArticleCount(router_id, event_id: string): Promise<number> {
    const path = `event-article-info/${router_id}/count/${event_id}`;
    return this.firestoreService
      .getObjectValueOnce<{ count: number }>(path)
      .pipe(
        take(1),
        map((obj) => {
          return obj ? obj.count : 0;
        }),
      )
      .toPromise();
  }

  getOwnArticle$(own_user_id: string, limit: number | null): Observable<ArticleDataVmWithId[]> {
    const path = `livevideo-articles-2/`;

    const query_param: QueryParam = {
      where_param: [{ key: 'participant', criteria: 'array-contains', value: own_user_id }],
      orderby_param: { key: `timestamp`, order: ORDERBY_DIRECTION.DESCENDING },
      startat_param: null,
      startafter_param: null,
      limit_param: limit,
    };
    return this.firestoreService
      .getSnapshotListWithQuery<ArticleDbWithId>(path, query_param)
      .pipe(map(firebaseTimestampDateMappingArticleArr));
  }

  getAllArticle$(): Observable<ArticleDataVmWithId[]> {
    const path = `livevideo-articles-2/`;

    const query_param: QueryParam = {
      where_param: [],
      orderby_param: { key: 'timestamp', order: ORDERBY_DIRECTION.DESCENDING },
      startat_param: null,
      startafter_param: null,
    };
    return this.firestoreService
      .getSnapshotListWithQuery<ArticleDbWithId>(path, query_param)
      .pipe(map(firebaseTimestampDateMappingArticleArr));
  }

  getPublicArticle$(limit_number): Observable<ArticleDataVmWithId[]> {
    const path = `livevideo-articles-2/`;

    const query_param: QueryParam = {
      where_param: [{ key: 'article_shared_range', criteria: '==', value: ARTICLE_SHARED_RANGE.PUBLIC }],
      orderby_param: { key: 'timestamp', order: ORDERBY_DIRECTION.DESCENDING },
      limit_param: limit_number,
      startat_param: null,
      startafter_param: null,
    };
    return this.firestoreService
      .getSnapshotListWithQuery<ArticleDbWithId>(path, query_param)
      .pipe(map(firebaseTimestampDateMappingArticleArr));
  }

  delete_article(id) {
    const path2 = `livevideo-articles-2/${id}/speech`;

    return this.firestoreService
      .getSnapshotListOnce(path2)
      .toPromise()
      .then((snapshot) => {
        const promise_arr = [];

        snapshot.forEach((doc: any) => {
          console.log(doc);
          console.log(doc.id);
          const path3 = `livevideo-articles-2/${id}/speech/${doc.id}`;

          const promise = this.firestoreService.removeObject(path3);

          promise_arr.push(promise);
        });

        return Promise.all(promise_arr);
      })
      .then(() => {
        const path = `livevideo-articles-2/` + id;
        console.log(path);

        return this.firestoreService.removeObject(path);
      });
  }

  getGroupArticle$(group_id): Observable<ArticleDataVmWithId[]> {
    const path = `livevideo-articles-2/`;

    const query_param: QueryParam = {
      where_param: [
        { key: 'article_shared_range', criteria: '==', value: ARTICLE_SHARED_RANGE.GROUP },
        { key: 'associated_group.id', criteria: '==', value: group_id },
      ],
      orderby_param: { key: 'timestamp', order: ORDERBY_DIRECTION.DESCENDING },
      startat_param: null,
      startafter_param: null,
    };
    return this.firestoreService
      .getSnapshotListWithQuery<ArticleDbWithId>(path, query_param)
      .pipe(map(firebaseTimestampDateMappingArticleArr));
  }

  getGroupPublicArticle$(group_id): Observable<ArticleDataVmWithId[]> {
    const path = `livevideo-articles-2/`;

    const query_param: QueryParam = {
      where_param: [
        { key: 'article_shared_range', criteria: '==', value: ARTICLE_SHARED_RANGE.PUBLIC },
        { key: 'associated_group.id', criteria: '==', value: group_id },
      ],
      orderby_param: { key: 'timestamp', order: ORDERBY_DIRECTION.DESCENDING },
      startat_param: null,
      startafter_param: null,
    };
    return this.firestoreService
      .getSnapshotListWithQuery<ArticleDbWithId>(path, query_param)
      .pipe(map(firebaseTimestampDateMappingArticleArr));
  }
}

function firebaseTimestampDateMappingArticleArr(articledata_arr: ArticleDbWithId[]): ArticleDataVmWithId[] {
  return articledata_arr.map((data: ArticleDbWithId) => {
    if (data && data.timestamp) {
      return Object.assign(data, { date: data.timestamp.toDate() });
    } else {
      return data;
    }
  });
}

function firebaseTimestampDateMappingArticle(articledata: ArticleDb): ArticleVm {
  if (articledata && articledata.timestamp) {
    return Object.assign(articledata, { date: articledata.timestamp.toDate() });
  } else {
    return articledata;
  }
}
