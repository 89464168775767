export function read_cookie(key) {
  const cookie_data = document.cookie;
  const cookie_arr = cookie_data.split(';');
  for (let i = 0; i < cookie_arr.length; i++) {
    const cookie_each_arr = cookie_arr[i].split('=');
    const cookie_key = cookie_each_arr[0].replace(/ /g, '');
    if (cookie_key === key) {
      return cookie_each_arr[1];
    }
  }
  return null;
}

export function write_cookie(key, value, duration: number) {
  const current_time = new Date();
  const expiration_time = new Date();
  expiration_time.setTime(current_time.getTime() + duration);
  const cookie_str = key + '=' + value + ';expires=' + expiration_time.toUTCString() + ';path=/';
  document.cookie = cookie_str;
}

export function remove_cookie(key) {
  const cookie_str = `${key}=; expires=Thu, 01-Jan-70 00:00:01 GMT;`;
  document.cookie = cookie_str;
}

export const COOKIE_KEY_JUST_AFTER_TRANSITION = 'COOKIE_KEY_JUST_AFTER_TRANSITION';

export enum MICROPHONE_STATUS {
  ON = 'ON',
  OFF = 'OFF',
}
