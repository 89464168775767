import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import linkifyHtml from 'linkify-html';

@Component({
  selector: 'app-linklified-html',
  templateUrl: './linklified-html.component.html',
  styleUrls: ['./linklified-html.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinklifiedHtmlComponent implements OnInit {
  @Input()
  text: string;
  // @ViewChild('jsChatMessage') jsChatMessage: ElementRef;

  constructor() {}

  ngOnInit() {}

  get content_html() {
    return linkifyHtml(this.text, {
      defaultProtocol: 'https',
    }).replace(/\n/g, '<br>');
    return '';
  }
}
