import {
  ROLE_NAME,
  NA_ROLE_ARRAY,
  BP_ROLE_ARRAY,
  ASIAN_ROLE_ARRAY,
  PDA_ROLE_ARRAY,
  WSC_ROLE_ARRAY,
  WSDC_ROLE_ARRAY,
  WSDC_SHORT_ROLE_ARRAY,
  PHS_ROLE_ARRAY,
  RoleInfo,
} from './role';

export enum DEBATE_STYLE {
  NA = 'NA',
  ASIAN = 'ASIAN',
  BP = 'BP',
  PDA = 'PDA',
  AUSTRAL = 'AUSTRAL',
  WSC = 'WSC',
  WSDC = 'WSDC',
  WSDC_SHORT = 'WSDC_SHORT',
  PHS = 'PHS',
}

export const DEBATE_STYLE_ARR = [
  DEBATE_STYLE.NA,
  DEBATE_STYLE.ASIAN,
  DEBATE_STYLE.BP,
  DEBATE_STYLE.PDA,
  DEBATE_STYLE.WSC,
  DEBATE_STYLE.WSDC,
  DEBATE_STYLE.WSDC_SHORT,
  DEBATE_STYLE.PHS,
];

export interface StylePrepTime {
  prep_duration: number;
  deb_style: DEBATE_STYLE;
}

interface Explanation {
  name: string;
  explanation: string;
}

export const STYLE_LONG_NAME: { [key: string]: string } = {
  [DEBATE_STYLE.NA]: 'North American',
  [DEBATE_STYLE.ASIAN]: 'Asian',
  [DEBATE_STYLE.BP]: 'British Pearliamentary',
  [DEBATE_STYLE.PDA]: 'Japanese Highschool style',
  [DEBATE_STYLE.WSC]: 'World Scholar Cup',
  [DEBATE_STYLE.WSDC]: 'World Schol Debate Championship',
  [DEBATE_STYLE.WSDC_SHORT]: 'WSDC short version',
  [DEBATE_STYLE.PHS]: 'Polish High School Style',
};

export const STYLE_EXPLANATION: { [key: string]: string } = {
  [DEBATE_STYLE.NA]: ' 6 speech(four 7minutes + two 4 minutes )',
  [DEBATE_STYLE.ASIAN]: '8 speech(six 7 minutes + two 4 minutes )',
  [DEBATE_STYLE.BP]: '8 speech(eight 7minutes ) ',
  [DEBATE_STYLE.PDA]: ' 6 speech(four 3minutes + two 2 minutes ) ',
  [DEBATE_STYLE.WSC]: ' 6 speech( 4 minutes )',
  [DEBATE_STYLE.WSDC]: ' 8 speech( six 8 minutes + two 4 minutes ) ',
  [DEBATE_STYLE.WSDC_SHORT]: ' 8 speech( six 5 minutes + two 4 minutes ) ',
  [DEBATE_STYLE.PHS]: ' 8 speech( 5 minutes ) ',
};

// export type DEBATE_STYLE_TYPE = 'NA' | 'ASIAN' | 'BP' | 'AUSTRAL';

export const STYLE_ROLE_MAPPING: { [key: string]: Array<ROLE_NAME | string> } = {
  [DEBATE_STYLE.NA]: [ROLE_NAME.NA_PM, ROLE_NAME.NA_LO, ROLE_NAME.NA_MG, ROLE_NAME.NA_MO, ROLE_NAME.NA_LOR, ROLE_NAME.NA_PMR],
  [DEBATE_STYLE.PDA]: [ROLE_NAME.PDA_PM, ROLE_NAME.PDA_LO, ROLE_NAME.PDA_MG, ROLE_NAME.PDA_MO, ROLE_NAME.PDA_LOR, ROLE_NAME.PDA_PMR],
  [DEBATE_STYLE.WSC]: [ROLE_NAME.WSC_PM, ROLE_NAME.WSC_LO, ROLE_NAME.WSC_MG, ROLE_NAME.WSC_MO, ROLE_NAME.WSC_LOR, ROLE_NAME.WSC_PMR],
  [DEBATE_STYLE.ASIAN]: [
    ROLE_NAME.ASIAN_PM,
    ROLE_NAME.ASIAN_LO,
    ROLE_NAME.ASIAN_DPM,
    ROLE_NAME.ASIAN_DLO,
    ROLE_NAME.ASIAN_WG,
    ROLE_NAME.ASIAN_WO,
    ROLE_NAME.ASIAN_LOR,
    ROLE_NAME.ASIAN_PMR,
  ],
  [DEBATE_STYLE.WSDC]: [
    ROLE_NAME.WSDC_PM,
    ROLE_NAME.WSDC_LO,
    ROLE_NAME.WSDC_DPM,
    ROLE_NAME.WSDC_DLO,
    ROLE_NAME.WSDC_WG,
    ROLE_NAME.WSDC_WO,
    ROLE_NAME.WSDC_LOR,
    ROLE_NAME.WSDC_PMR,
  ],
  [DEBATE_STYLE.WSDC_SHORT]: [
    ROLE_NAME.WSDC_SHORT_PM,
    ROLE_NAME.WSDC_SHORT_LO,
    ROLE_NAME.WSDC_SHORT_DPM,
    ROLE_NAME.WSDC_SHORT_DLO,
    ROLE_NAME.WSDC_SHORT_WG,
    ROLE_NAME.WSDC_SHORT_WO,
    ROLE_NAME.WSDC_SHORT_LOR,
    ROLE_NAME.WSDC_SHORT_PMR,
  ],
  [DEBATE_STYLE.BP]: [
    ROLE_NAME.BP_PM,
    ROLE_NAME.BP_LO,
    ROLE_NAME.BP_DPM,
    ROLE_NAME.BP_DLO,
    ROLE_NAME.BP_MG,
    ROLE_NAME.BP_MO,
    ROLE_NAME.BP_GW,
    ROLE_NAME.BP_OW,
  ],
  [DEBATE_STYLE.PHS]: [
    ROLE_NAME.PHS_PM,
    ROLE_NAME.PHS_LO,
    ROLE_NAME.PHS_DPM,
    ROLE_NAME.PHS_DLO,
    ROLE_NAME.PHS_MG,
    ROLE_NAME.PHS_MO,
    ROLE_NAME.PHS_GW,
    ROLE_NAME.PHS_OW,
  ],
};

export const STYLE_ROLE_MAPPING_WITHORDER: { [key: string]: RoleInfo[] } = {
  [DEBATE_STYLE.NA]: NA_ROLE_ARRAY,
  [DEBATE_STYLE.PDA]: PDA_ROLE_ARRAY,
  [DEBATE_STYLE.WSC]: WSC_ROLE_ARRAY,
  [DEBATE_STYLE.ASIAN]: ASIAN_ROLE_ARRAY,
  [DEBATE_STYLE.WSDC]: WSDC_ROLE_ARRAY,
  [DEBATE_STYLE.WSDC_SHORT]: WSDC_SHORT_ROLE_ARRAY,
  [DEBATE_STYLE.BP]: BP_ROLE_ARRAY,
  [DEBATE_STYLE.PHS]: PHS_ROLE_ARRAY,
};
