import { ROLE_NAME } from './role';
import { SIDE } from './side';

import { DEBATE_STYLE } from './deb-style';
import { CHAT_TEAM } from './chat-type';
import { TEAM_NAME } from './team-enum';
import { ROOM_NAME_BASE } from './room';

// export const TEAM_PROPOSITION = 'proposition';
// export const TEAM_OPPOSITION = 'opposition';
// export const TEAM_GOV = 'gov';
// export const TEAM_OPP = 'opp';
// export const TEAM_OG = 'OG';
// export const TEAM_OO = 'OO';
// export const TEAM_CG = 'CG';
// export const TEAM_CO = 'CO';

export const TEAM_ROLE_MAPPINJG: { [key: string]: ROLE_NAME[] } = {
  [TEAM_NAME.ASIAN_PROPOSITION]: [ROLE_NAME.ASIAN_PM, ROLE_NAME.ASIAN_DPM, ROLE_NAME.ASIAN_WG, ROLE_NAME.ASIAN_PMR],
  [TEAM_NAME.ASIAN_OPPOSITION]: [ROLE_NAME.ASIAN_LO, ROLE_NAME.ASIAN_DLO, ROLE_NAME.ASIAN_WO, ROLE_NAME.ASIAN_LOR],
  [TEAM_NAME.WSDC_PROPOSITION]: [ROLE_NAME.WSDC_PM, ROLE_NAME.WSDC_DPM, ROLE_NAME.WSDC_WG, ROLE_NAME.WSDC_PMR],
  [TEAM_NAME.WSDC_OPPOSITION]: [ROLE_NAME.WSDC_LO, ROLE_NAME.WSDC_DLO, ROLE_NAME.WSDC_WO, ROLE_NAME.WSDC_LOR],
  [TEAM_NAME.WSDC_SHORT_PROPOSITION]: [
    ROLE_NAME.WSDC_SHORT_PM,
    ROLE_NAME.WSDC_SHORT_DPM,
    ROLE_NAME.WSDC_SHORT_WG,
    ROLE_NAME.WSDC_SHORT_PMR,
  ],
  [TEAM_NAME.WSDC_SHORT_OPPOSITION]: [ROLE_NAME.WSDC_SHORT_LO, ROLE_NAME.WSDC_SHORT_DLO, ROLE_NAME.WSDC_SHORT_WO, ROLE_NAME.WSDC_SHORT_LOR],
  [TEAM_NAME.NA_GOV]: [ROLE_NAME.NA_PM, ROLE_NAME.NA_MG, ROLE_NAME.NA_PMR],
  [TEAM_NAME.NA_OPP]: [ROLE_NAME.NA_LO, ROLE_NAME.NA_MO, ROLE_NAME.NA_LOR],
  [TEAM_NAME.PDA_GOV]: [ROLE_NAME.PDA_PM, ROLE_NAME.PDA_MG, ROLE_NAME.PDA_PMR],
  [TEAM_NAME.PDA_OPP]: [ROLE_NAME.PDA_LO, ROLE_NAME.PDA_MO, ROLE_NAME.PDA_LOR],
  [TEAM_NAME.WSC_GOV]: [ROLE_NAME.WSC_PM, ROLE_NAME.WSC_MG, ROLE_NAME.WSC_PMR],
  [TEAM_NAME.WSC_OPP]: [ROLE_NAME.WSC_LO, ROLE_NAME.WSC_MO, ROLE_NAME.WSC_LOR],
  [TEAM_NAME.BP_OG]: [ROLE_NAME.BP_PM, ROLE_NAME.BP_DPM],
  [TEAM_NAME.BP_OO]: [ROLE_NAME.BP_LO, ROLE_NAME.BP_DLO],
  [TEAM_NAME.BP_CG]: [ROLE_NAME.BP_MG, ROLE_NAME.BP_GW],
  [TEAM_NAME.BP_CO]: [ROLE_NAME.BP_MO, ROLE_NAME.BP_OW],
  [TEAM_NAME.PHS_PROPOSITION]: [ROLE_NAME.PHS_PM, ROLE_NAME.PHS_DPM, ROLE_NAME.PHS_MG, ROLE_NAME.PHS_GW],
  [TEAM_NAME.PHS_OPPOSITION]: [ROLE_NAME.PHS_LO, ROLE_NAME.PHS_DLO, ROLE_NAME.PHS_MO, ROLE_NAME.PHS_OW],
};

export const TEAM_NAME_SHOWNAME_MAPPING = {
  [TEAM_NAME.NA_GOV]: 'Government',
  [TEAM_NAME.NA_OPP]: 'Opposition',
  [TEAM_NAME.PDA_GOV]: 'Government',
  [TEAM_NAME.PDA_OPP]: 'Opposition',
  [TEAM_NAME.WSC_GOV]: 'Affirmative',
  [TEAM_NAME.WSC_OPP]: 'Negative',
  [TEAM_NAME.ASIAN_PROPOSITION]: 'Propposition',
  [TEAM_NAME.ASIAN_OPPOSITION]: 'Opposition',
  [TEAM_NAME.WSDC_PROPOSITION]: 'Propposition',
  [TEAM_NAME.WSDC_OPPOSITION]: 'Opposition',
  [TEAM_NAME.WSDC_SHORT_PROPOSITION]: 'Propposition',
  [TEAM_NAME.WSDC_SHORT_OPPOSITION]: 'Opposition',
  [TEAM_NAME.PHS_PROPOSITION]: 'Propposition',
  [TEAM_NAME.PHS_OPPOSITION]: 'Opposition',
  [TEAM_NAME.BP_OG]: 'OG',
  [TEAM_NAME.BP_OO]: 'OO',
  [TEAM_NAME.BP_CG]: 'CG',
  [TEAM_NAME.BP_CO]: 'CO',
  [CHAT_TEAM.ALL]: 'All',
  [ROOM_NAME_BASE.PREP_AUDIENCE]: 'Audince',
  [ROOM_NAME_BASE.REFLEC_JUDGE]: 'Judge',
  [ROOM_NAME_BASE.AUDITRIUM]: 'Auditrium',
  [ROOM_NAME_BASE.PRESENTATION]: 'Presentation',
};

export const TEAM_NAME_SHOWNAME_MAPPING_SHORT = {
  [TEAM_NAME.NA_GOV]: 'Gov',
  [TEAM_NAME.NA_OPP]: 'Opp',
  [TEAM_NAME.PDA_GOV]: 'Gov',
  [TEAM_NAME.PDA_OPP]: 'Opp',
  [TEAM_NAME.WSC_GOV]: 'Aff',
  [TEAM_NAME.WSC_OPP]: 'Neg',
  [TEAM_NAME.ASIAN_PROPOSITION]: 'Prop',
  [TEAM_NAME.ASIAN_OPPOSITION]: 'Opp',
  [TEAM_NAME.WSDC_PROPOSITION]: 'Prop',
  [TEAM_NAME.WSDC_OPPOSITION]: 'Opp',
  [TEAM_NAME.WSDC_SHORT_PROPOSITION]: 'Prop',
  [TEAM_NAME.WSDC_SHORT_OPPOSITION]: 'Opp',
  [TEAM_NAME.BP_OG]: 'OG',
  [TEAM_NAME.BP_OO]: 'OO',
  [TEAM_NAME.BP_CG]: 'CG',
  [TEAM_NAME.BP_CO]: 'CO',
  [TEAM_NAME.PHS_PROPOSITION]: 'Prop',
  [TEAM_NAME.PHS_OPPOSITION]: 'Opp',
  [CHAT_TEAM.ALL]: 'All',
};

export const STYLE_TEAM_MAPPING = {
  [DEBATE_STYLE.NA]: [TEAM_NAME.NA_GOV, TEAM_NAME.NA_OPP],
  [DEBATE_STYLE.PDA]: [TEAM_NAME.PDA_GOV, TEAM_NAME.PDA_OPP],
  [DEBATE_STYLE.WSC]: [TEAM_NAME.WSC_GOV, TEAM_NAME.WSC_OPP],
  [DEBATE_STYLE.ASIAN]: [TEAM_NAME.ASIAN_PROPOSITION, TEAM_NAME.ASIAN_OPPOSITION],
  [DEBATE_STYLE.WSDC]: [TEAM_NAME.WSDC_PROPOSITION, TEAM_NAME.WSDC_OPPOSITION],
  [DEBATE_STYLE.WSDC_SHORT]: [TEAM_NAME.WSDC_SHORT_PROPOSITION, TEAM_NAME.WSDC_SHORT_OPPOSITION],
  [DEBATE_STYLE.BP]: [TEAM_NAME.BP_OG, TEAM_NAME.BP_OO, TEAM_NAME.BP_CG, TEAM_NAME.BP_CO],
  [DEBATE_STYLE.PHS]: [TEAM_NAME.PHS_PROPOSITION, TEAM_NAME.PHS_OPPOSITION],
};

export const TEAM_STYLE_MAPPING = {
  [TEAM_NAME.ASIAN_PROPOSITION]: DEBATE_STYLE.ASIAN,
  [TEAM_NAME.ASIAN_OPPOSITION]: DEBATE_STYLE.ASIAN,
  [TEAM_NAME.WSDC_PROPOSITION]: DEBATE_STYLE.WSDC,
  [TEAM_NAME.WSDC_OPPOSITION]: DEBATE_STYLE.WSDC,
  [TEAM_NAME.WSDC_SHORT_PROPOSITION]: DEBATE_STYLE.WSDC_SHORT,
  [TEAM_NAME.WSDC_SHORT_OPPOSITION]: DEBATE_STYLE.WSDC_SHORT,
  [TEAM_NAME.NA_GOV]: DEBATE_STYLE.NA,
  [TEAM_NAME.NA_OPP]: DEBATE_STYLE.NA,
  [TEAM_NAME.PDA_GOV]: DEBATE_STYLE.PDA,
  [TEAM_NAME.PDA_OPP]: DEBATE_STYLE.PDA,
  [TEAM_NAME.WSC_GOV]: DEBATE_STYLE.WSC,
  [TEAM_NAME.WSC_OPP]: DEBATE_STYLE.WSC,
  [TEAM_NAME.BP_OG]: DEBATE_STYLE.BP,
  [TEAM_NAME.BP_OO]: DEBATE_STYLE.BP,
  [TEAM_NAME.BP_CG]: DEBATE_STYLE.BP,
  [TEAM_NAME.BP_CO]: DEBATE_STYLE.BP,
  [TEAM_NAME.PHS_PROPOSITION]: DEBATE_STYLE.PHS,
  [TEAM_NAME.PHS_OPPOSITION]: DEBATE_STYLE.PHS,
};

export const TEAM_PLAYERNUM_FULL = {
  [DEBATE_STYLE.NA]: 2,
  [DEBATE_STYLE.ASIAN]: 3,
  [DEBATE_STYLE.BP]: 2,
  [DEBATE_STYLE.PDA]: 2,
  [DEBATE_STYLE.WSC]: 2,
  [DEBATE_STYLE.WSDC]: 5,
  [DEBATE_STYLE.WSDC_SHORT]: 5,
  [DEBATE_STYLE.PHS]: 4,
};
// team number validation
export const TEAM_PLAYERNUM_MINUMUM = {
  [DEBATE_STYLE.NA]: 1,
  [DEBATE_STYLE.ASIAN]: 1,
  [DEBATE_STYLE.BP]: 1,
  [DEBATE_STYLE.PDA]: 1,
  [DEBATE_STYLE.WSC]: 1,
  [DEBATE_STYLE.WSDC]: 1,
  [DEBATE_STYLE.PHS]: 1,
  [DEBATE_STYLE.WSDC_SHORT]: 1,
};

export const TEAM_SIDE_MAPPING = {
  [TEAM_NAME.ASIAN_PROPOSITION]: SIDE.LEFT,
  [TEAM_NAME.ASIAN_OPPOSITION]: SIDE.RIGHT,
  [TEAM_NAME.WSDC_PROPOSITION]: SIDE.LEFT,
  [TEAM_NAME.WSDC_OPPOSITION]: SIDE.RIGHT,
  [TEAM_NAME.WSDC_SHORT_PROPOSITION]: SIDE.LEFT,
  [TEAM_NAME.WSDC_SHORT_OPPOSITION]: SIDE.RIGHT,
  [TEAM_NAME.NA_GOV]: SIDE.LEFT,
  [TEAM_NAME.NA_OPP]: SIDE.RIGHT,
  [TEAM_NAME.PDA_GOV]: SIDE.LEFT,
  [TEAM_NAME.PDA_OPP]: SIDE.RIGHT,
  [TEAM_NAME.WSC_GOV]: SIDE.LEFT,
  [TEAM_NAME.WSC_OPP]: SIDE.RIGHT,
  [TEAM_NAME.BP_OG]: SIDE.LEFT,
  [TEAM_NAME.BP_OO]: SIDE.RIGHT,
  [TEAM_NAME.BP_CG]: SIDE.LEFT,
  [TEAM_NAME.BP_CO]: SIDE.RIGHT,
  [TEAM_NAME.PHS_PROPOSITION]: SIDE.LEFT,
  [TEAM_NAME.PHS_OPPOSITION]: SIDE.RIGHT,
  [ROOM_NAME_BASE.PREP_AUDIENCE]: SIDE.MIDDLE,
  [ROOM_NAME_BASE.REFLEC_JUDGE]: SIDE.MIDDLE,
  [ROOM_NAME_BASE.AUDITRIUM]: SIDE.MIDDLE,
  [ROOM_NAME_BASE.PRESENTATION]: SIDE.MIDDLE,
};
