import { Pipe, PipeTransform } from '@angular/core';

import { ROLE_LONG_SHOWNAME_MAPPING_ALL, ROLE_NAME } from '@mixidea-client/model';

@Pipe({
  name: 'roleLongshowname',
  pure: true,
})
export class RoleLongshownamePipe implements PipeTransform {
  transform(value: ROLE_NAME, args?: any): string {
    if (ROLE_LONG_SHOWNAME_MAPPING_ALL[value]) {
      return ROLE_LONG_SHOWNAME_MAPPING_ALL[value];
    }
    return '';
  }
}
