export const STATUS_INTRO = 'intro';
export const STATUS_PREP = 'preparation';
export const STATUS_DEBATE = 'debate';
export const STATUS_REFLECTION = 'reflection';
export const STATUS_TRANSITION = 'transition';

export const DEBATE_STATUS_WAITING = 'waiting';
export const DEBATE_STATUS_SPEECH_MAIN_SPEAKER = 'main_speaer';
export const DEBATE_STATUS_SPEECH_POI = 'poi_speech';
export const DEBATE_STATUS_LAUNCHING_SCREEN = 'launching_screen';

export enum DEBATE_STATUS {
  WAITING = 'waiting',
  SPEECH_MAIN_SPEAKER = 'main_speaer',
  SPEECH_POI = 'poi_speech',
  LAUNCHING_SCREEN = 'launching_screen',
}

export const SKYWAY_STATUS_NOPEER = 'SKYWAY_STATUS_NOPEER';
export const SKYWAY_STATUS_UNDER_PEERINIT = 'SKYWAY_STATUS_UNDER_PEERINIT';
export const SKYWAY_STATUS_PEERSET = 'SKYWAY_STATUS_PEERSET';
export const SKYWAY_STATUS_UNDER_ROOMINIT = 'SKYWAY_STATUS_UNDER_ROOMINIT';
export const SKYWAY_STATUS_IN_ROOM = 'SKYWAY_STATUS_IN_ROOM';
