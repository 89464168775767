import { Injectable, ErrorHandler } from '@angular/core';

import * as stackTrace from 'stack-trace';
// http://kitak.hatenablog.jp/entry/2014/10/08/093056

import { LoggerService, LogParam, LOG_LEVEL, LOG_TECH, LOG_MODULE, LOG_TYPE } from '@mixidea-client/logger';

// https://medium.com/@amcdnl/global-error-handling-with-angular2-6b992bdfb59c

@Injectable()
export class GlobalErrorHandlerService implements ErrorHandler {
  constructor(private logger: LoggerService) {}

  handleError(error) {
    const trace = JSON.stringify(stackTrace.parse(error));
    const message = error.message;

    const log_obj: LogParam = {
      message: error.message,
      trace: trace,
      level: LOG_LEVEL.ERROR,
      tech: LOG_TECH.Global,
      type: LOG_TYPE.ALL,
      file_name: 'global-error-handler',
      module: LOG_MODULE.Core,
    };
    this.logger.write(log_obj);

    console.error(log_obj);

    return;
  }
}
