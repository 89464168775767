<footer class="Footer">
  <div class="Footer__inner">
    <div class="Wrapper">
      <ul class="LinkList">
        <li class="LinkList__item">
          <a (click)="goto_study()" href="javascript:void(0)" class="LinkList__itemAnchor">学習サービス</a>
        </li>
        <li class="LinkList__item">
          <a href="https://www.facebook.com/groups/1147230135617740/" class="LinkList__itemAnchor"
            ><i aria-hidden="true" class="LinkList__itemIcon fab fa-facebook-f"></i>Facebook Community</a
          >
        </li>
        <li class="LinkList__item">
          <a href="https://twitter.com/MixideaD" class="LinkList__itemAnchor"
            ><i aria-hidden="true" class="LinkList__itemIcon fab fa-twitter"></i>Twitter</a
          >
        </li>
        <li class="LinkList__item">
          <a (click)="goto_school_overview()" href="javascript:void(0)" class="LinkList__itemAnchor">学校案内</a>
        </li>
        <li class="LinkList__item">
          <a href="https://www.tabbr.co/" class="LinkList__itemAnchor"><i aria-hidden="true"></i>Tabbr</a>
        </li>
        <li class="LinkList__item">
          <a href="{{ privacy_policy_url }}" class="LinkList__itemAnchor"><i aria-hidden="true"></i>privacy policy</a>
        </li>
        <ng-container *ngIf="own_user.loggedIn">
          <li class="LinkList__item"><a (click)="logout()" href="javascript:void(0)" class="LinkList__itemAnchor">Logout</a></li>
        </ng-container>
      </ul>

      <div class="Copyright">
        <img class="Copyright__logo" src="https://storage.googleapis.com/mixidea_resource/m-logotype2.svg" alt="" />
        <div class="Copyright__body">Copyright © 2016 Mixidea Inc.</div>
      </div>
    </div>
  </div>
</footer>
