import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// import { UserLinkTestComponent } from './shared/user-link-test/user-link-test.component';

import { SelectivePreloadStrategyService } from './core/service/selective-preload-strategy.service';

export const appRoutes: Routes = [
  {
    path: 'livevideo-debate',
    loadChildren: () => import('./livevideo-debate/livevideo-debate.module').then((m) => m.LiveVideoDebateModule),
    data: { preload: true },
  },
  {
    path: 'livevideo-debate-audio',
    loadChildren: () =>
      import('./article2/livevideo-debate-audio-article/livevideo-debate-audio-article.module').then(
        (m) => m.LivevideoDebateAudioArticleModule,
      ),
  },
  {
    path: 'livevideo-debate-audio-serverrecognition',
    loadChildren: () =>
      import('./article2/livevideo-debate-audioarticle-serverrecognition/livevideo-debate-audioarticle-serverrecognition.module').then(
        (m) => m.LivevideoDebateAudioarticleServerrecognitionModule,
      ),
  },
  {
    path: 'livevideo-debate-audio-serverrecog-2',
    loadChildren: () =>
      import('./article2/live-debate-audioarticle-serverrecog-2/live-debate-audioarticle-serverrecog-2.module').then(
        (m) => m.LiveDebateAudioarticleServerrecog2Module,
      ),
  },
  {
    path: 'livevideo-debate-audio-headlesschrome',
    loadChildren: () =>
      import('./article2/live-debate-audioarticle-headlesschrome/live-debate-audioarticle-headlesschrome.module').then(
        (m) => m.LiveDebateAudioarticleHeadlesschromeModule,
      ),
  },
  {
    path: 'livevideo-debate-prepdoc',
    loadChildren: () =>
      import('./article2/livevideo-debate-prepdoc-article/livevideo-debate-prepdoc-article.module').then(
        (m) => m.LivevideoDebatePrepdocArticleModule,
      ),
  },
  {
    path: 'written-debate-article',
    loadChildren: () => import('./article2/writtendebate-article/writtendebate-article.module').then((m) => m.WrittendebateArticleModule),
    //   loadChildren: "./article2/writtendebate-article/writtendebate-article.module"
  },
  {
    path: 'writtendebate-article2',
    loadChildren: () =>
      import('./article2/writtendebate-article2/writtendebate-article2.module').then((m) => m.WrittendebateArticle2Module),
    //   loadChildren: "./article2/writtendebate-article/writtendebate-article.module"
  },
  {
    path: 'complete-event',
    loadChildren: () => import('./article2/complete-event/complete-event.module').then((m) => m.CompleteEventModule),
  },
  {
    path: 'event',
    loadChildren: () => import('./event/event.module').then((m) => m.EventModule),
  },
  {
    path: 'test_grouptournament',
    loadChildren: () =>
      import('./group-tournament/test-gtouptournament-module/test-gtouptournament-module.module').then(
        (m) => m.TestGtouptournamentModuleModule,
      ),
  },
  {
    path: 'article',
    loadChildren: () => import('./article/article.module').then((m) => m.ArticleModule),
  },
  {
    path: 'grouptournament/create-update',
    loadChildren: () =>
      import('./group-tournament/createupdate-tournamentgroup/createupdate-tournamentgroup.module').then(
        (m) => m.CreateupdateTournamentgroupModule,
      ),
  },
  {
    path: 'group/:id',
    loadChildren: () => import('./group-tournament/group/group.module').then((m) => m.GroupModule),
  },
  {
    path: 'grouplist',
    loadChildren: () => import('./group-tournament/group-list/group-list.module').then((m) => m.GroupListModule),
  },
  // {
  //   path: 'dashboard',
  //   loadChildren: './dashboard/dashboard.module#DashboardModule',
  // },
  {
    path: 'createupdate_event',
    loadChildren: () => import('./event2/createupdate-event/createupdate-event.module').then((m) => m.CreateupdateEventModule),
  },
  {
    path: 'announcement',
    loadChildren: () => import('./announcement/announcement.module').then((m) => m.AnnouncementModule),
  },
  {
    path: 'mail-login',
    loadChildren: () => import('./mail-login/mail-login.module').then((m) => m.MailLoginModule),
  },
  {
    path: 'lecture',
    loadChildren: () => import('./lecture/lecture.module').then((m) => m.LectureModule),
  },
  // {
  //   path: 'user_link_test',
  //   component: UserLinkTestComponent
  // },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule),
  },
  {
    path: 'line',
    loadChildren: () => import('./line/line.module').then((m) => m.LineModule),
  },
  {
    path: 'announcement',
    loadChildren: () => import('./announcement/announcement.module').then((m) => m.AnnouncementModule),
  },
  {
    path: 'study',
    loadChildren: () => import('./study/study.module').then((m) => m.StudyModule),
  },
  {
    path: 'payment',
    loadChildren: () => import('./payment/payment.module').then((m) => m.PaymentModule),
  },
  {
    path: 'jisti',
    loadChildren: () => import('./jisti/jisti.module').then((m) => m.JistiModule),
  },
  {
    path: '',
    loadChildren: () => import('./home/home/home.module').then((m) => m.HomeModule),
  },
  {
    path: '**',
    redirectTo: '/top?openExternalBrowser=1',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { preloadingStrategy: SelectivePreloadStrategyService })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
