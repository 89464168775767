import { DEBATE_STYLE } from './deb-style';

export const PREPARATION_DURATION_EACHSTYLE = {
  [DEBATE_STYLE.NA]: 20,
  [DEBATE_STYLE.ASIAN]: 25,
  [DEBATE_STYLE.BP]: 15,
  [DEBATE_STYLE.PDA]: 15,
  [DEBATE_STYLE.WSC]: 20,
  [DEBATE_STYLE.WSDC]: 30,
  [DEBATE_STYLE.WSDC_SHORT]: 30,
  [DEBATE_STYLE.PHS]: 30,
};
