import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';

import { OwnUser } from '@mixidea-client/model';
import { UserauthService } from './../../../core/service/userauth.service';

@Component({
  selector: 'app-user-show-own-icon',
  templateUrl: './user-show-own-icon.component.html',
  styleUrls: ['./user-show-own-icon.component.scss'],
})
export class UserShowOwnIconComponent implements OnInit {
  own_user$: Observable<OwnUser>;

  @Input()
  width;
  @Input()
  height;

  constructor(private user_auth: UserauthService) {}

  ngOnInit() {
    this.own_user$ = this.user_auth.get_own_user$();
  }
}
