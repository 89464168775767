import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit, ChangeDetectorRef, NgZone } from '@angular/core';
import { UserauthService } from './../../core/service/userauth.service';
// import { CloudMessagingService } from './../../core/service/cloud-messaging.service';
import { Router, NavigationExtras } from '@angular/router';

import { OwnUser, EMPTY_OWN_USER, LS_KEY_LOGGEDIN, getBlankNavigationExtra } from '@mixidea-client/model';

// import { NotificationWebService } from './../../core/service/notification-web.service';

import {
  NOTIFICATION_MESSAGE_TYPE_messagelist,
  NOTIFICATION_MESSAGE_TYPE_notificationlist,
  NOTIFICATION_MESSAGE_TYPE_message,
} from './../../interface/notification-message';

// import { NotificationMessageModalComponent } from '../notification-message-modal/notification-message-modal.component';

// import { NotificationMessageModalComponent } from './../notification-message-modal/notification-message-modal.component';

import { LoginModalComponent, MODAL_SIZE } from './../../shared/login-modal/login-modal.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy, AfterViewInit {
  notification_arr = [];
  message_arr = [];
  own_user_subscription;
  show_login_delay;

  initialized = false;

  own_user: OwnUser = EMPTY_OWN_USER;

  // @ViewChild(NotificationMessageModalComponent) private notification_message_modal: NotificationMessageModalComponent;

  constructor(
    private user_auth: UserauthService,
    public dialog: MatDialog,
    private change_ref: ChangeDetectorRef,
    private router: Router,
    private ngZone: NgZone,
  ) {}

  ngOnInit() {
    // console.log('header component initialized');
    this.own_user_subscription = this.user_auth
      .get_own_user$()
      // .pipe(
      //   switchMap((user_data: OwnUser)=>{
      //     if(user_data && user_data.id){
      //       return of(user_data);
      //     }else{
      //       return of(EMPTY_OWN_USER)
      //       .pipe(
      //         delay(5000),
      //         filter(() => {
      //           if( this.own_user && this.own_user.id ){
      //             return false;
      //           }else{
      //             return true
      //           }
      //         })
      //       )
      //     }
      //   }),
      // )
      .subscribe((user_data: OwnUser) => {
        this.own_user = user_data;
        this.initialized = true;
        this.change_ref.detectChanges();
      });

    // this makes unnnecessary delay if user try to login but do not execute
    const loggedin = localStorage.getItem(LS_KEY_LOGGEDIN);
    if (loggedin) {
      this.show_login_delay = true;
      setTimeout(() => {
        this.show_login_delay = false;
        this.change_ref.detectChanges();
      }, 2500);
    }
  }
  ngAfterViewInit() {
    // this.notification.start_notify_message_subscription();
  }

  show_login_modal() {
    this.dialog.open(LoginModalComponent, { width: MODAL_SIZE });
  }

  goto_dashboard() {
    this.ngZone.run(() => {
      const navigationExtra2 = getBlankNavigationExtra();
      this.router.navigate(['/dashboard/'], navigationExtra2);
    });
  }

  goto_eventlist() {
    this.ngZone.run(() => {
      const navigationExtra2 = getBlankNavigationExtra();
      this.router.navigate(['/event/eventlist'], navigationExtra2);
    });
  }

  goto_grouplist() {
    this.ngZone.run(() => {
      const navigationExtra2 = getBlankNavigationExtra();
      this.router.navigate(['/grouplist'], navigationExtra2);
    });
  }

  // goto_video(){
  //   this.ngZone.run(() => {
  //     const navigationExtra2 = getBlankNavigationExtra();
  //     this.router.navigate(['/study/top'], navigationExtra2);
  //   });

  // }

  set_profile_picture() {
    this.ngZone.run(() => {
      const navigationExtra2 = getBlankNavigationExtra();
      this.router.navigate(['/mail-login/signin'], navigationExtra2);
    });
  }

  goto_home() {
    this.ngZone.run(() => {
      const navigationExtra2 = getBlankNavigationExtra();
      this.router.navigate(['/'], navigationExtra2);
    });
  }

  goto_school() {
    this.ngZone.run(() => {
      const navigationExtra2 = getBlankNavigationExtra();
      this.router.navigate(['/lecture/school-list'], navigationExtra2);
    });
  }

  // show_notification_list() {
  //   if (!this.user_auth.is_loggedin()) {
  //     alert('you need to login to see notifications');
  //     this.dialog.open(LoginModalComponent, { width: MODAL_SIZE });
  //     return;
  //   }

  //   console.log('show_notification_list');
  //   // this.notification_message_modal.open_notificationlist();
  //   this.dialog.open(NotificationMessageModalComponent, { data: NOTIFICATION_MESSAGE_TYPE_notificationlist });
  // }

  // show_message_list() {
  //   if (!this.user_auth.is_loggedin()) {
  //     alert('you need to login to see messages');
  //     this.dialog.open(LoginModalComponent, { width: MODAL_SIZE });
  //     return;
  //   }
  //   console.log('show_message_list');
  //   // this.notification_message_modal.open_messagelist();
  //   this.dialog.open(NotificationMessageModalComponent, { data: NOTIFICATION_MESSAGE_TYPE_messagelist });
  // }

  // show_message(event_id) {
  //   if (!this.user_auth.is_loggedin()) {
  //     alert('you need to login to see message');
  //     this.dialog.open(LoginModalComponent, { width: MODAL_SIZE });
  //     return;
  //   }
  //   // this.notification_message_modal.open_message(event_id);
  //   this.dialog.open(NotificationMessageModalComponent, { data: NOTIFICATION_MESSAGE_TYPE_message });
  // }

  // goto_dashboard(){
  //   this.ngZone.run(() => {
  //     this.router.navigate(['/dashboard']);
  //   });

  // }

  ngOnDestroy() {
    if (this.own_user_subscription) {
      this.own_user_subscription.unsubscribe();
    }
  }
}
