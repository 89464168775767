import { Participate } from '../constant/participate';
import { SEARCH_STATUS } from './search-users';

import { environment } from './../../../../web/src/environments/environment';

export interface OwnUser {
  id?: string;
  fb_id?: string;
  loggedIn?: boolean;
  full_name?: string;
  short_name?: string;
  pict_src?: string;
  cover_src?: string;
}

export const EMPTY_OWN_USER: OwnUser = {
  id: null,
  fb_id: null,
  loggedIn: false,
  full_name: '',
  short_name: '',
  pict_src: '',
  cover_src: '',
};

export interface UserBasicDb {
  full_name: string;
  short_name: string;
  fb_id?: string;
  twitter_id?: string;
  twitter_name?: string;
  is_email_account?: boolean;
  pict_src?: string;
}

export interface UserBasicVm {
  full_name?: string;
  short_name?: string;
  pict_src?: string;
  fb_id?: string;
  twitter_id?: string;
  twitter_name?: string;
}

// this const should move to const file after eliminating the cyclic dependency by moving enum to enum module
export const BLANK_USER_DATA: UserBasicVm = {
  full_name: 'name',
  short_name: 'name',
  pict_src: 'https://storage.googleapis.com/mixidea_resource/blank_user2.jpg',
};

export const BLANK_USER_DATA_RECORDER: UserBasicVm = {
  full_name: 'recording',
  short_name: 'recording',
  pict_src: 'https://storage.googleapis.com/mixidea_resource/red-circle.png',
};

export function convert_user_DbToVm(user_db: UserBasicDb, user_id: string): UserBasicVm {
  if (user_db && user_db.pict_src) {
    return Object.assign({}, user_db, { pict_src: user_db.pict_src });
  } else if (user_db && user_db.fb_id) {
    return Object.assign({}, user_db, { pict_src: `https://graph.facebook.com/${user_db.fb_id}/picture?height=100&amp;width=100` });
  } else if (user_db && user_db.twitter_id) {
    return Object.assign({}, user_db, { pict_src: `${environment.cloudfunction_base_url}/twitter_img_redirect?user_id=${user_id}` });
  } else if (user_db && user_db.is_email_account) {
    return Object.assign({}, user_db, { pict_src: user_db.pict_src || '' });
  } else if (user_db) {
    return Object.assign({}, user_db, { pict_src: '' });
  } else {
    return {};
  }
}

export interface UserFbToken {
  full_name: string;
  token: string;
  provider_uid: string;
  token_updated: Date;
}

export interface UserTwitterToken {
  full_name: string;
  token: string;
  secret: string;
  provider_uid: string;
  token_updated: Date;
}

export interface UserProfile {
  cover_src?: string;
  cover?: {
    id?: string;
    offset_y?: number;
    source?: string;
  };
  first_name?: string;
  gender?: string;
  id?: string;
  last_name?: string;
  link?: string;
  locale?: string;
  name?: string;
  picture?: {
    data: {
      url?: string;
    };
  };
  timezone?: number;
  privilege?: boolean;
}

export interface UserSearch {
  full_name?: string;
  fb_id?: string;
  twitter_id?: string;
  twitter_name?: string;
  short_name?: string;
  fullname_lowercase?: string;
  first_name_lowercase?: string;
  middle_name_lowercase?: string;
  last_name_lowercase?: string;
}

export interface UserSearchWithId extends UserSearch {
  id?: string;
}

export interface UserSearchElastic {
  userid?: string;
  full_name: string;
  fullname_lowercase?: string;
  fullname_lowercase_local?: string;
  last_name_lowercase?: string;
  last_name_lowercase_local?: string;
  first_name_lowercase?: string;
  first_name_lowercase_local?: string;
  twitter_name?: string;
  twitter_name_lowercase?: string;
  fb_id?: string;
  twitter_id?: string;
  source?: string;
}

export interface UserSearchWithIdVm extends UserSearchWithId {
  status?: Participate | SEARCH_STATUS;
  message?: string;
  pict_src?: string;
  user_id?: string;
}

export const LS_KEY_USER_NAME_SET = 'LS_KEY_USER_NAME_SET';
export const SESSION_KEY_CREATE_USER_PREVIOUS_PAGE = 'SESSION_KEY_CREATE_USER_PREVIOUS_PAGE';
export const LS_KEY_LOGGEDIN = 'LS_KEY_LOGGEDIN';

export interface UserNameInputData {
  own_userid: string;
}
