import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DomSanitizer } from '@angular/platform-browser';

export interface ManualData {
  width: number;
  title: string;
  google_slide_url: string;
}

@Component({
  selector: 'app-manual-modal',
  templateUrl: './manual-modal.component.html',
  styleUrls: ['./manual-modal.component.scss'],
})
export class ManualModalComponent implements OnInit {
  width_num = null;
  title = '';
  google_slide_url;

  constructor(
    public dialogRef: MatDialogRef<ManualModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ManualData,
    public sanitizer: DomSanitizer,
  ) {
    this.width_num = data.width - 8;
    this.title = data.title;
    this.google_slide_url = this.sanitizer.bypassSecurityTrustResourceUrl(data.google_slide_url);
  }

  ngOnInit() {}

  get width() {
    return String(this.width_num);
  }

  get height() {
    return String((this.width_num * 9) / 16);
  }

  hide_login_modal() {
    this.dialogRef.close();
  }
}
