// export const USER_VIEW_MAIN_SPEAKER = 'USER_VIEW_MAIN_SPEAKER';
// export const USER_VIEW_POI_SPEAKER = 'USER_VIEW_POI_SPEAKER';
// export const USER_VIEW_POI_WAITING_MAIN_SPEAKER = 'USER_VIEW_POI_WAITING_MAIN_SPEAKER';
// export const USER_VIEW_INTRO = 'USER_VIEW_INTRO';
// export const USER_VIEW_PREP = 'USER_VIEW_PREP';

export enum USER_VIEW {
  MAIN_SPEAKER = 'USER_VIEW_MAIN_SPEAKER',
  POI_SPEAKER = 'USER_VIEW_POI_SPEAKER',
  POI_WAITING_MAIN_SPEAKER = 'USER_VIEW_POI_WAITING_MAIN_SPEAKER ',
  VIEW_INTRO = 'USER_VIEW_INTRO ',
  VIEW_PREP = 'USER_VIEW_PREP ',
}
