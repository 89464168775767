import { Component, OnInit, Input } from '@angular/core';

import { UserShowBaseComponent } from '../user-show-base/user-show-base.component';

import { USERSHOW_NAME_TYPE } from '@mixidea-client/model';

@Component({
  selector: 'app-user-show-name',
  templateUrl: './user-show-name.component.html',
  styleUrls: ['./user-show-name.component.scss'],
})
export class UserShowNameComponent extends UserShowBaseComponent {
  USERSHOW_NAME_TYPE = USERSHOW_NAME_TYPE;

  @Input()
  name_type: USERSHOW_NAME_TYPE;
}
