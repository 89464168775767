import { NavigationExtras } from '@angular/router';
import { LAYOUT_TYPE } from '../constant/layout';
import { TEAM_NAME } from '../constant/team-enum';

export type QueryKey =
  | 'prep_room'
  | 'judge_room'
  | 'layout_type'
  | 'room'
  | 'receiveonly_current'
  | 'receiveonly_previous_auditorium'
  | 'use_preparation_ready_status'
  | 'openExternalBrowser';

const QueryKeyArr: QueryKey[] = [
  'prep_room',
  'judge_room',
  'layout_type',
  'room',
  'receiveonly_current',
  'receiveonly_previous_auditorium',
  'use_preparation_ready_status',
  'openExternalBrowser',
];

interface QueryKeyValue {
  prep_room?: string;
  judge_room?: string;
  layout_type?: string;
  room?: string;
  receiveonly_current?: string;
  receiveonly_previous_auditorium?: string;
  use_preparation_ready_status?: string;
  router_id?: string;
  user_id?: string;
  user_name?: string;
  openExternalBrowser?: string;
}

export interface QuerySource {
  use_preparation_ready_status?: boolean;
  receiveonly_current?: boolean;
  receiveonly_previous_auditorium?: boolean;
  room_num_in_auditrium?: number;
  jisti_room_num_in_auditrium?: number;
  user_id?: string;
  user_name?: string;
  router_id?: string;
}

function addQueryParam(query_keyvalue: QueryKeyValue): NavigationExtras {
  const current_query = getAllCurrentQueryParamFromUrl();
  const next_query = Object.assign(current_query, query_keyvalue);

  const navigationExtras: NavigationExtras = {
    queryParams: next_query,
  };
  return navigationExtras;
}

function addQueryParamObject(query_keyvalue: QueryKeyValue): QueryKeyValue {
  const current_query = getAllCurrentQueryParamFromUrl();
  const next_query = Object.assign(current_query, query_keyvalue);
  return next_query;
}

function removeQueryParam(key: QueryKey): NavigationExtras {
  const current_query = getAllCurrentQueryParamFromUrl();
  delete current_query[key];

  const navigationExtras: NavigationExtras = {
    queryParams: current_query,
  };
  return navigationExtras;
}

function removeQueryParamObject(key: QueryKey): QueryKeyValue {
  const current_query: QueryKeyValue = getAllCurrentQueryParamFromUrl();
  delete current_query[key];
  return current_query;
}

function getAllCurrentQueryParamFromUrl() {
  const query_str = location.search;
  const query_str_removed = query_str.replace('?', '');
  const query_list = query_str_removed.split('&');
  let query_obj = {};

  query_list.forEach((str) => {
    const query_split = str.split('=');
    if (query_split && query_split[1] && QueryKeyArr.indexOf(query_split[0] as QueryKey) !== -1) {
      query_obj[query_split[0]] = query_split[1];
    }
  });
  return query_obj;
}

export function getQueryLayoutTypeFromUrl() {
  const query_obj: QueryKeyValue = getAllCurrentQueryParamFromUrl();
  return query_obj.layout_type ? query_obj.layout_type : null;
}
function getQueryValue(key: QueryKey) {
  const query = getAllCurrentQueryParamFromUrl();
  return query[key] ? query[key] : null;
}

export function isReceiveOnly(): boolean {
  return getQueryValue('receiveonly_current') === 'true';
}

export function isPreviousAuditoriumReceiveonly(): boolean {
  return getQueryValue('receiveonly_previous_auditorium') === 'true';
}

export function existLayoutTypeInQuery(): boolean {
  return !!getQueryValue('layout_type');
}

export function getAllCurrentQueryParam(): NavigationExtras {
  const navigationExtras: NavigationExtras = {
    queryParams: getAllCurrentQueryParamFromUrl(),
  };
  return navigationExtras;
}

export function getAllCurrentAndAdjustReceiveOnlyQueryParam(receive_only_data: QuerySource): NavigationExtras {
  const current_query: QueryKeyValue = getAllCurrentQueryParamFromUrl();
  const next_query = addReceiveOnly(current_query, receive_only_data);
  const navigationExtras: NavigationExtras = {
    queryParams: next_query,
  };
  return navigationExtras;
}

export function getInitialQueryParam(
  receive_only_data: QuerySource,
  is_auditorium_default: boolean,
  is_presentation_default: boolean,
): NavigationExtras {
  let current_query: QueryKeyValue = getAllCurrentQueryParamFromUrl();

  if (!current_query.layout_type && is_auditorium_default) {
    current_query.layout_type = LAYOUT_TYPE.AUDITRIUM;
  } else if (!current_query.layout_type && is_presentation_default) {
    current_query.layout_type = LAYOUT_TYPE.PRESENTATION;
  }

  if (receive_only_data) {
    current_query = addReceiveOnly(current_query, receive_only_data);
  }
  const navigationExtras: NavigationExtras = {
    queryParams: current_query,
  };
  return addOpenExternalBrowser(navigationExtras);
}

function addReceiveOnly(current_query: QueryKeyValue, receive_only_data: QuerySource): QueryKeyValue {
  if (receive_only_data.receiveonly_current) {
    current_query.receiveonly_current = 'true';
  } else {
    delete current_query['receiveonly_current'];
  }

  if (receive_only_data.receiveonly_previous_auditorium) {
    current_query.receiveonly_previous_auditorium = 'true';
  } else {
    delete current_query['receiveonly_previous_auditorium'];
  }

  return current_query;
}

export function getPrepRoomQueryParamTeamName(team_name: TEAM_NAME): NavigationExtras {
  if (team_name) {
    return addQueryParam({ prep_room: team_name });
  }
  return getAllCurrentQueryParam();
}

export function removePrepRoomQueryParam(): NavigationExtras {
  return removeQueryParam('prep_room');
}

export function getJudgeRoomQueryParam(): NavigationExtras {
  return addQueryParam({ judge_room: 'true' });
}

export function removeJudgeRoomQueryParam(): NavigationExtras {
  return removeQueryParam('judge_room');
}

// export function getGameQueryParam(): NavigationExtras {
//   const auditrium_query: QueryKeyValue = { layout_type: LAYOUT_TYPE.GAME };

//   const navigationExtras: NavigationExtras = {
//     queryParams: auditrium_query,
//   };
//   return navigationExtras;
// }

export function getGamePath(): string {
  const query = { layout_type: LAYOUT_TYPE.GAME };
  return get_path(query);
}

// export function getAuditriumQueryParam(): NavigationExtras {
//   const auditrium_query: QueryKeyValue = { layout_type: LAYOUT_TYPE.AUDITRIUM };

//   const navigationExtras: NavigationExtras = {
//     queryParams: auditrium_query,
//   };
//   return navigationExtras;
// }

export function getAuditoriumPath(query_source: QuerySource): string {
  const auditorium_query = { layout_type: LAYOUT_TYPE.AUDITRIUM };

  const additional_query = addReceiveOnly(auditorium_query, query_source);

  return get_path(additional_query);
}

// export function getPresentationQueryParam(): NavigationExtras {
//   const auditrium_query: QueryKeyValue = { layout_type: LAYOUT_TYPE.PRESENTATION };

//   const navigationExtras: NavigationExtras = {
//     queryParams: auditrium_query,
//   };
//   return navigationExtras;
// }

export function getPresentationPath(): string {
  const query = {
    layout_type: LAYOUT_TYPE.PRESENTATION,
  };
  return get_path(query);
}

// export function getGameInAuditriumQueryParam(room_in_auditrium): NavigationExtras {
//   return addQueryParam({
//     layout_type: LAYOUT_TYPE.GAME_IN_AUDITRIUM,
//     room: room_in_auditrium,
//   });
// }

export function getGameInAuditriumPath(query_source: QuerySource): string {
  let game_in_auditorium_query: QueryKeyValue = {
    layout_type: LAYOUT_TYPE.GAME_IN_AUDITRIUM,
    room: String(query_source.room_num_in_auditrium),
  };
  if (query_source.use_preparation_ready_status) {
    game_in_auditorium_query.use_preparation_ready_status = 'true';
  }
  const query2 = addQueryParamObject(game_in_auditorium_query);
  const query3 = addReceiveOnly(query2, query_source);

  return get_path(query3);
}

export function getJistiRoomPath(query_source: QuerySource): string {
  let jisti_in_auditorium_query: QueryKeyValue = {
    layout_type: LAYOUT_TYPE.JISTI_IN_AUDITRIUM,
    room: String(query_source.jisti_room_num_in_auditrium),
    router_id: String(query_source.router_id),
    user_id: query_source.user_id,
    user_name: query_source.user_name,
  };
  if (query_source.use_preparation_ready_status) {
    jisti_in_auditorium_query.use_preparation_ready_status = 'true';
  }
  const query2 = addQueryParamObject(jisti_in_auditorium_query);
  const query3 = addReceiveOnly(query2, query_source);

  return get_path(query3);
}

// export function getPresenInAuditriumQueryParam(room_num_in_auditrium: string): NavigationExtras {
//   return addQueryParam({
//     layout_type: LAYOUT_TYPE.PRESENTATION_IN_AUDITRIUM,
//     room: room_num_in_auditrium,
//   });
// }

export function getPresenInAuditriumPath(query_source: QuerySource): string {
  const presentation_query: QueryKeyValue = {
    layout_type: LAYOUT_TYPE.PRESENTATION_IN_AUDITRIUM,
    room: String(query_source.room_num_in_auditrium),
  };
  const query2 = addQueryParamObject(presentation_query);
  const query3 = addReceiveOnly(query2, query_source);
  return get_path(query3);
}

export function getBlankNavigationExtra() {
  return addOpenExternalBrowser(null);
}

export function getBlankPath() {
  return get_path({});
}

export function addOpenExternalBrowser(data: NavigationExtras | null): NavigationExtras {
  let current_query_param: QueryKeyValue = {};
  if (data && data.queryParams) {
    current_query_param = data.queryParams;
  }
  const next_query_param = addOpenExternalBrowserQuery(current_query_param);
  const navigationExtras: NavigationExtras = {
    queryParams: next_query_param,
  };

  return navigationExtras;
}

export function addOpenExternalBrowserQuery(data: QueryKeyValue | null): QueryKeyValue {
  if (data) {
    if (data.openExternalBrowser) {
      return data;
    } else {
      data.openExternalBrowser = '1';
      return data;
    }
  } else {
    return { openExternalBrowser: '1' };
  }
}

function get_path(obj): string {
  let path = '';
  const obj2 = addOpenExternalBrowserQuery(obj);
  for (let key in obj2) {
    path = `${path}&${key}=${obj[key]}`;
  }
  return `?${path}`;
}
