import { Component, OnInit, Inject } from '@angular/core';

import { FirebaseStoragedApi } from '@mixidea-client/firebase-rtd-lib';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { generate_random_string } from '@mixidea-client/util-func';

@Component({
  selector: 'app-upload-presentation-modal',
  templateUrl: './upload-presentation-modal.component.html',
  styleUrls: ['./upload-presentation-modal.component.scss'],
})
export class UploadPresentationModalComponent implements OnInit {
  file_name: string;
  selected_file: File;
  router_id: string;
  presentation_room_in_auditorium: boolean;

  under_upload = false;
  current_file = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<UploadPresentationModalComponent>,
    public firebaseStoragedApi: FirebaseStoragedApi,
  ) {
    this.router_id = data.router_id;
    this.current_file = data.current_file;
    this.presentation_room_in_auditorium = data.presentation_room_in_auditorium;
  }

  ngOnInit() {}

  selectFile(event) {
    const file = event.target.files[0];
    this.selected_file = file;
    this.file_name = file.name;
  }

  uploadFile() {
    if (this.selected_file) {
      this.under_upload = true;

      let original_filename = this.file_name;
      let kakuchoushi = '';
      let except_kakuchoushi_filename = '';
      let length = original_filename.lastIndexOf('.');
      if (length === -1) {
        kakuchoushi = '';
        except_kakuchoushi_filename = original_filename;
      } else {
        const original_file_len = original_filename.length;
        const minus_last_len = length - original_file_len;
        kakuchoushi = original_filename.slice(minus_last_len);
        except_kakuchoushi_filename = original_filename.substr(0, length);
      }

      const hannkaku_filename = except_kakuchoushi_filename.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function (s) {
        return String.fromCharCode(s.charCodeAt(0) - 65248);
      });

      const file_name_with_alphabet = hannkaku_filename.replace(/[^0-9a-zA-Z]/g, 'x');
      const final_filename = file_name_with_alphabet + generate_random_string() + kakuchoushi;

      return this.firebaseStoragedApi
        .storeFilePresentationPDF(this.router_id, final_filename, this.selected_file, this.presentation_room_in_auditorium)
        .then((downlod_url: string) => {
          console.log('downlod_url', downlod_url);
          setTimeout(() => {
            this.under_upload = false;
            this.dialogRef.close();
          }, 1000);
        });
    }
  }
}
