export enum USER_ENV {
  ICON = 'ICON',
  SNAPSHOT = 'SNAPSHOT',
  VIDEO = 'VIDEO ',
}

/*
export const USER_ENV_ICON = 'ICON';
export const USER_ENV_SNAPSHOT = 'SNAPSHOT';
export const USER_ENV_VIDEO = 'VIDEO';
*/
