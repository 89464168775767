export enum GAME_STATUS {
  INTRO = 'intro',
  PREP = 'preparation',
  DEBATE = 'debate',
  REFLECTION = 'reflection',
  TRANSITION = 'transition',
  AUDITRIUM = 'auditrium',
  FORCE_GOBACK_AUDITRIUM = 'FORCE_GOBACK_AUDITRIUM',
  PRESENTATION = 'PRESENTATION',
}

export const GAME_STATUS_SHOWNAME_MAPPING = {
  [GAME_STATUS.INTRO]: 'Meetup',
  [GAME_STATUS.PREP]: 'Preparation',
  [GAME_STATUS.DEBATE]: 'Debate',
  [GAME_STATUS.REFLECTION]: 'Feedback',
};
