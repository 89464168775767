import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'showDayHour',
  pure: true,
})
export class ShowDayHourPipe implements PipeTransform {
  transform(time_spent_seconds: number, args?: any): any {
    if (time_spent_seconds) {
      const speech_time_hours_whole = Math.floor(time_spent_seconds / 60 / 60);
      const speech_time_hours = speech_time_hours_whole % 24;
      const speech_time_days = Math.floor(time_spent_seconds / 60 / 60 / 24);
      return String(speech_time_days) + 'days and ' + String(speech_time_hours) + 'hours';
    } else {
      return '';
    }
  }
}
