import { SIDE } from '../constant/side';

export interface PoiInfoDb {
  start_time_msec: number;
  end_time_msec: number;
  poi_speaker_userid: string;
}

export enum SPEAKER_TYPE {
  Main = 'Main',
  POI = 'POI',
}

export interface SpeechInfoVm {
  speaker_type?: SPEAKER_TYPE;
  userid?: string;
  start_time_msec?: number;
  end_time_msec?: number;
  side?: SIDE;
  transcription_info_arr?: TranscriptionInfoVm[];
}

export interface RecordspeechInfoDb {
  main_speaker_userid?: string;
  role_name?: string;
  poin_info_arr?: PoiInfoDb[];
}

export interface RecordspeechInfoDbFunctionExtend extends RecordspeechInfoDb {
  transcription?: TranscriptionInfoDb[];
  mp3_url?: string;
  id?: string;
}

export interface wordsDb {
  word?: string;
  startSecs?: Duration;
  endSecs?: Duration;
}

export interface Duration {
  seconds: { high: number; low: number };
  nanos: number;
}

export interface wordsVm {
  word?: string;
  highlight?: boolean;
  startMsec?: number;
  endMsec?: number;
}

export interface TranscriptionInfoVm {
  words?: wordsVm[];
}

export interface TranscriptionInfoDb {
  transcription?: string;
  words?: wordsDb[];
}
