import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { FirebaseRtdService } from './service/firebase-rtd.service';
import { FirebaseRtdPureService } from './service/firebase-rtd-pure.service';
import { FirebaseStorageService } from './service/firebase-storage.service';

import { USER_ENV } from '@mixidea-client/model';
import {
  EachMessageChatOrPost,
  UserMediaEnv,
  FirebaseRoomUserDb,
  FirebaseRoomUserOnlyUserDb,
  ROOM_NAME_TYPE,
  ChildroomInAuditriumRoomUserDb,
  WebrtcCondition,
  Motion,
  MoveParticipantsData,
} from '@mixidea-client/model';
import { LiveVideoBasic } from '@mixidea-client/model';
import { SpeechStatusDb } from '@mixidea-client/model';
import {
  PreparationDocument,
  PreparationDocumentAllDb,
  CommentDataDb,
  ROLE_NAME,
  CommentDataVm,
  PreviousRoundDataForRemove,
  RegisteredTeamInfo,
  RegisteredTeamData,
  TabbycatData,
} from '@mixidea-client/model';
import { RoleUser } from '@mixidea-client/model';
import { GameStatusCandidate, GAME_STATUS, GAME_STATUS_DIRECTION } from '@mixidea-client/model';
import { REACTION_TYPE, Reaction, ReactionWithKey } from '@mixidea-client/model';

import { Presentation, LAYOUT_TYPE } from '@mixidea-client/model';

import { map } from 'rxjs/operators';

@Injectable()
export class LiveDebateFirebaseService {
  constructor(
    private firebaseRtdService: FirebaseRtdService,
    private firebaseRtdPureService: FirebaseRtdPureService,
    private firebaseStorageService: FirebaseStorageService,
  ) {}

  get_room_users$(event_id: string): Observable<FirebaseRoomUserDb> {
    const path = '/event_related/room_users/' + event_id;
    return this.firebaseRtdService.getObjectValue<FirebaseRoomUserDb>(path);
  }

  get_room_users_only_one_room$(event_id: string, room_type: ROOM_NAME_TYPE): Observable<FirebaseRoomUserOnlyUserDb> {
    const path = '/event_related/room_users/' + event_id + '/' + room_type;
    return this.firebaseRtdService.getObjectValue<FirebaseRoomUserOnlyUserDb>(path);
  }

  get_childroom_in_auditrium_room_users$(event_id: string): Observable<ChildroomInAuditriumRoomUserDb> {
    const path = '/event_related/room_users_childrooms_in_auditrium/' + event_id;
    return this.firebaseRtdService.getObjectValue<ChildroomInAuditriumRoomUserDb>(path);
  }

  get_jisti_num(event_id: string): Observable<number> {
    const path = '/event_related/jisti_num_in_auditorium/' + event_id;
    return this.firebaseRtdService.getObjectValue<number>(path);
  }

  set_jisti_num(event_id: string, num: number): Promise<void> {
    const path = '/event_related/jisti_num_in_auditorium/' + event_id;
    return this.firebaseRtdService.setObject(path, num);
  }

  set_receiveonly_status(event_id: string, userid: string, receive_only: boolean): Promise<void> {
    const path = `/event_related/receiveonly_participant/${event_id}/${userid}`;
    return this.firebaseRtdService.setObject(path, receive_only);
  }

  remove_receiveonly_status(event_id: string, userid: string): Promise<void> {
    const path = `/event_related/receiveonly_participant/${event_id}/${userid}`;
    return this.firebaseRtdService.removeObject(path);
  }

  get_receiveonly_status$(event_id: string, userid: string): Observable<boolean> {
    const path = `/event_related/receiveonly_participant/${event_id}/${userid}`;
    return this.firebaseRtdService.getObjectValueOnce<boolean>(path);
  }

  get_webrtc_condition(router_id: string, layout_type: LAYOUT_TYPE): Promise<WebrtcCondition> {
    const path = `/event_related/webrtc_condition/${router_id}/${layout_type}`;
    return this.firebaseRtdService.getObjectValueOnce<WebrtcCondition>(path).toPromise();
  }

  get_room_users_in_room$(event_id: string, room_type: ROOM_NAME_TYPE, own_userid: string): Observable<boolean> {
    const path = `/event_related/room_user_recognized_by_others/${event_id}/${room_type}/${own_userid}`;
    return this.firebaseRtdService.getObjectValue<boolean>(path);
  }

  add_room_users(
    event_id: string,
    room_type: ROOM_NAME_TYPE,
    user_id: string,
    own_user_id: string,
    value: string | boolean,
  ): Promise<void> {
    const path = '/event_related/room_users/' + event_id + '/' + room_type + '/' + user_id + '/' + own_user_id;
    return this.firebaseRtdService
      .setObject(path, value)
      .then(() => {
        console.log('success to add room users');
      })
      .catch((err) => {
        console.log('fail to add users');
      });
  }

  remove_room_users(event_id: string, room_type: ROOM_NAME_TYPE, user_id: string, own_user_id: string): Promise<void> {
    const path = '/event_related/room_users/' + event_id + '/' + room_type + '/' + user_id + '/' + own_user_id;
    return this.firebaseRtdService
      .removeObject(path)
      .then(() => {
        console.log('success to remove room users');
      })
      .catch((err) => {
        console.log('fail to remove room users');
      });
  }

  remove_own_room_user(event_id: string, room_type: ROOM_NAME_TYPE, own_user_id: string): Promise<void> {
    const path = '/event_related/room_users/' + event_id + '/' + room_type + '/' + own_user_id;
    return this.firebaseRtdService
      .removeObject(path)
      .then(() => {
        console.log('success to remove own users');
      })
      .catch((err) => {
        console.log('fail to remove own users');
      });
  }

  remove_roomuser_when_disconnected(event_id: string, room_type: ROOM_NAME_TYPE, user_id: string, own_user_id: string) {
    const path = '/event_related/room_users/' + event_id + '/' + room_type + '/' + user_id + '/' + own_user_id;
    this.firebaseRtdPureService.removeWhenDisconnected(path);
  }

  remove_own_roomuser_when_disconnected(event_id: string, room_type: ROOM_NAME_TYPE, own_user_id: string) {
    const path = '/event_related/room_users/' + event_id + '/' + room_type + '/' + own_user_id;
    this.firebaseRtdPureService.removeWhenDisconnected(path);
  }

  getLivevideoDebate(event_id: string): Observable<LiveVideoBasic> {
    const path = '/event_related/livevideo-debate/' + event_id;
    return this.firebaseRtdService.getObjectValue<LiveVideoBasic>(path);
  }

  get_game_status(event_id: string): Observable<GAME_STATUS> {
    const path = `/event_related/livevideo-debate/${event_id}/game_status`;
    return this.firebaseRtdService.getObjectValueOnce<GAME_STATUS>(path);
  }

  get_speechstatus$(event_id: string): Observable<SpeechStatusDb> {
    const path = '/event_related/livevideo-debate-speechstatus/' + event_id;
    return this.firebaseRtdService.getObjectValue<SpeechStatusDb>(path);
  }

  get_prepdoc$(event_id: string, prep_team: string): Observable<PreparationDocument> {
    const path = '/event_related/livevideo-debate-prepdoc/' + event_id + '/' + prep_team;
    return this.firebaseRtdService.getObjectValue<PreparationDocument>(path);
  }

  get_prepdoc_all$(event_id: string): Observable<PreparationDocumentAllDb> {
    const path = '/event_related/livevideo-debate-prepdoc/' + event_id;
    return this.firebaseRtdService.getObjectValue<PreparationDocumentAllDb>(path);
  }

  get_role_once$(event_id: string): Observable<RoleUser> {
    const path = '/event_related/livevideo-debate-role/' + event_id;
    return this.firebaseRtdService.getObjectValueOnce<RoleUser>(path);
  }

  get_role$(event_id: string): Observable<RoleUser> {
    const path = '/event_related/livevideo-debate-role/' + event_id;
    return this.firebaseRtdService.getObjectValue<RoleUser>(path);
  }

  set_role(event_id: string, role_name: string, user_id: string): Promise<void> {
    const path = '/event_related/livevideo-debate-role/' + event_id + '/' + role_name;
    return this.firebaseRtdService.setObject(path, user_id);
  }

  remove_role(event_id: string, role_name: string): Promise<void> {
    const path = '/event_related/livevideo-debate-role/' + event_id + '/' + role_name;
    return this.firebaseRtdService.removeObject(path);
  }

  get_event_once$(event_id: string): Observable<any> {
    const path = '/event_related/event/' + event_id;
    return this.firebaseRtdService.getObjectValueOnce<any>(path);
  }

  getTabbycatConfig$(event_id: string): Observable<TabbycatData> {
    const path = '/event_related/tabbycat/' + event_id;
    return this.firebaseRtdService.getObjectValueOnce<TabbycatData>(path);
  }

  get_selected_role(event_id: string): Observable<string> {
    const path = '/event_related/livevideo-debate-reflec-selectedrole/' + event_id;
    return this.firebaseRtdService.getObjectValue<string>(path);
  }

  set_selected_role(event_id, role_name: string): Promise<void> {
    const path = '/event_related/livevideo-debate-reflec-selectedrole/' + event_id;
    return this.firebaseRtdService.setObject(path, role_name);
  }

  remove_selected_role(event_id: string): Promise<void> {
    const path = '/event_related/livevideo-debate-reflec-selectedrole/' + event_id;
    return this.firebaseRtdService.removeObject(path);
  }

  get_userenv(in_event_id: string): Observable<{ [key: string]: UserMediaEnv }> {
    const path = '/event_related/user_env/' + in_event_id;
    return this.firebaseRtdService.getObjectValue<{ [key: string]: UserMediaEnv }>(path);
  }

  join_team(event_id, team_name, user_id): Promise<void> {
    const path = '/event_related/livevideo-debate/' + event_id + '/team/' + team_name + '/' + user_id;
    return this.firebaseRtdService
      .setObject(path, true)
      .then(() => {
        console.log('success to join team');
      })
      .catch((err) => {
        alert('fail to join please try again');
      });
  }

  leave_team(event_id, team_name, user_id): Promise<void> {
    const path = '/event_related/livevideo-debate/' + event_id + '/team/' + team_name + '/' + user_id;
    return this.firebaseRtdService
      .removeObject(path)
      .then(() => {
        console.log('success to leave team');
      })
      .catch((err) => {
        console.log('fail to leave team');
      });
  }

  move_team(event_id: string, join_team: string, current_own_team: string[], user_id: string): Promise<void> {
    current_own_team.forEach((current_team: string) => {
      this.leave_team(event_id, current_team, user_id);
    });
    return this.join_team(event_id, join_team, user_id);
  }

  getChangGamestatusCandidate(event_id: string): Observable<GameStatusCandidate> {
    const path = '/event_related/livevideo-gamestatus-candidate/' + event_id;
    return this.firebaseRtdService.getObjectValue<GameStatusCandidate>(path);
  }

  change_game_status_candidate(
    event_id: string,
    game_status: GAME_STATUS,
    direction: GAME_STATUS_DIRECTION,
    own_user_id: string,
  ): Promise<void> {
    const status_candidate: GameStatusCandidate = {
      direction,
      game_status,
      user_id: own_user_id,
    };
    const path = '/event_related/livevideo-gamestatus-candidate/' + event_id;
    return this.firebaseRtdService.setObject(path, status_candidate);
  }

  removeChangGamestatusCandidate(event_id: string): Promise<void> {
    const path = '/event_related/livevideo-gamestatus-candidate/' + event_id;
    return this.firebaseRtdService.removeObject(path);
  }

  change_game_status(event_id: string, status: string): Promise<void> {
    const path = '/event_related/livevideo-debate/' + event_id + '/game_status/';
    return this.firebaseRtdService.setObject(path, status);
  }

  set_recording_status(event_id: string): Promise<void> {
    const path = '/event_related/livevideo-debate-recording/' + event_id;
    const value = Date.now();
    return this.firebaseRtdService.setObject(path, value);
  }

  change_preparation_ready_status(event_id: string, value: boolean): Promise<void> {
    const path = '/event_related/livevideo-debate/' + event_id + '/preparation_ready_status/';
    return this.firebaseRtdService.setObject(path, value);
  }

  save_motion(event_id, in_motion, in_info) {
    // const event_motion_ref = '/event_related/event/' + event_id + '/motion/';

    const updateObject = {};
    let livevideo_debate_motion_ref = '/event_related/livevideo-debate-motion-2/' + event_id;
    let livevideo_debate_prepdoc_motion_ref = '/event_related/livevideo-debate-prepdoc/' + event_id + '/motion-2';

    if (in_info !== undefined) {
      const motion_obj: Motion = {
        motion: in_motion,
        info: in_info,
      };
      updateObject[livevideo_debate_motion_ref] = motion_obj;
      updateObject[livevideo_debate_prepdoc_motion_ref] = motion_obj;
    } else {
      livevideo_debate_motion_ref = livevideo_debate_motion_ref + '/motion';
      livevideo_debate_prepdoc_motion_ref = livevideo_debate_prepdoc_motion_ref + '/motion';
      updateObject[livevideo_debate_motion_ref] = in_motion;
      updateObject[livevideo_debate_prepdoc_motion_ref] = in_motion;
    }

    // updateObject[event_motion_ref] = in_motion;
    // updateObject[audio_transcript_motion_ref] = in_motion;

    return this.firebaseRtdPureService
      .update_multiple_object(updateObject)
      .then(() => {
        console.log('motion has been updated');
      })
      .catch(() => {
        alert('saving the motion has been failed');
      });
  }

  get_motion(event_id: string): Observable<Motion> {
    const livevideo_debate_motion_ref = '/event_related/livevideo-debate-motion-2/' + event_id;
    return this.firebaseRtdService.getObjectValue<Motion>(livevideo_debate_motion_ref);
  }

  save_event_title(event_id: string, title: string): Promise<void> {
    const path = '/event_related/livevideo-debate/' + event_id + '/event_title';
    return this.firebaseRtdService.setObject(path, title);
  }
  set_prepdoc_intro_start_edit(event_id: string, team: string, user_id: string): Promise<void> {
    const path = '/event_related/livevideo-debate-prepdoc/' + event_id + '/' + team + '/intro/editor/' + user_id;
    return this.firebaseRtdService.setObject(path, true);
  }

  set_prepdoc_intro_finish_edit(event_id: string, team: string, user_id: string): Promise<void> {
    const path = '/event_related/livevideo-debate-prepdoc/' + event_id + '/' + team + '/intro/editor/' + user_id;
    return this.firebaseRtdService.removeObject(path);
  }

  set_prepdoc_arg_signpost_start_edit(event_id: string, team: string, arg_num: number, user_id: string): Promise<void> {
    const path =
      '/event_related/livevideo-debate-prepdoc/' + event_id + '/' + team + '/argument/' + String(arg_num) + '/signpost/editor/' + user_id;
    return this.firebaseRtdService.setObject(path, true);
  }

  set_prepdoc_arg_signpost_finish_edit(event_id: string, team: string, arg_num: number, user_id: string): Promise<void> {
    const path =
      '/event_related/livevideo-debate-prepdoc/' + event_id + '/' + team + '/argument/' + String(arg_num) + '/signpost/editor/' + user_id;
    return this.firebaseRtdService.removeObject(path);
  }

  set_prepdoc_refute_start_edit(event_id: string, team: string, arg_num: number, user_id: string): Promise<void> {
    const path =
      '/event_related/livevideo-debate-prepdoc/' + event_id + '/' + team + '/argument/' + String(arg_num) + '/refute/editor/' + user_id;
    return this.firebaseRtdService.setObject(path, true);
  }

  set_prepdoc_refute_finish_edit(event_id: string, team: string, arg_num: number, user_id: string): Promise<void> {
    const path =
      '/event_related/livevideo-debate-prepdoc/' + event_id + '/' + team + '/argument/' + String(arg_num) + '/refute/editor/' + user_id;
    return this.firebaseRtdService.removeObject(path);
  }

  set_prepdoc_arg_context_start_edit(event_id: string, team: string, arg_num: number, user_id: string): Promise<void> {
    const path =
      '/event_related/livevideo-debate-prepdoc/' +
      event_id +
      '/' +
      team +
      '/argument/' +
      String(arg_num) +
      '/main_context/editor/' +
      user_id;
    return this.firebaseRtdService.setObject(path, true);
  }

  set_prepdoc_arg_context_finish_edit(event_id: string, team: string, arg_num: number, user_id: string): Promise<void> {
    const path =
      '/event_related/livevideo-debate-prepdoc/' +
      event_id +
      '/' +
      team +
      '/argument/' +
      String(arg_num) +
      '/main_context/editor/' +
      user_id;
    return this.firebaseRtdService.removeObject(path);
  }

  save_prepdoc_introduction(event_id: string, team: string, context: string): Promise<void> {
    const path = '/event_related/livevideo-debate-prepdoc/' + event_id + '/' + team + '/intro/text/';
    return this.firebaseRtdService.setObject(path, context);
  }

  save_prepdoc_arg_signpost(event_id: string, team: string, arg_num: number, context: string): Promise<void> {
    const path = '/event_related/livevideo-debate-prepdoc/' + event_id + '/' + team + '/argument/' + String(arg_num) + '/signpost/text/';
    return this.firebaseRtdService.setObject(path, context);
  }

  save_prepdoc_refute(event_id: string, team: string, arg_num: number, context: string): Promise<void> {
    const path = '/event_related/livevideo-debate-prepdoc/' + event_id + '/' + team + '/argument/' + String(arg_num) + '/refute/text/';
    return this.firebaseRtdService.setObject(path, context);
  }

  save_prepdoc_arg_context(event_id: string, team: string, arg_num: number, context: string): Promise<void> {
    const path = '/event_related/livevideo-debate-prepdoc/' + event_id + '/' + team + '/argument/' + String(arg_num) + '/main_context/text';
    return this.firebaseRtdService.setObject(path, context);
  }

  /*
  set_prep_duration(event_id : string, duration: number) {
    const reference = '/event_related/livevideo-debate/' + event_id + '/prep_duration';
    this.save_firebase_data(reference, duration);
  }
*/
  set_debate_speaker(event_id: string, speaker_obj: any) {
    const path = '/event_related/livevideo-debate-speechstatus/' + event_id + '/speech_status/main_speaker';
    return this.firebaseRtdService.setObject(path, speaker_obj);
  }

  remove_speech_status(event_id: string): Promise<void> {
    const path = '/event_related/livevideo-debate-speechstatus/' + event_id + '/speech_status/';
    return this.firebaseRtdService.removeObject(path);
  }

  set_poi_candidate(event_id: string, own_user_id: string): Promise<void> {
    const path = '/event_related/livevideo-debate-speechstatus/' + event_id + '/speech_status/poi_candidates/' + own_user_id;
    return this.firebaseRtdService.setObject(path, true);
  }

  cancel_poi_candidate(event_id: string, own_user_id: string): Promise<void> {
    const path = '/event_related/livevideo-debate-speechstatus/' + event_id + '/speech_status/poi_candidates/' + own_user_id;
    return this.firebaseRtdService.removeObject(path);
  }

  cancel_poi_candidate_all(event_id: string): Promise<void> {
    const path = '/event_related/livevideo-debate-speechstatus/' + event_id + '/speech_status/poi_candidates/';
    return this.firebaseRtdService.removeObject(path);
  }

  remove_all_poi_candidates(event_id: string): Promise<void> {
    const path = '/event_related/livevideo-debate-speechstatus/' + event_id + '/speech_status/poi_candidates';
    return this.firebaseRtdService.removeObject(path);
  }

  take_poi(event_id: string, poi_speaker_obj): Promise<void> {
    const path = '/event_related/livevideo-debate-speechstatus/' + event_id + '/speech_status/poi_speaker';
    return this.firebaseRtdService.setObject(path, poi_speaker_obj);
  }
  remove_poi_speaker(event_id: string): Promise<void> {
    const path = '/event_related/livevideo-debate-speechstatus/' + event_id + '/speech_status/poi_speaker';
    return this.firebaseRtdService.removeObject(path);
  }

  change_nextspeaker(event_id, role_num): Promise<void> {
    const path = '/event_related/livevideo-debate-speechstatus/' + event_id + '/speech_status/explicit_next_role';
    return this.firebaseRtdService.setObject(path, role_num);
  }

  start_speech(event_id, role_num, user_id) {
    const path = '/event_related/livevideo-debate-speechstatus/' + event_id + '/speech_log/last_started_speaker';
    const path2 = '/event_related/livevideo-debate-speechstatus/' + event_id + '/speech_log/last_started_role_num';

    const updateObject = {};
    updateObject[path] = user_id;
    updateObject[path2] = role_num;

    return this.firebaseRtdPureService
      .update_multiple_object(updateObject)
      .then(() => {
        console.log('complete speech succeed');
      })
      .catch(() => {
        alert('complete speech failed');
      });
  }

  complete_speech(event_id, role_num, time_spent) {
    const path = '/event_related/livevideo-debate-speechstatus/' + event_id + '/speech_log/completed_role_obj/' + role_num;
    const path2 = '/event_related/livevideo-debate-speechstatus/' + event_id + '/speech_log/last_completed_role_num';

    const updateObject = {};
    updateObject[path] = time_spent;
    updateObject[path2] = role_num;

    return this.firebaseRtdPureService
      .update_multiple_object(updateObject)
      .then(() => {
        console.log('complete speech succeed');
      })
      .catch(() => {
        alert('complete speech failed');
      });
  }

  reflesh_speech_log(event_id): Promise<void> {
    const path = '/event_related/livevideo-debate-speechstatus/' + event_id + '/speech_log';
    return this.firebaseRtdService.removeObject(path);
  }

  /******************** */

  set_agora_existence(event_id: string, room_type, own_userid: string, own_peer_user_data): Promise<void> {
    const path = `/event_related/agora-existence/${event_id}/${room_type}/${own_userid}`;
    return this.firebaseRtdService.setObject(path, own_peer_user_data).then(() => {
      this.firebaseRtdPureService.removeWhenDisconnected(path);
    });
  }
  remove_agora_existence(event_id: string, room_type, own_userid: string): Promise<void> {
    const path = `/event_related/agora-existence/${event_id}/${room_type}/${own_userid}`;
    return this.firebaseRtdService.removeObject(path);
  }

  monitor_agora_existence$(event_id: string, room_type): Observable<any> {
    const path = `/event_related/agora-existence/${event_id}/${room_type}/`;
    return this.firebaseRtdService.getObjectValue(path);
  }

  /******************** */

  set_agora_speaker(event_id: string, room_type, own_userid: string): Promise<void> {
    const path = `/event_related/agora-speaker/${event_id}/${room_type}/${own_userid}`;
    return this.firebaseRtdService.setObject(path, true).then(() => {
      this.firebaseRtdPureService.removeWhenDisconnected(path);
    });
  }
  remove_agora_speaker(event_id: string, room_type, own_userid: string): Promise<void> {
    const path = `/event_related/agora-speaker/${event_id}/${room_type}/${own_userid}`;
    return this.firebaseRtdService.removeObject(path);
  }

  monitor_agora_speaker$(event_id: string, room_type): Observable<any> {
    const path = `/event_related/agora-speaker/${event_id}/${room_type}/`;
    return this.firebaseRtdService.getObjectValue(path);
  }

  set_prep_start_time(event_id: string, current_time: number): Promise<void> {
    const path = '/event_related/livevideo-debate/' + event_id + '/prep_start_time';
    return this.firebaseRtdService.setObject(path, current_time);
  }

  set_judge_start_time(event_id: string, current_time: number): Promise<void> {
    const path = '/event_related/livevideo-debate/' + event_id + '/judge_start_time';
    return this.firebaseRtdService.setObject(path, current_time);
  }

  /*this userenv part can be accessed only from user-env-model */

  set_userenv_video_deactive(event_id, user_id): Promise<void> {
    const path = '/event_related/user_env/' + event_id + '/' + user_id + '/camera/';
    return this.firebaseRtdService.removeObject(path);
  }

  set_userenv_video_active(event_id, user_id): Promise<void> {
    const path = '/event_related/user_env/' + event_id + '/' + user_id + '/camera/';
    return this.firebaseRtdService.setObject(path, USER_ENV.VIDEO);
  }

  set_video_user_peer(event_id, peer_id): Promise<void> {
    const path = `/event_related/video_user/${event_id}/${peer_id}`;
    return this.firebaseRtdService.setObject(path, true);
  }

  remove_video_user_peer(event_id, peer_id): Promise<void> {
    const path = `/event_related/video_user/${event_id}/${peer_id}`;
    return this.firebaseRtdService.removeObject(path);
  }

  get_video_user_peers$(event_id): Observable<string[]> {
    const path = `/event_related/video_user/${event_id}/`;
    return this.firebaseRtdService.getObjectValue(path).pipe(
      map((data) => {
        return data ? Object.keys(data) : [];
      }),
    );
  }

  set_userenv_snapshot_deactive(event_id, user_id): Promise<void> {
    const path = '/event_related/user_env/' + event_id + '/' + user_id + '/camera/';
    return this.firebaseRtdService.removeObject(path);
  }

  set_userenv_snapshot_active(event_id, user_id): Promise<void> {
    const path = '/event_related/user_env/' + event_id + '/' + user_id + '/camera/';
    return this.firebaseRtdService.setObject(path, USER_ENV.SNAPSHOT);
  }

  set_userenv_microphone_deactive(event_id, user_id): Promise<void> {
    const path = '/event_related/user_env/' + event_id + '/' + user_id + '/microphone';
    return this.firebaseRtdService.setObject(path, false);
  }

  set_userenv_microphone_active(event_id, user_id): Promise<void> {
    const path = '/event_related/user_env/' + event_id + '/' + user_id + '/microphone';
    return this.firebaseRtdService.setObject(path, true);
  }

  update_preptime(event_id, prep_duration): Promise<void> {
    const path = '/event_related/livevideo-debate/' + event_id + '/prep_duration';
    return this.firebaseRtdService.setObject(path, prep_duration);
  }

  remove_preptime(event_id): Promise<void> {
    const path = '/event_related/livevideo-debate/' + event_id + '/prep_duration';
    return this.firebaseRtdService.removeObject(path);
  }

  change_style(event_id, style): Promise<any> {
    const path = '/event_related/livevideo-debate/' + event_id + '/deb_style';
    // const path2 = '/event_related/audio_transcriptserver_2/' + event_id + '/deb_style';

    const updateObject = {};
    updateObject[path] = style;
    // updateObject[path2] = style;

    return this.firebaseRtdPureService
      .update_multiple_object(updateObject)
      .then(() => {
        console.log('style has been updated');
      })
      .catch(() => {
        alert('saving the style has been failed');
      });
  }

  save_img_usersnapshot(event_id: string, user_id: string, img_base64: HTMLCanvasElement) {
    const img_blob: Blob = this.firebaseStorageService.dataURLtoBlob(img_base64);

    const storage_path = '/event_related/snapshots/' + event_id;
    const file_name = user_id + '.png';
    const metadata = {
      contentType: 'image/png',
    };

    return this.firebaseStorageService
      .put_img(storage_path, file_name, img_blob, metadata)
      .then((image_url: string) => {
        const img_url_path = '/event_related/snapshots/' + event_id + '/' + user_id;

        return this.firebaseRtdService.setObject(img_url_path, image_url);
      })
      .then(() => {})
      .catch(() => {
        console.log('fail to save file');
      });
  }

  remove_img_usersnapshot(event_id: string, user_id: string) {
    const img_url_path = '/event_related/snapshots/' + event_id + '/' + user_id;
    this.firebaseRtdService
      .setObject(img_url_path, false)
      .then(() => {
        console.log('succeed to remove');
      })
      .catch(() => {
        console.log('fail to remove');
      });
  }

  get_judge_comment$(event_id: string): Observable<CommentDataVm[]> {
    const path = '/event_related/livevideo-debate-judgecomment/' + event_id;
    return this.firebaseRtdService.getSnapshotList<CommentDataDb>(path);
  }

  add_judge_comment(event_id: string, comment, role: ROLE_NAME, own_user_id): PromiseLike<any> {
    const shared_comment: CommentDataDb = {
      text: comment,
      role: role,
      user_id: own_user_id,
    };
    const path = '/event_related/livevideo-debate-judgecomment/' + event_id;

    return this.firebaseRtdService.pushList<CommentDataDb>(path, shared_comment);
  }

  update_judge_comment(event_id: string, comment: string, role: ROLE_NAME, own_user_id: string, firebase_key: string): Promise<void> {
    const path = '/event_related/livevideo-debate-judgecomment/' + event_id + '/' + firebase_key;
    if (comment) {
      const shared_comment: CommentDataDb = {
        text: comment,
        role: role,
        user_id: own_user_id,
      };
      return this.firebaseRtdService.updateObject(path, shared_comment);
    } else {
      return this.firebaseRtdService.removeObject(path);
    }
  }

  get_reactions(event_id: string, room_name): Observable<ReactionWithKey[]> {
    const path = `/event_related/reaction/${event_id}/${room_name}`;
    return this.firebaseRtdService.getSnapshotList<ReactionWithKey>(path);
  }

  update_reaction(event_id: string, reaction_type: REACTION_TYPE, user_id: string, speech_id: number = 0, room_name): Promise<void> {
    const now = Date.now();
    const reaction: Reaction = {
      user_id: user_id,
      speech_id: speech_id,
      reaction_type: reaction_type,
      time: now - speech_id,
    };

    const path = `/event_related/reaction/${event_id}/${room_name}/${now}`;
    return this.firebaseRtdService.updateObject(path, reaction);
  }

  get_presentation$(router_id: string): Observable<Presentation> {
    const path = '/event_related/presentation/' + router_id;
    return this.firebaseRtdService.getObjectValue<Presentation>(path);
  }

  move_layout_candidate_from_presentation_to_game(router_id: string, user_id: string): Promise<void> {
    const path = `/event_related/presentation/${router_id}/`;

    const transition_obj: Presentation = {
      transition_candidate: {
        user_id,
        layout: LAYOUT_TYPE.GAME,
      },
    };
    return this.firebaseRtdService.updateObject(path, transition_obj);
  }

  removePresentationTransitionCandidate(router_id: string): Promise<void> {
    const path = `/event_related/presentation/${router_id}/transition_candidate`;

    return this.firebaseRtdService.removeObject(path);
  }

  removePresentationTransitionCandidate_and_setPresentationTransitionGame(router_id: string): Promise<void> {
    const path = `/event_related/presentation/${router_id}`;

    const transition_obj: Presentation = {
      transition_candidate: null,
      transition: LAYOUT_TYPE.GAME,
    };
    return this.firebaseRtdService.updateObject(path, transition_obj);
  }

  removePresentationTransitionCandidate_and_setPresentationTransitionAuditrium(router_id: string): Promise<void> {
    const path = `/event_related/presentation/${router_id}`;

    const transition_obj: Presentation = {
      transition_candidate: null,
      transition: LAYOUT_TYPE.AUDITRIUM,
    };
    return this.firebaseRtdService.updateObject(path, transition_obj);
  }

  removePresentationTransitionAndTransitioncandidate(router_id: string): Promise<void> {
    const path = `/event_related/presentation/${router_id}/`;

    const transition_obj: Presentation = {
      transition_candidate: null,
      transition: null,
    };
    return this.firebaseRtdService.updateObject(path, transition_obj);
  }

  update_for_reemoving_round_data(router_id: string, round_data: PreviousRoundDataForRemove) {
    const path = `/event_related/remove_round_data/${router_id}/`;
    return this.firebaseRtdService.updateObject(path, round_data);
  }

  move_allparticipants_to_auditorium(router_id: string, move_obj: MoveParticipantsData) {
    const path = `/event_related/move_participants/${router_id}/`;
    return this.firebaseRtdService.updateObject(path, move_obj);
  }

  get_registered_team_info(event_id: string): Observable<RegisteredTeamInfo> {
    const path = `/event_related/registered_team/${event_id}/`;
    return this.firebaseRtdService.getObjectValue<RegisteredTeamInfo>(path);
  }

  add_registered_team(event_id: string, team_name: string, own_userid: string) {
    const registered_team: RegisteredTeamData = {
      registeredTeam_name: team_name,
      registeredTeam_members: {},
      registered_by: own_userid,
    };

    const path = `/event_related/registered_team/${event_id}/registeredTeams`;
    return this.firebaseRtdService.pushList<RegisteredTeamData>(path, registered_team);
  }

  async add_registered_team_tab(event_id: string, team_name: string, own_userid: string, tab_team_id: string): Promise<any> {
    const path = `/event_related/registered_team/${event_id}/registeredTeams/${tab_team_id}`;

    const team = await this.firebaseRtdService.getObjectValueOnce(path).toPromise();
    if (team) {
      const registered_team: RegisteredTeamData = {
        registeredTeam_name: team_name,
      };

      await this.firebaseRtdService.updateObject(path, registered_team);
    } else {
      const registered_team: RegisteredTeamData = {
        registeredTeam_name: team_name,
        registeredTeam_members: {},
        registered_by: own_userid,
        is_tabbycat_data: true,
      };
      await this.firebaseRtdService.setObject(path, registered_team);
    }
  }

  remove_registered_team(event_id: string, team_id: string) {
    const path = `/event_related/registered_team/${event_id}/registeredTeams/${team_id}`;
    return this.firebaseRtdService.removeObject(path);
  }

  join_registered_team(event_id: string, team_id: string, own_userid: string) {
    const path = `/event_related/registered_team/${event_id}/registeredTeams/${team_id}/registeredTeam_members/${own_userid}`;
    return this.firebaseRtdService.setObject(path, true);
  }

  leave_registered_team(event_id: string, team_id: string, own_userid: string) {
    const path = `/event_related/registered_team/${event_id}/registeredTeams/${team_id}/registeredTeam_members/${own_userid}`;
    return this.firebaseRtdService.removeObject(path);
  }

  join_registered_individual_debater(event_id: string, own_userid: string) {
    const path = `/event_related/registered_team/${event_id}/registeredIndividualDebaters/${own_userid}`;
    return this.firebaseRtdService.setObject(path, true);
  }

  leave_registered_individual_debater(event_id: string, own_userid: string) {
    const path = `/event_related/registered_team/${event_id}/registeredIndividualDebaters/${own_userid}`;
    return this.firebaseRtdService.removeObject(path);
  }

  join_registered_judge(event_id: string, own_userid: string) {
    const path = `/event_related/registered_team/${event_id}/registeredJudges/${own_userid}`;
    return this.firebaseRtdService.setObject(path, true);
  }

  leave_registered_judge(event_id: string, own_userid: string) {
    const path = `/event_related/registered_team/${event_id}/registeredJudges/${own_userid}`;
    return this.firebaseRtdService.removeObject(path);
  }

  join_registered_audience(event_id: string, own_userid: string) {
    const path = `/event_related/registered_team/${event_id}/registeredAudiences/${own_userid}`;
    return this.firebaseRtdService.setObject(path, true);
  }

  leave_registered_audience(event_id: string, own_userid: string) {
    const path = `/event_related/registered_team/${event_id}/registeredAudiences/${own_userid}`;
    return this.firebaseRtdService.removeObject(path);
  }
}
