import { Component, Input, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { FirestoreApiService } from '@mixidea-client/firestore-lib';
import { UserauthService } from './../../core/service/userauth.service';

import { take, switchMap, map } from 'rxjs/operators';
import { of, Subscription } from 'rxjs';

import { SchoolDataWithId, OwnUser } from '@mixidea-client/model';

const excluded_school_list = ['kCBSB4yL1zdKUe1e9bbf', 'uuA8eYEGjBT0Mli9m9Ml'];

@Component({
  selector: 'mixidea-client-ad-area',
  templateUrl: './ad-area.component.html',
  styleUrls: ['./ad-area.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
// export class AdAreaComponent implements OnInit, OnDestroy {
export class AdAreaComponent {
  // is_show_ad = false;
  // ad_subscription: Subscription;
  // img_url = "https://storage.googleapis.com/mixidea_resource/imgbnr_02.jpg";
  // page_link = "https://www.benesse-glc.com/lab/blog/";
  // constructor(
  //   private firestoreApiService: FirestoreApiService,
  //   private change_ref: ChangeDetectorRef,
  //   private user_auth: UserauthService,
  //   ) {}
  // ngOnInit() {
  //   this.ad_subscription =
  //   this.user_auth
  //   .get_own_user$()
  //   .pipe(
  //     map((own_user: OwnUser)=>{
  //       return own_user.id;
  //     }),
  //   switchMap((own_user_id: string)=>{
  //     if(own_user_id){
  //       return this.firestoreApiService.getMySchoolList(own_user_id).pipe(take(1))
  //     }else{
  //       return of([])
  //     }
  //   })).subscribe(( school_data_arr: SchoolDataWithId[] ) => {
  //     this.is_show_ad = true;
  //     const school_id_arr: string[] = school_data_arr.map(
  //       (school_data: SchoolDataWithId)=>{
  //         return school_data.id;
  //       }
  //     )
  //     if(school_id_arr){
  //       school_id_arr.forEach((school_id: string)=>{
  //         if( excluded_school_list.includes(school_id)){
  //           this.is_show_ad = false;
  //         }
  //       })
  //     }
  //     this.change_ref.detectChanges();
  //   });
  // }
  // ngOnDestroy(){
  //   if(this.ad_subscription){
  //     this.ad_subscription.unsubscribe();
  //   }
  // }
}
