import { Injectable } from '@angular/core';

import { combineLatest, Observable, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

import firebase from 'firebase/compat/app';

import { FirestoreService, QueryParam, ORDERBY_DIRECTION } from './services/firestore.service';
import { FirestorePureService, BatchObject, BatchUnit, BatchPath } from './services/firestore-pure.service';

import {
  CourseData,
  CourseDataWithId,
  EVENT_APPLICATION_QUALIFICATION,
  EventDataWithLecture,
  EventDataWithLectureWithId,
  EventDataWithLectureTimeDateConverted,
  EventDataWithLectureTimeDateConvertedWithId,
  EventDataWithLectureVMwithId,
  LessonDataTimeDateConverted,
  LessonDataTimeDateConvertedWithId,
  LessonDataForBatchFunc,
  EventDataWithLectureVM,
  TeacherSubcollectionData,
  GroupData,
  GroupDataWithId,
  GroupMember,
  GroupMemberWithId,
  LessonData,
  LessonDataWithId,
  MainComment,
  MainCommentWithId,
  OrganizerSubcollectionDataWithId,
  ParticipantSubcollectionData,
  ParticipantSubcollectionDataWithId,
  Participate,
  SchoolData,
  SchoolDataWithId,
  SubComment,
  SubCommentWithId,
  TeacherData,
  TeadherDataWithId,
  TimelineBaseDb,
  UserSearchWithId,
  LineData,
  MESSAGE_ORIGIN,
  LineUserWithId,
  COURSE_STATUS,
  UsersRecordFirebase,
  TeacherAdminData,
  EVENT_LESSON_ACTION_BY_STUDENTS,
  EVENT_LESSON_ACTION_BY_ADMIN,
  // EventLectureCreateParam,
  SchoolApplicantInfo,
  ParticipantIntension,
  LessonParticipantIntensionSubcollectionData,
  TEACHER_TYPE,
  LESSON_STATUS,
  LessonDataWithIdWithMyIntension,
  TabbycatInput,
  StudyWork,
  StudyCorrection,
  PriceUserPaymentStatus,
  PriceUserPaymentStatusVm,
  PricePaymentHistory,
  WithTimestampPayment,
  StripeUser,
  StudyContent,
  ZoomData,
} from '@mixidea-client/model';

// import * as moment from 'moment';

export interface WithTimestamp {
  start_timestamp?: firebase.firestore.Timestamp;
}
export interface WithTimestampStudy {
  added_timestamp?: firebase.firestore.Timestamp;
  updated_timestamp?: firebase.firestore.Timestamp;
  correction_done_timestamp?: firebase.firestore.Timestamp;
  added_date?: Date;
  updated_date?: Date;
  correction_done_date?: Date;
}

export interface WithTime {
  time?: firebase.firestore.Timestamp;
}

export interface WithDate {
  start_date: Date;
}

@Injectable()
export class FirestoreApiService {
  constructor(private firestoreService: FirestoreService, private firestorePureService: FirestorePureService) {}

  getCommentOnGroupTimeline$(group_id: string): Observable<MainCommentWithId[]> {
    const query_param: QueryParam = {
      where_param: [],
      orderby_param: { key: 'time_added_timestamp', order: ORDERBY_DIRECTION.DESCENDING },
      startat_param: null,
      startafter_param: null,
    };

    const path = `group-2/${group_id}/timeline`;
    return this.firestoreService
      .getSnapshotListWithQuery<MainCommentWithId>(path, query_param)
      .pipe(map(firebaseTimestampArrMapping), map(firebaseLastSubcommentTimestampMapping));
  }

  getCommentOnEventDetail$(event_id: string): Observable<MainCommentWithId[]> {
    const query_param: QueryParam = {
      where_param: [],
      orderby_param: { key: 'time_added_timestamp', order: ORDERBY_DIRECTION.DESCENDING },
      startat_param: null,
      startafter_param: null,
    };

    const path = `event-3/${event_id}/comment`;
    return this.firestoreService
      .getSnapshotListWithQuery<MainCommentWithId>(path, query_param)
      .pipe(map(firebaseTimestampArrMapping), map(firebaseLastSubcommentTimestampMapping));
  }

  async addCommentOnGroupTimeline(group_id: string, data: MainComment): Promise<string> {
    const time_added_timestamp = firebase.firestore.Timestamp.fromDate(new Date());
    const time_updated_timestamp = firebase.firestore.Timestamp.fromDate(new Date());
    data.time_added_timestamp = time_added_timestamp;
    data.time_updated_timestamp = time_updated_timestamp;

    const path = `group-2/${group_id}/timeline`;
    return this.firestoreService.pushList(path, data);
  }

  async updateCommentOnGroupTimeline(group_id: string, data: MainComment, comment_id: string): Promise<void> {
    const time_updated_timestamp = firebase.firestore.Timestamp.fromDate(new Date());
    data.time_updated_timestamp = time_updated_timestamp;

    const path = `group-2/${group_id}/timeline/${comment_id}`;
    return this.firestoreService.updateObject(path, data);
  }

  async addCommentOnEventDetail(event_id: string, data: MainComment): Promise<string> {
    const time_added_timestamp = firebase.firestore.Timestamp.fromDate(new Date());
    const time_updated_timestamp = firebase.firestore.Timestamp.fromDate(new Date());
    data.time_added_timestamp = time_added_timestamp;
    data.time_updated_timestamp = time_updated_timestamp;

    const path = `event-3/${event_id}/comment`;
    return this.firestoreService.pushList(path, data);
  }

  async updateCommentOnEventDetail(event_id: string, data: MainComment, comment_id: string): Promise<void> {
    const time_updated_timestamp = firebase.firestore.Timestamp.fromDate(new Date());
    data.time_updated_timestamp = time_updated_timestamp;

    const path = `event-3/${event_id}/comment/${comment_id}`;
    return this.firestoreService.updateObject(path, data);
  }

  addSubCommentOnGroupTimeline(group_id: string, maincomment_id: string, data: SubComment): Promise<string> {
    const time_added_timestamp = firebase.firestore.Timestamp.fromDate(new Date());
    const time_updated_timestamp = firebase.firestore.Timestamp.fromDate(new Date());
    data.time_added_timestamp = time_added_timestamp;
    data.time_updated_timestamp = time_updated_timestamp;

    const path = `group-2/${group_id}/timeline/${maincomment_id}/subcomment`;
    return this.firestoreService.pushList(path, data);
  }

  addSubCommentOnEventDetail(event_id: string, maincomment_id: string, data: SubComment): Promise<string> {
    const time_added_timestamp = firebase.firestore.Timestamp.fromDate(new Date());
    const time_updated_timestamp = firebase.firestore.Timestamp.fromDate(new Date());
    data.time_added_timestamp = time_added_timestamp;
    data.time_updated_timestamp = time_updated_timestamp;

    const path = `event-3/${event_id}/comment/${maincomment_id}/subcomment`;
    return this.firestoreService.pushList(path, data);
  }

  getSubCommentOnGroupTimeline$(group_id: string, maincomment_id: string): Observable<SubCommentWithId[]> {
    const query_param: QueryParam = {
      where_param: [],
      orderby_param: { key: 'time_added_timestamp', order: ORDERBY_DIRECTION.ASCENDING },
      startat_param: null,
      startafter_param: null,
    };
    const path = `group-2/${group_id}/timeline/${maincomment_id}/subcomment`;
    return this.firestoreService.getSnapshotListWithQuery<SubCommentWithId>(path, query_param).pipe(map(firebaseTimestampArrMapping));
  }

  getSubCommentOnEventDetail$(event_id: string, maincomment_id: string): Observable<SubCommentWithId[]> {
    const query_param: QueryParam = {
      where_param: [],
      orderby_param: { key: 'time_added_timestamp', order: ORDERBY_DIRECTION.ASCENDING },
      startat_param: null,
      startafter_param: null,
    };
    const path = `event-3/${event_id}/comment/${maincomment_id}/subcomment`;
    return this.firestoreService.getSnapshotListWithQuery<SubCommentWithId>(path, query_param).pipe(map(firebaseTimestampArrMapping));
  }

  getZoomDetail(event_id: string): Observable<ZoomData> {
    const zoom_subcollection_id = 'zoomData';
    const path = `event-3/${event_id}/zoom/${zoom_subcollection_id}`;
    return this.firestoreService.getObjectValue(path);
  }

  async createEventLecture(event_lecture: EventDataWithLecture, own_user_id: string, start_date: Date): Promise<string> {
    const lesson_id = event_lecture.lesson_id;
    const event_id = await this.addEvent(event_lecture, start_date);
    await this.setOrganizersOnEvent(event_id, own_user_id, own_user_id);
    const partial_lesson_data: Partial<LessonData> = { event_id, lesson_status: LESSON_STATUS.TEMPORAL_RESERVATION_FINISHED };
    await this.updatePartialLessonById(lesson_id, partial_lesson_data);
    return event_id;
  }

  addEvent(data: EventDataWithLecture, start_date: Date): Promise<string> {
    data.start_timestamp = firebase.firestore.Timestamp.fromDate(start_date);
    const path = `event-3`;
    return this.firestoreService.pushList(path, data);
  }

  async storeLineUseridMappingg(line_id: string, own_userid: string, own_name: string) {
    const obj = {
      set_date: firebase.firestore.Timestamp.fromDate(new Date()),
      user_name: own_name,
    };
    const path = `line/${line_id}/users/${own_userid}`;
    await this.firestoreService.setObject(path, obj);

    const lineid_obj = {};
    lineid_obj[line_id] = obj;

    const path2 = `line-user-mapping/${own_userid}/`;

    await this.firestoreService.setMergeObject(path2, lineid_obj);
  }

  get_line_user_mapping(own_userid): Observable<any> {
    const path2 = `line-user-mapping/${own_userid}/`;
    return this.firestoreService.getObjectValueOnce(path2);
  }

  getLineUsers(): Observable<LineUserWithId[]> {
    const path = `line`;

    const query_param: QueryParam = {
      where_param: [],
      orderby_param: { key: 'last_updated', order: ORDERBY_DIRECTION.DESCENDING },
      startat_param: null,
      startafter_param: null,
    };

    return this.firestoreService.getSnapshotListWithQuery<LineUserWithId>(path, query_param);
  }

  getLineMessages(line_userid): Observable<LineData[]> {
    const query_param: QueryParam = {
      where_param: [],
      orderby_param: { key: 'time', order: ORDERBY_DIRECTION.ASCENDING },
      startat_param: null,
      startafter_param: null,
    };

    const path = `line/${line_userid}/messages`;
    console.log(path);
    return this.firestoreService.getSnapshotListWithQuery<LineData>(path, query_param).pipe(
      map((messages: LineData[]) => {
        return messages.map((line_data) => {
          if (line_data && line_data.time) {
            return Object.assign(line_data, { date: line_data.time.toDate() });
          } else {
            return line_data;
          }
        });
      }),
    );
  }

  addLineChatFromAdmin(line_userid, text: string, admin_userid: string) {
    const obj: LineData = {
      admin_data: { text },
      forward_message_line_app: true,
      time: firebase.firestore.Timestamp.fromDate(new Date()),
      origin: MESSAGE_ORIGIN.ADMIN_DASHBOARD,
      admin_userid,
    };

    const path = `line/${line_userid}/messages`;
    return this.firestoreService.pushList(path, obj);
  }

  setLectureEventAsStudentAction(event_id: string, own_user_id: string, student_action: EVENT_LESSON_ACTION_BY_STUDENTS): Promise<void> {
    const student_action_obj = {
      set_timestamp: firebase.firestore.Timestamp.fromDate(new Date()),
      student_action,
    };

    const path = `event-3/${event_id}/event_lecture_student_action/${own_user_id}`;
    return this.firestoreService.setObject(path, student_action_obj);
  }

  setLectureEventAsAdminAction(
    event_id: string,
    student_user_id: string,
    admin_action: EVENT_LESSON_ACTION_BY_ADMIN,
    admin_userid: string,
  ): Promise<void> {
    const student_action_obj = {
      set_timestamp: firebase.firestore.Timestamp.fromDate(new Date()),
      admin_action,
      admin_userid,
    };
    const path = `event-3/${event_id}/event_lecture_admin_action/${student_user_id}`;
    return this.firestoreService.setObject(path, student_action_obj);
  }

  setLectureEventAsTeacher(event_id: string, teacher_user_id: string, own_user_id: string, teacher_type: TEACHER_TYPE): Promise<void> {
    const teacher_obj: TeacherSubcollectionData = {
      set_timestamp: firebase.firestore.Timestamp.fromDate(new Date()),
      set_by: own_user_id,
      teacher_type,
    };
    const path = `event-3/${event_id}/event_lecture_teacher/${teacher_user_id}`;
    return this.firestoreService.setObject(path, teacher_obj).then(() => {
      this.setOrganizersOnEvent(event_id, teacher_user_id, own_user_id);
    });
  }

  updateEvent(data: EventDataWithLecture, start_date: Date, event_id: string): Promise<void> {
    data.start_timestamp = firebase.firestore.Timestamp.fromDate(start_date);
    const path = `event-3/${event_id}`;
    return this.firestoreService.updateObject(path, data);
  }

  updatePartialEvent(data: EventDataWithLecture, event_id: string): Promise<void> {
    const path = `event-3/${event_id}`;
    return this.firestoreService.updateObject(path, data);
  }

  getEventListOld$(date_from): Observable<EventDataWithLectureTimeDateConvertedWithId[]> {
    const timestamp_from = firebase.firestore.Timestamp.fromDate(date_from);

    const query_param: QueryParam = {
      where_param: [],
      orderby_param: null,
      startat_param: null,
      startafter_param: null,
    };
    const path = `event`;
    return this.firestoreService
      .getSnapshotListWithQuery<EventDataWithLectureWithId>(path, query_param)
      .pipe(map(firebaseTimestampDateArrMapping));
  }

  getEventList$(date_from: Date, date_to: Date): Observable<EventDataWithLectureTimeDateConvertedWithId[]> {
    const timestamp_from = firebase.firestore.Timestamp.fromDate(date_from);
    const timestamp_to = firebase.firestore.Timestamp.fromDate(date_to);

    const query_param: QueryParam = {
      where_param: [
        { key: 'start_timestamp', criteria: '>', value: timestamp_from },
        { key: 'start_timestamp', criteria: '<', value: timestamp_to },
      ],
      orderby_param: { key: 'start_timestamp', order: ORDERBY_DIRECTION.ASCENDING },
      startat_param: null,
      startafter_param: null,
    };
    const path = `event-3`;
    return this.firestoreService
      .getSnapshotListWithQuery<EventDataWithLectureWithId>(path, query_param)
      .pipe(map(firebaseTimestampDateArrMapping));
  }

  getEventListByCourseId$(course_id?: string): Observable<EventDataWithLectureTimeDateConvertedWithId[]> {
    const query_param: QueryParam = {
      where_param: [{ key: 'course_id', criteria: '==', value: course_id }],
      orderby_param: { key: 'start_timestamp', order: ORDERBY_DIRECTION.ASCENDING },
      startat_param: null,
      startafter_param: null,
    };
    const path = `event-3`;
    return this.firestoreService
      .getSnapshotListWithQuery<EventDataWithLectureTimeDateConvertedWithId>(path, query_param)
      .pipe(map(firebaseTimestampDateArrMapping));
  }

  getOwnEventListWithOwnAvailability$(own_user_id: string, date_from: Date): Observable<EventDataWithLectureVMwithId[]> {
    return combineLatest(
      this.getMyGroupListExceptUnderReques$(own_user_id),
      this.getOwnEvent$(own_user_id, date_from),
      this.getMySchoolList(own_user_id),
    ).pipe(
      map(
        ([my_group_list, event_item_arr, my_school_list]: [
          GroupDataWithId[],
          EventDataWithLectureTimeDateConvertedWithId[],
          SchoolDataWithId[],
        ]) => {
          const converted_eventitem_arr = [];
          event_item_arr.forEach((event_item: EventDataWithLectureTimeDateConvertedWithId) => {
            const eventitem_with_ownavailability: EventDataWithLectureVMwithId = eventItemAddOwnAvailability(
              my_group_list,
              event_item,
              own_user_id,
              my_school_list,
            );
            converted_eventitem_arr.push(eventitem_with_ownavailability);
          });
          return converted_eventitem_arr;
        },
      ),
    );
  }

  private getOwnEvent$(own_user_id: string, date_from: Date): Observable<EventDataWithLectureTimeDateConvertedWithId[]> {
    const timestamp_from = firebase.firestore.Timestamp.fromDate(date_from);

    return combineLatest(
      this.getOwnEventGoing$(own_user_id, timestamp_from),
      this.getOwnEventInvited$(own_user_id, timestamp_from),
      this.getOwnEventCreate$(own_user_id, timestamp_from),
    ).pipe(
      map(([going_event = [], invited_event = [], created_events = []]) => {
        const invited_going_event = going_event.concat(invited_event);
        const invited_going_filterd = invited_going_event.filter((event) => {
          for (let created_event of created_events) {
            if (created_event.id === event.id) {
              return false;
            }
          }
          return true;
        });
        const all_own_events = created_events.concat(invited_going_filterd);

        return all_own_events;
      }),
    );
  }

  getOwnEventMemmber$(own_user_id: string, from_date: Date): Observable<EventDataWithLectureTimeDateConvertedWithId[]> {
    const timestamp_from = firebase.firestore.Timestamp.fromDate(from_date);

    const path = `event-3`;
    const query_param: QueryParam = {
      where_param: [
        { key: `start_timestamp`, criteria: '>', value: timestamp_from },
        { key: 'all_related_users', criteria: 'array-contains', value: own_user_id },
      ],
      orderby_param: { key: `start_timestamp`, order: ORDERBY_DIRECTION.ASCENDING },
      startat_param: null,
      startafter_param: null,
    };
    return this.firestoreService
      .getSnapshotListWithQuery<EventDataWithLectureWithId>(path, query_param)
      .pipe(map(firebaseTimestampDateArrMapping));
  }

  private getOwnEventGoing$(
    own_user_id: string,
    timestamp_from: firebase.firestore.Timestamp,
  ): Observable<EventDataWithLectureTimeDateConvertedWithId[]> {
    // console.log('get_own_event', own_user_id);
    const path = `event-3`;
    const query_param: QueryParam = {
      where_param: [
        { key: `start_timestamp`, criteria: '>', value: timestamp_from },
        { key: 'participants_going', criteria: 'array-contains', value: own_user_id },
      ],
      orderby_param: { key: `start_timestamp`, order: ORDERBY_DIRECTION.ASCENDING },
      startat_param: null,
      startafter_param: null,
    };
    return this.firestoreService
      .getSnapshotListWithQuery<EventDataWithLectureWithId>(path, query_param)
      .pipe(map(firebaseTimestampDateArrMapping));
  }

  private getOwnEventInvited$(
    own_user_id: string,
    timestamp_from: firebase.firestore.Timestamp,
  ): Observable<EventDataWithLectureTimeDateConvertedWithId[]> {
    // console.log('get_own_event', own_user_id);
    const path = `event-3`;
    const query_param: QueryParam = {
      where_param: [
        { key: `start_timestamp`, criteria: '>', value: timestamp_from },
        { key: 'participants_invited', criteria: 'array-contains', value: own_user_id },
      ],
      orderby_param: { key: `start_timestamp`, order: ORDERBY_DIRECTION.ASCENDING },
      startat_param: null,
      startafter_param: null,
    };
    return this.firestoreService
      .getSnapshotListWithQuery<EventDataWithLectureWithId>(path, query_param)
      .pipe(map(firebaseTimestampDateArrMapping));
  }

  private getOwnEventCreate$(
    own_user_id: string,
    timestamp_from: firebase.firestore.Timestamp,
  ): Observable<EventDataWithLectureTimeDateConvertedWithId[]> {
    // console.log('get_own_event', own_user_id);
    const path = `event-3`;
    const query_param: QueryParam = {
      where_param: [
        { key: `start_timestamp`, criteria: '>', value: timestamp_from },
        { key: 'created_by', criteria: '==', value: own_user_id },
      ],
      orderby_param: { key: `start_timestamp`, order: ORDERBY_DIRECTION.ASCENDING },
      startat_param: null,
      startafter_param: null,
    };
    return this.firestoreService
      .getSnapshotListWithQuery<EventDataWithLectureWithId>(path, query_param)
      .pipe(map(firebaseTimestampDateArrMapping));
  }

  getGroupEvent$(group_id: string, date_from: Date): Observable<EventDataWithLectureTimeDateConvertedWithId[]> {
    const path = `event-3`;
    const query_param: QueryParam = {
      where_param: [
        { key: `associated_group.id`, criteria: '==', value: group_id },
        { key: `start_timestamp`, criteria: '>', value: date_from },
      ],
      orderby_param: { key: 'start_timestamp', order: ORDERBY_DIRECTION.ASCENDING },
      startat_param: null,
      startafter_param: null,
    };
    return this.firestoreService
      .getSnapshotListWithQuery<EventDataWithLectureWithId>(path, query_param)
      .pipe(map(firebaseTimestampDateArrMapping));
  }

  getEvent$(event_id): Observable<EventDataWithLectureTimeDateConverted> {
    const path = `event-3/${event_id}`;
    return this.firestoreService.getObjectValue<EventDataWithLecture>(path).pipe(map(firebaseTimestampDateMapping));
  }

  getEventPayment(event_id): Observable<any> {
    const path = `event-payment/${event_id}`;
    return this.firestoreService.getObjectValueOnce<any>(path).pipe(
      map((data) => {
        return { id: event_id, data };
      }),
    );
  }

  setEventPayment(event_id, data): Promise<any> {
    const path = `event-payment/${event_id}`;
    return this.firestoreService.setMergeObject(path, data);
  }

  getFixedPriceEventTeacher(event_id): Observable<any> {
    const path = `event-fixed-price-payment/${event_id}`;
    return this.firestoreService.getObjectValueOnce<any>(path).pipe(
      map((data) => {
        return { id: event_id, data };
      }),
    );
  }

  setFixedPriceEventTeacher(event_id, data): Promise<any> {
    const path = `event-fixed-price-payment/${event_id}`;
    return this.firestoreService.setMergeObject(path, data);
  }

  add_send_invoice(data, id) {
    const path = `invoice-mail-pdf/${id}`;
    data.added_timestamp = firebase.firestore.Timestamp.fromDate(new Date());
    return this.firestoreService.setObject(path, data);
  }

  getEventOnce(event_id): Promise<EventDataWithLectureTimeDateConverted> {
    return this.getEvent$(event_id).pipe(take(1)).toPromise();
  }

  getEventListWithOwnAvailabilityWithoutUser$(date_from: Date, date_to: Date): Observable<EventDataWithLectureVMwithId[]> {
    return this.getEventList$(date_from, date_to).pipe(
      map((event_item_arr: EventDataWithLectureTimeDateConvertedWithId[]) => {
        return event_item_arr.map((event_item: EventDataWithLectureTimeDateConvertedWithId) => {
          const eventitem_with_ownavailability: EventDataWithLectureVMwithId = eventItemAddOwnAvailabilityWithoutUser(event_item);
          return eventitem_with_ownavailability;
        });
      }),
    );
  }

  getEventListWithOwnAvailability$(own_user_id: string, date_from: Date, date_to: Date): Observable<EventDataWithLectureVMwithId[]> {
    return combineLatest(
      this.getMyGroupListExceptUnderReques$(own_user_id),
      this.getEventList$(date_from, date_to),
      this.getMySchoolList(own_user_id),
    ).pipe(
      map(
        ([my_group_list, event_item_arr, my_school_list]: [
          GroupDataWithId[],
          EventDataWithLectureTimeDateConvertedWithId[],
          SchoolDataWithId[],
        ]) => {
          const converted_eventitem_arr = [];
          event_item_arr.forEach((event_item: EventDataWithLectureTimeDateConvertedWithId) => {
            const eventitem_with_ownavailability: EventDataWithLectureVMwithId = eventItemAddOwnAvailability(
              my_group_list,
              event_item,
              own_user_id,
              my_school_list,
            );
            converted_eventitem_arr.push(eventitem_with_ownavailability);
          });
          return converted_eventitem_arr;
        },
      ),
    );
  }

  getEventWithOwnAvailability$(event_id, own_user_id): Observable<EventDataWithLectureVM> {
    return of({}).pipe(
      switchMap(() => {
        if (own_user_id) {
          return combineLatest(
            this.getMyGroupListExceptUnderReques$(own_user_id),
            this.getEvent$(event_id),
            this.getMySchoolList(own_user_id),
          );
        } else {
          return combineLatest(of({}), this.getEvent$(event_id));
        }
      }),
      switchMap(
        ([my_group_list, event_item, my_school_list]: [GroupDataWithId[], EventDataWithLectureTimeDateConverted, SchoolDataWithId[]]) => {
          const eventitem_with_ownavailability: EventDataWithLectureVM = eventItemAddOwnAvailability(
            my_group_list,
            event_item,
            own_user_id,
            my_school_list,
          );
          return of(eventitem_with_ownavailability);
        },
      ),
    );
  }

  setParticipateOnEvent(event_id: string, user_id: string, participate_type: Participate, own_user_id: string): Promise<void> {
    const path = `event-3/${event_id}/participants/${user_id}`;
    const participate_obj: ParticipantSubcollectionData = { type: participate_type, set_by: own_user_id };
    return this.firestoreService.setObject(path, participate_obj);
  }

  removeParticipateOnEvent(event_id: string, user_id: string): Promise<void> {
    const path = `event-3/${event_id}/participants/${user_id}`;
    return this.firestoreService.removeObject(path);
  }
  getParticipateOnEvent(event_id: string): Observable<ParticipantSubcollectionDataWithId[]> {
    const path = `event-3/${event_id}/participants`;
    return this.firestoreService.getSnapshotList<ParticipantSubcollectionDataWithId>(path);
  }

  setOrganizersOnEvent(event_id: string, user_id: string, own_user_id: string): Promise<void> {
    const path = `event-3/${event_id}/organizers/${user_id}`;
    const participate_obj: ParticipantSubcollectionData = { set_by: own_user_id };
    return this.firestoreService.setObject(path, participate_obj);
  }

  getOrganizersOnEvent(event_id: string): Observable<OrganizerSubcollectionDataWithId[]> {
    const path = `event-3/${event_id}/organizers`;
    return this.firestoreService.getSnapshotList<OrganizerSubcollectionDataWithId>(path);
  }

  // getGroupList$(): Observable<GroupDataWithId[]> {
  //   const query_param: QueryParam = {
  //     where_param: [],
  //     orderby_param: null,
  //     startat_param: null,
  //     startafter_param: null,
  //   };
  //   const path = `group-2/`;
  //   return this.firestoreService.getSnapshotListWithQuery<GroupDataWithId>(path, query_param);
  // }

  getMyGroupList$(own_userid): Observable<GroupDataWithId[]> {
    const query_param: QueryParam = {
      where_param: [{ key: `member_arr`, criteria: 'array-contains', value: own_userid }],
      orderby_param: { key: 'event_last_updated', order: ORDERBY_DIRECTION.DESCENDING },
      startat_param: null,
      startafter_param: null,
    };
    const path = `group-2/`;
    return this.firestoreService
      .getSnapshotListWithQuery<GroupDataWithId>(path, query_param)
      .pipe(map(groupFirebaseTimestampDateArrMapping));
  }

  getLastUpdatedGroupList$(): Observable<GroupDataWithId[]> {
    const query_param: QueryParam = {
      where_param: [],
      orderby_param: { key: 'event_last_updated', order: ORDERBY_DIRECTION.DESCENDING },
      startat_param: null,
      startafter_param: null,
    };
    const path = `group-2/`;
    return this.firestoreService
      .getSnapshotListWithQuery<GroupDataWithId>(path, query_param)
      .pipe(map(groupFirebaseTimestampDateArrMapping));
  }

  getMyGroupListExceptUnderReques$(own_userid): Observable<GroupDataWithId[]> {
    return this.getMyGroupList$(own_userid);
  }

  getMyGroupListExceptUnderRequestOnce(own_userid): Observable<GroupDataWithId[]> {
    return this.getMyGroupListExceptUnderReques$(own_userid).pipe(take(1));
  }

  getSystemAdmin() {
    const path = `admin-user/admin-user`;
    return this.firestoreService.getObjectValueOnce(path);
  }

  getGroup$(group_id: string): Observable<GroupData> {
    const path = `group-2/${group_id}`;
    return this.firestoreService.getObjectValue<GroupData>(path);
  }

  getGroupOnce$(group_id: string): Observable<GroupData> {
    const path = `group-2/${group_id}`;
    return this.firestoreService.getObjectValueOnce<GroupData>(path);
  }

  async addGroup(data: GroupData): Promise<string> {
    const event_last_updated = firebase.firestore.Timestamp.fromDate(new Date());
    data.event_last_updated = event_last_updated;
    const path = `group-2`;
    return this.firestoreService.pushList(path, data);
  }

  async updateGroup(group_id: string, data: GroupData): Promise<void> {
    const event_last_updated = firebase.firestore.Timestamp.fromDate(new Date());
    data.event_last_updated = event_last_updated;
    const path = `group-2/${group_id}`;
    return this.firestoreService.updateObject(path, data);
  }

  getGroupMembers$(group_id: string): Observable<GroupMemberWithId[]> {
    const path = `group-2/${group_id}/member`;
    return this.firestoreService.getSnapshotList<GroupMemberWithId>(path);
  }
  getGroupMembersOnce(group_id: string): Observable<GroupMemberWithId[]> {
    const path = `group-2/${group_id}/member`;
    return this.firestoreService.getSnapshotListOnce<GroupMemberWithId>(path);
  }

  async setGroupMember(group_id: string, userid: string, member_data: GroupMember): Promise<void> {
    const join_timestamp = firebase.firestore.Timestamp.fromDate(new Date());
    member_data.join_timestamp = join_timestamp;

    const path = `group-2/${group_id}/member/${userid}`;
    return this.firestoreService.setObject(path, member_data);
  }

  async removeGroupMember(group_id: string, userid: string): Promise<void> {
    const path = `group-2/${group_id}/member/${userid}`;
    return this.firestoreService.removeObject(path);
  }

  async setGroupMembshipRequest(group_id: string, userid: string, member_data: GroupMember): Promise<void> {
    const request_timestamp = firebase.firestore.Timestamp.fromDate(new Date());
    member_data.request_timestamp = request_timestamp;

    const path = `group-2/${group_id}/memberRequested/${userid}`;
    return this.firestoreService.setObject(path, member_data);
  }

  getGroupMembshipRequestedOnce(group_id: string): Observable<GroupMemberWithId[]> {
    const path = `group-2/${group_id}/memberRequested`;
    return this.firestoreService.getSnapshotListOnce<GroupMemberWithId>(path);
  }

  getGroupMembshipRequested$(group_id: string): Observable<GroupMemberWithId[]> {
    const path = `group-2/${group_id}/memberRequested`;
    return this.firestoreService.getSnapshotList<GroupMemberWithId>(path);
  }

  declineGroupMemberRequest(group_id: string, userid: string) {
    const path = `group-2/${group_id}/memberRequested/${userid}`;
    return this.firestoreService.removeObject(path);
  }

  approveGroupMemberRequest(group_id: string, userid: string, member_data: GroupMember) {
    const member_request_delete_obj: BatchUnit = {
      path: [
        { collection_path: 'group-2', doc_id: group_id },
        { collection_path: 'memberRequested', doc_id: userid },
      ],
    };

    const join_timestamp = firebase.firestore.Timestamp.fromDate(new Date());
    member_data.join_timestamp = join_timestamp;

    const member_set_obj: BatchUnit = {
      path: [
        { collection_path: 'group-2', doc_id: group_id },
        { collection_path: 'member', doc_id: userid },
      ],
      obj: member_data,
    };

    const batch_obj: BatchObject = {
      deleteArr: [member_request_delete_obj],
      setArr: [member_set_obj],
      updateArr: [],
    };

    return this.firestorePureService
      .batch_common_obj(batch_obj)
      .then(() => {
        console.log('approveGroupMemberRequest succeed');
      })
      .catch((error: Error) => {
        console.log(`fail  approveGroupMemberRequest ${error.name} - ${error.message}`);
      });
  }

  getGroupMembersAdministrator$(group_id: string): Observable<GroupMemberWithId[]> {
    const path = `group-2/${group_id}/administrator`;
    return this.firestoreService.getSnapshotList<GroupMemberWithId>(path);
  }
  getGroupMembersAdministratorOnce(group_id: string): Observable<GroupMemberWithId[]> {
    const path = `group-2/${group_id}/administrator`;
    return this.firestoreService.getSnapshotListOnce<GroupMemberWithId>(path);
  }
  async setGroupMemberAdministrator(group_id: string, userid: string, member_data: GroupMember): Promise<void> {
    const path = `group-2/${group_id}/administrator/${userid}`;
    return this.firestoreService.setObject(path, member_data);
  }

  async removeGroupMemberAdministrator(group_id: string, userid: string): Promise<void> {
    const path = `group-2/${group_id}/administrator/${userid}`;
    return this.firestoreService.removeObject(path);
  }

  getGroupMembersCreatorOnce(group_id: string): Observable<GroupMemberWithId[]> {
    const path = `group-2/${group_id}/creator`;
    return this.firestoreService.getSnapshotListOnce<GroupMemberWithId>(path);
  }

  getGroupMembersCreator$(group_id: string): Observable<GroupMemberWithId[]> {
    const path = `group-2/${group_id}/creator`;
    return this.firestoreService.getSnapshotList<GroupMemberWithId>(path);
  }

  async setGroupMemberCreator(group_id: string, userid: string, member_data: GroupMember): Promise<void> {
    const path = `group-2/${group_id}/creator/${userid}`;
    return this.firestoreService.setObject(path, member_data);
  }

  async reflectGroupMember(group_id: string) {
    const path = `group-member-updated/${group_id}`;
    return this.firestoreService.setObject(path, { lastUpdated: new Date() });
  }

  /*
  async updateGroupMemberSubcollection(group_id: string, userid: string, member_data: GroupMember): Promise<void> {
    const path = `group-2/${group_id}/member/${userid}`;
    return this.firestoreService.updateObject(path, member_data);
  }

  async removeGroupMemberSubcollection(group_id: string, userid: string): Promise<void> {
    const path = `group-2/${group_id}/member/${userid}`;
    return this.firestoreService.removeObject(path);
  }
*/

  searchUser(name_query: string, search_user_number: number): Observable<UserSearchWithId[]> {
    const query_param: QueryParam = {
      where_param: [{ key: `fullname_lowercase`, criteria: '>', value: name_query }],
      orderby_param: { key: 'fullname_lowercase', order: ORDERBY_DIRECTION.ASCENDING },
      startat_param: null,
      startafter_param: null,
      limit_param: search_user_number,
    };
    const path = `users-search/`;
    return this.firestoreService.getSnapshotListWithQuery<UserSearchWithId>(path, query_param);
  }

  searchTeacher(name_query: string, search_user_number: number): Observable<UserSearchWithId[]> {
    const query_param: QueryParam = {
      where_param: [{ key: `fullname_lowercase`, criteria: '>', value: name_query }],
      orderby_param: { key: 'fullname_lowercase', order: ORDERBY_DIRECTION.ASCENDING },
      startat_param: null,
      startafter_param: null,
      limit_param: search_user_number,
    };
    const path = 'teachers';
    return this.firestoreService.getSnapshotListWithQuery<UserSearchWithId>(path, query_param);
  }

  async getGoogleProfileOnce(own_userid: string): Promise<any> {
    const path = `users-google-profile/${own_userid}`;
    return this.firestoreService.getObjectValue<GroupData>(path).pipe(take(1)).toPromise();
  }

  async setGoogleProfile(own_userid: string, google_data: any): Promise<void> {
    const path = `users-google-profile/${own_userid}`;
    return this.firestoreService.setObject(path, google_data);
  }

  getLessonListByCourseId(course_id: string): Observable<LessonDataTimeDateConvertedWithId[]> {
    const query_param: QueryParam = {
      orderby_param: { key: 'start_timestamp', order: 'asc' },
      startafter_param: null,
      startat_param: null,
      where_param: [{ key: `course_id`, criteria: '==', value: course_id }],
    };
    return this.firestoreService
      .getSnapshotListWithQuery<LessonDataWithId>('lessons', query_param)
      .pipe(map(firebaseTimestampDateArrMapping));
  }

  getOwnLessonList$(own_userid: string, date_from: Date): Observable<LessonDataTimeDateConvertedWithId[]> {
    const timestamp_from = firebase.firestore.Timestamp.fromDate(date_from);

    const query_param: QueryParam = {
      orderby_param: { key: 'start_timestamp', order: ORDERBY_DIRECTION.ASCENDING },
      startafter_param: null,
      startat_param: null,
      where_param: [
        { key: `start_timestamp`, criteria: '>', value: timestamp_from },
        { key: `attend_all_input_users`, criteria: 'array-contains', value: own_userid },
      ],
    };
    return this.firestoreService
      .getSnapshotListWithQuery<LessonDataWithId>('lessons', query_param)
      .pipe(map(firebaseTimestampDateArrMapping), take(1));
  }

  getLessonOnce(lesson_id: string): Observable<LessonDataTimeDateConverted> {
    const path = `lessons/${lesson_id}/`;
    return this.firestoreService.getObjectValueOnce<LessonData>(path).pipe(map(firebaseTimestampDateMapping));
  }

  addCourse(data: CourseData): Promise<string> {
    const first_lesson_timestamp = firebase.firestore.Timestamp.fromDate(data.first_lesson_date);
    const last_lesson_timestamp = firebase.firestore.Timestamp.fromDate(data.last_lesson_date);
    data.first_lesson_timestamp = first_lesson_timestamp;
    data.last_lesson_timestamp = last_lesson_timestamp;
    const path = `courses/`;
    return this.firestoreService.pushList(path, data);
  }

  getCourseListBySchoolId(school_id: string, course_status?: COURSE_STATUS): Observable<CourseDataWithId[]> {
    const query_param: QueryParam = {
      orderby_param: { key: 'first_lesson_date', order: ORDERBY_DIRECTION.DESCENDING },
      startafter_param: null,
      startat_param: null,
      where_param: [
        { key: `school_id`, criteria: '==', value: school_id },
        { key: `course_status`, criteria: '==', value: course_status },
      ],
    };
    const path = `courses/`;
    return this.firestoreService.getSnapshotListWithQuery<CourseDataWithId>(path, query_param);
  }

  getCourse(course_id: string): Observable<CourseData | undefined> {
    const path = `courses/${course_id}`;
    return this.firestoreService.getObjectValue(path);
  }

  updateParticalCourseById(course_id: string, course_data: Partial<CourseData>): Promise<void> {
    const path = `courses/${course_id}`;
    return this.firestoreService.updateObject(path, course_data);
  }

  set_lesson_temporal_reservation_comment(comment: string, own_userid: string, course_id: string, own_full_name: string) {
    const data: any = {};
    const comment_obj = {
      comment,
      set_date: firebase.firestore.Timestamp.fromDate(new Date()),
    };
    data[Date.now()] = comment_obj;
    data.full_name = own_full_name;

    const path = `courses/${course_id}/participate_comment/${own_userid}`;
    return this.firestoreService.setMergeObject(path, data);
  }

  set_lesson_temporal_reservation2(lesson_with_intension_list: LessonDataWithIdWithMyIntension[], own_userid: string, course_id: string) {
    const setArr: BatchUnit[] = [];
    lesson_with_intension_list.forEach((lesson_with_intension: LessonDataWithIdWithMyIntension) => {
      const set_path_arr: BatchPath[] = [
        {
          collection_path: `lessons/${lesson_with_intension.id}/participant_intension/`,
          doc_id: own_userid,
        },
      ];
      const intension: LessonParticipantIntensionSubcollectionData = {
        intension: lesson_with_intension.my_intension,
        set_date: firebase.firestore.Timestamp.fromDate(new Date()),
        course_id,
      };

      const set_unit: BatchUnit = {
        path: set_path_arr,
        obj: intension,
      };
      setArr.push(set_unit);
    });
    const batch_obj: BatchObject = { deleteArr: [], updateArr: [], setArr };

    return this.firestorePureService
      .batch_common_obj(batch_obj)
      .then(() => {
        console.log('adding all participant_intension succeed');
      })
      .catch((error) => {
        console.error(error);
      });
  }

  addLessonList(lessons: LessonDataForBatchFunc[]): Promise<void> {
    const converted_lessons = lessons.map((lesson: LessonDataForBatchFunc) => {
      return Object.assign(lesson, { start_timestamp: firebase.firestore.Timestamp.fromDate(lesson.start_date) });
    });

    const setArr: BatchUnit[] = [];
    converted_lessons.forEach((lesson_data: LessonData) => {
      const set_path_arr: BatchPath[] = [
        {
          collection_path: 'lessons',
          doc_id: null,
        },
      ];
      const set_unit: BatchUnit = {
        path: set_path_arr,
        obj: lesson_data,
      };
      setArr.push(set_unit);
    });
    const batch_obj: BatchObject = { deleteArr: [], updateArr: [], setArr };

    return this.firestorePureService
      .batch_common_obj(batch_obj)
      .then(() => {
        console.log('adding all lesson succeed');
      })
      .catch((error) => {
        console.error(error);
      });
  }

  removeLessonList(lesson_ids: string[]) {
    const deleteArr: BatchUnit[] = [];
    lesson_ids.forEach((doc_id) => {
      const delete_path_arr: BatchPath[] = [
        {
          collection_path: 'lessons',
          doc_id,
        },
      ];
      const delete_unit: BatchUnit = {
        path: delete_path_arr,
      };
      deleteArr.push(delete_unit);
    });
    const batch_obj: BatchObject = { deleteArr, updateArr: [], setArr: [] };

    return this.firestorePureService
      .batch_common_obj(batch_obj)
      .then(() => {
        console.log('removing all lessons succeed');
      })
      .catch((error) => {
        console.error(error);
      });
  }

  updateLessonList(lessons: LessonDataForBatchFunc[]): Promise<void> {
    const converted_lessons = lessons.map((lesson: LessonDataForBatchFunc) => {
      return Object.assign(lesson, { start_timestamp: firebase.firestore.Timestamp.fromDate(lesson.start_date) });
    });
    const updateArr: BatchUnit[] = [];
    converted_lessons.forEach((lesson) => {
      const update_path_arr: BatchPath[] = [
        {
          collection_path: 'lessons',
          doc_id: lesson.id,
        },
      ];
      const update_unit: BatchUnit = {
        path: update_path_arr,
        obj: lesson,
      };
      updateArr.push(update_unit);
    });
    const batch_obj: BatchObject = { deleteArr: [], updateArr, setArr: [] };

    return this.firestorePureService
      .batch_common_obj(batch_obj)
      .then(() => {
        console.log('updating all lessons succeed');
      })
      .catch((error) => {
        console.error(error);
      });
  }

  updatePartialLessonById(lessson_id: string, lesson: Partial<LessonData>) {
    const path = `lessons/${lessson_id}`;
    return this.firestoreService.updateObject(path, lesson);
  }

  addSchool(data: SchoolData): Promise<string> {
    const path = `schools/`;
    return this.firestoreService.pushList(path, data);
  }

  getSchoolList(): Observable<SchoolDataWithId[]> {
    return this.firestoreService.getSnapshotList('schools');
  }

  getSchool(school_id: string): Observable<SchoolData | undefined> {
    const path = `schools/${school_id}`;
    return this.firestoreService.getObjectValueOnce(path);
  }

  getMySchoolList(own_userid: string): Observable<SchoolDataWithId[]> {
    const query_param: QueryParam = {
      orderby_param: null,
      startafter_param: null,
      startat_param: null,
      where_param: [{ key: `school_all_members_list`, criteria: 'array-contains', value: own_userid }],
    };
    return this.firestoreService.getSnapshotListWithQuery<SchoolDataWithId>('schools', query_param).pipe(take(1));
  }

  getUsersRecordFirebase(user_id: string): Observable<UsersRecordFirebase | undefined> {
    const path = `users-record-firebase/${user_id}`;
    return this.firestoreService.getObjectValue(path);
  }

  updateTeacherById(teacher_id: string, data: Partial<TeacherData>): Promise<void> {
    const path = `teachers/${teacher_id}`;
    return this.firestoreService.updateObject(path, data);
  }

  addTeacherToSchool(school_id: string, teacher_userid: string, setted_userid: string): Promise<void> {
    const path = `schools/${school_id}/teachers/${teacher_userid}`;
    const data = {
      set_by: setted_userid,
      set_date: firebase.firestore.Timestamp.fromDate(new Date()),
    };

    return this.firestoreService.setObject(path, data);
  }

  getSchoolTeachers(school_id): Observable<SchoolDataWithId[]> {
    const path = `schools/${school_id}/teachers/`;
    return this.firestoreService.getSnapshotList(path);
  }

  getStudentsInfo(user_id): Observable<{ user_id: string; school_app_info_obj: { [key: string]: SchoolApplicantInfo } }> {
    const path = `students_info/${user_id}/`;
    return this.firestoreService.getObjectValueOnce<{ [key: string]: SchoolApplicantInfo }>(path).pipe(
      map((school_app_info_obj) => {
        return { user_id, school_app_info_obj };
      }),
    );
  }

  getSchoolStudents(school_id): Observable<SchoolApplicantInfo[]> {
    const path = `schools/${school_id}/students/`;
    return this.firestoreService.getSnapshotList(path);
  }

  getStudentFromSchoolOnce(school_id: string, userid: string): Observable<SchoolApplicantInfo> {
    const path = `schools/${school_id}/students/${userid}`;
    return this.firestoreService.getObjectValueOnce<SchoolApplicantInfo>(path);
  }

  addStudentToSchool(school_id: string, userid: string, application_data: SchoolApplicantInfo) {
    const path = `schools/${school_id}/students/${userid}`;
    application_data.added_timestamp = firebase.firestore.Timestamp.fromDate(new Date());
    return this.firestoreService.setObject(path, application_data);
  }

  updateStudentInSchool(school_id: string, userid: string, application_data: Partial<SchoolApplicantInfo>) {
    const path = `schools/${school_id}/students/${userid}`;
    return this.firestoreService.updateObject(path, application_data);
  }

  addTeacher(own_userid: string, teacher_data: TeacherData): Promise<void> {
    const path = `teachers/${own_userid}`;
    return this.firestoreService.setObject(path, teacher_data);
  }

  getTeacher(user_id: string): Observable<TeacherData | undefined> {
    const path = `teachers/${user_id}`;
    return this.firestoreService.getObjectValue(path);
  }

  getTeacherList(): Observable<TeadherDataWithId[]> {
    return this.firestoreService.getSnapshotList('teachers');
  }

  setTeacherAdmin(own_userid: string, data: TeacherAdminData): Promise<void> {
    const path = `teacher-admin/${own_userid}`;
    return this.firestoreService.setObject(path, data);
  }
  getTeacherAdmin(own_userid: string): Observable<TeacherAdminData | undefined> {
    const path = `teacher-admin/${own_userid}`;
    return this.firestoreService.getObjectValue(path);
  }

  storeTabbycatInputTemp(event_id: string, data: TabbycatInput): Promise<void> {
    const path = `tabbycat-temp/${event_id}`;
    return this.firestoreService.setObject(path, data);
  }

  addWork(data: Partial<StudyWork>): Promise<string> {
    const path = `study-work`;
    const firebase_date = firebase.firestore.Timestamp.fromDate(new Date());
    data.added_timestamp = firebase_date;
    data.updated_timestamp = firebase_date;

    return this.firestoreService.pushList(path, data);
  }

  getAllWorkList(): Observable<StudyWork[]> {
    const path = `study-work`;

    const query_param: QueryParam = {
      orderby_param: { key: 'updated_timestamp', order: ORDERBY_DIRECTION.DESCENDING },
      startafter_param: null,
      startat_param: null,
      where_param: [],
    };

    return this.firestoreService.getSnapshotListWithQuery<StudyWork>(path, query_param).pipe(map(firebaseTimestampDateArrMappingStudy));
  }

  getMyWorkList(own_user_id): Observable<StudyWork[]> {
    const query_param: QueryParam = {
      orderby_param: { key: 'updated_timestamp', order: ORDERBY_DIRECTION.DESCENDING },
      startafter_param: null,
      startat_param: null,
      where_param: [{ key: `user_id`, criteria: '==', value: own_user_id }],
    };
    const path = `study-work`;
    return this.firestoreService.getSnapshotListWithQuery<StudyWork>(path, query_param).pipe(map(firebaseTimestampDateArrMappingStudy));
  }

  getAllWorkListAssignedAsCorrector(user_id) {
    const query_param: QueryParam = {
      orderby_param: { key: 'updated_timestamp', order: ORDERBY_DIRECTION.DESCENDING },
      startafter_param: null,
      startat_param: null,
      where_param: [{ key: `assigned_corrector`, criteria: 'array-contains', value: user_id }],
    };
    const path = `study-work`;
    return this.firestoreService.getSnapshotListWithQuery<StudyWork>(path, query_param).pipe(map(firebaseTimestampDateArrMappingStudy));
  }

  getWork(work_id: string): Observable<StudyWork> {
    const path = `study-work/${work_id}`;
    return this.firestoreService.getObjectValue(path);
  }

  updateWork(work_id, data: Partial<StudyWork>): Promise<void> {
    const path = `study-work/${work_id}`;
    return this.firestoreService.updateObject(path, data);
  }

  getStudyContent(content_id: string): Observable<StudyContent> {
    const path = `study-content/${content_id}`;
    return this.firestoreService.getObjectValue(path);
  }

  getStudyContentArr(content_group_id = null): Observable<StudyContent[]> {
    const query_param: QueryParam = {
      orderby_param: { key: 'updated_timestamp', order: ORDERBY_DIRECTION.ASCENDING },
      startafter_param: null,
      startat_param: null,
      where_param: [{ key: `content_group_id`, criteria: '==', value: content_group_id }],
    };
    const path = `study-content/`;
    return this.firestoreService.getSnapshotListWithQuery<StudyContent>(path, query_param).pipe(map(firebaseTimestampDateArrMappingStudy));
  }

  addStudyContent(content: StudyContent) {
    const added_timestamp = firebase.firestore.Timestamp.fromDate(new Date());
    const updated_timestamp = firebase.firestore.Timestamp.fromDate(new Date());
    content.added_timestamp = added_timestamp;
    content.updated_timestamp = updated_timestamp;

    const path = `study-content/`;
    return this.firestoreService.pushList(path, content);
  }

  updateStudyContent(content_id: string, content: StudyContent) {
    const updated_timestamp = firebase.firestore.Timestamp.fromDate(new Date());
    content.updated_timestamp = updated_timestamp;
    const path = `study-content/${content_id}`;
    return this.firestoreService.updateObject(path, content);
  }

  addCorrectorToWork(work_id, userids: string[]) {
    const obj: Partial<StudyWork> = { assigned_corrector: firebase.firestore.FieldValue.arrayUnion(...userids) };
    this.updateWork(work_id, obj);
  }

  correctionDone(work_id, userid: string) {
    const firebase_date = firebase.firestore.Timestamp.fromDate(new Date());
    const obj: Partial<StudyWork> = {
      correction_done: true,
      correction_done_by: firebase.firestore.FieldValue.arrayUnion(userid),
      correction_done_timestamp: firebase_date,
    };
    this.updateWork(work_id, obj);
  }

  addStudyCorrection(data: StudyCorrection): Promise<string> {
    const path = `study-correction`;
    const firebase_date = firebase.firestore.Timestamp.fromDate(new Date());
    data.added_timestamp = firebase_date;
    data.updated_timestamp = firebase_date;
    return this.firestoreService.pushList(path, data);
  }

  updateStudyCorrection(correction_id: string, data: StudyCorrection) {
    const path = `study-correction/${correction_id}`;
    const firebase_date = firebase.firestore.Timestamp.fromDate(new Date());
    data.updated_timestamp = firebase_date;
    return this.firestoreService.updateObject(path, data);
  }

  getStudyCorrectionList(associated_study_work_id: string): Observable<StudyCorrection[]> {
    const query_param: QueryParam = {
      orderby_param: { key: 'updated_timestamp', order: ORDERBY_DIRECTION.DESCENDING },
      startafter_param: null,
      startat_param: null,
      where_param: [{ key: `associated_study_work_id`, criteria: '==', value: associated_study_work_id }],
    };
    const path = `study-correction`;
    return this.firestoreService
      .getSnapshotListWithQuery<StudyCorrection>(path, query_param)
      .pipe(map(firebaseTimestampDateArrMappingStudy));
  }

  getUserPaymentRegistration(userid: string): Observable<PriceUserPaymentStatusVm[]> {
    const query_param: QueryParam = {
      orderby_param: null,
      startafter_param: null,
      startat_param: null,
      where_param: [{ key: `userid`, criteria: '==', value: userid }],
    };
    const path = `stripe-price-user-payment-status`;
    return this.firestoreService
      .getSnapshotListWithQuery<PriceUserPaymentStatus>(path, query_param)
      .pipe(map(firebaseTimestampDateArrMappingStripe));
  }

  getPricePaymentStatusList(price_id = null): Observable<PriceUserPaymentStatusVm[]> {
    const where_param = [];
    if (price_id) {
      where_param.push({ key: `price_id`, criteria: '==', value: price_id });
    }

    const query_param: QueryParam = {
      orderby_param: null,
      startafter_param: null,
      startat_param: null,
      where_param,
    };
    const path = `stripe-price-user-payment-status`;
    return this.firestoreService
      .getSnapshotListWithQuery<PriceUserPaymentStatus>(path, query_param)
      .pipe(map(firebaseTimestampDateArrMappingStripe));
  }

  getPaymentHistoryList(price_id = null, userid = null): Observable<PricePaymentHistory[]> {
    const where_param = [];
    if (price_id) {
      where_param.push({ key: `price_id`, criteria: '==', value: price_id });
    }
    if (userid) {
      where_param.push({ key: `userid`, criteria: '==', value: userid });
    }

    const query_param: QueryParam = {
      orderby_param: null,
      startafter_param: null,
      startat_param: null,
      where_param,
    };
    const path = `stripe-price-payment-history`;
    return this.firestoreService
      .getSnapshotListWithQuery<PricePaymentHistory>(path, query_param)
      .pipe(map(firebaseTimestampDateArrMappingStripe));
  }

  getStripeCustomer(user_id): Observable<StripeUser> {
    const path = `user-stripe-customer/${user_id}`;
    return this.firestoreService.getObjectValueOnce(path);
  }

  saveCardHolderName(userid, card_holder_name) {
    const path = `user-stripe-customer/${userid}`;
    const data = { card_holder_name };
    return this.firestoreService.updateObject(path, data);
  }
}

function firebaseTimestampDateArrMappingStripe<T extends WithTimestampPayment>(data_arr: T[]) {
  return data_arr.map((data: T) => {
    const converted_data: T = data;
    if (data && data.cancel_at_timestamp) {
      converted_data.cancel_at_date = data.cancel_at_timestamp.toDate();
    }
    if (data && data.period_start_timestamp) {
      converted_data.period_start_date = data.period_start_timestamp.toDate();
    }
    if (data && data.period_end_timestamp) {
      converted_data.period_end_date = data.period_end_timestamp.toDate();
    }
    if (data && data.updated_timestamp) {
      converted_data.updated_date = data.updated_timestamp.toDate();
    }
    return converted_data;
  });
}

function firebaseTimestampDateArrMappingStudy<T extends WithTimestampStudy>(data_arr: T[]) {
  return data_arr.map((data: T) => {
    if (data && data.added_timestamp) {
      data.added_date = data.added_timestamp.toDate();
    }
    if (data && data.updated_timestamp) {
      data.updated_date = data.updated_timestamp.toDate();
    }
    if (data && data.correction_done_timestamp) {
      data.correction_done_date = data.correction_done_timestamp.toDate();
    }
    return data;
  });
}

function firebaseTimestampDateArrMapping<T extends WithTimestamp>(event_arr: T[]) {
  return event_arr.map((data: T) => {
    if (data && data.start_timestamp) {
      return Object.assign(data, { start_date: data.start_timestamp.toDate() });
    } else {
      return data;
    }
  });
}

function groupFirebaseTimestampDateArrMapping(group_arr: GroupDataWithId[]): GroupDataWithId[] {
  return group_arr.map((data: GroupDataWithId) => {
    if (data.eventlist) {
      const eventlist = data.eventlist.map(firebaseTimestampDateMapping);
      return Object.assign(data, { eventlist: eventlist });
    } else {
      return data;
    }
  });
}

function firebaseTimestampDateMapping<T extends WithTimestamp>(event: T) {
  // return Object.assign(event, { start_datetime: new Date(event.start_datetime.seconds * 1000) });
  if (event && event.start_timestamp) {
    return Object.assign(event, { start_date: event.start_timestamp.toDate() });
  } else {
    return event;
  }
}

function firebaseTimepDateMapping<T extends WithTime>(data: T) {
  // return Object.assign(event, { start_datetime: new Date(event.start_datetime.seconds * 1000) });
  if (data && data.time) {
    return Object.assign(data, { date: data.time.toDate() });
  } else {
    return data;
  }
}

function firebaseTimestampArrMapping<T extends TimelineBaseDb>(comment_arr: T[]): T[] {
  return comment_arr.map((data: T) => {
    let time_added_date = null;
    let time_updated_date = null;
    if (data.time_added_timestamp) {
      time_added_date = data.time_added_timestamp.toDate();
    }
    if (data.time_updated_timestamp) {
      time_updated_date = data.time_updated_timestamp.toDate();
    }
    return Object.assign(data, { time_added_date }, { time_updated_date });
  });
}

function firebaseLastSubcommentTimestampMapping(comment_arr: MainCommentWithId[]): MainCommentWithId[] {
  return comment_arr.map((data: MainCommentWithId) => {
    if (data.last_subcomment && data.last_subcomment.time_added_timestamp) {
      const obj = Object.assign(data.last_subcomment, {
        time_added_date: data.last_subcomment.time_added_timestamp.toDate(),
      });
      return Object.assign(data, { last_subcomment: obj });
    } else {
      return data;
    }
  });
}

function eventItemAddOwnAvailabilityWithoutUser(event_item: EventDataWithLectureTimeDateConvertedWithId): EventDataWithLectureVMwithId {
  if (event_item.application_qualification === EVENT_APPLICATION_QUALIFICATION.ANYONE) {
    return Object.assign(event_item, { is_event_owner: false, have_join_qualification: true, own_user_id: null });
  } else {
    return Object.assign(event_item, { is_event_owner: false, have_join_qualification: false, own_user_id: null });
  }
}

function eventItemAddOwnAvailability(
  my_group_list: GroupDataWithId[],
  event_item: EventDataWithLectureTimeDateConvertedWithId,
  own_user_id: string,
  my_school_list: SchoolDataWithId[],
): EventDataWithLectureVMwithId {
  event_item = event_item || {};

  let is_event_owner = false;
  if (own_user_id && event_item.created_by === own_user_id) {
    is_event_owner = true;
  }

  let assigned = false;

  if (
    (event_item.participants_invited && event_item.participants_invited.indexOf(own_user_id) !== -1) ||
    event_item.created_by === own_user_id ||
    (event_item.participants_going && event_item.participants_going.indexOf(own_user_id) !== -1)
  ) {
    assigned = true;
  }

  let have_join_qualification = false;

  switch (event_item.application_qualification) {
    case EVENT_APPLICATION_QUALIFICATION.ANYONE:
      have_join_qualification = true;
      return Object.assign(event_item, { is_event_owner }, { have_join_qualification }, { own_user_id: own_user_id }, { assigned });
      break;

    case EVENT_APPLICATION_QUALIFICATION.INVITED:
      if (
        (event_item.participants_invited && event_item.participants_invited.indexOf(own_user_id) !== -1) ||
        event_item.created_by === own_user_id ||
        (event_item.participants_going && event_item.participants_going.indexOf(own_user_id) !== -1) ||
        (event_item.participants_cannotgo && event_item.participants_cannotgo.indexOf(own_user_id) !== -1)
      ) {
        have_join_qualification = true;
      }
      return Object.assign(event_item, { is_event_owner }, { have_join_qualification }, { own_user_id: own_user_id }, { assigned });
      break;

    case EVENT_APPLICATION_QUALIFICATION.GROUP_MEMBER:
      if (event_item.associated_group && own_user_id) {
        const event_group_id = event_item.associated_group.id;
        my_group_list.forEach((group: GroupDataWithId) => {
          if (group.id === event_group_id) {
            have_join_qualification = true;
          }
        });
        // even if user is not group member, invited people can still join.
        if (
          (event_item.participants_invited && event_item.participants_invited.indexOf(own_user_id) !== -1) ||
          event_item.created_by === own_user_id ||
          (event_item.participants_going && event_item.participants_going.indexOf(own_user_id) !== -1) ||
          (event_item.participants_cannotgo && event_item.participants_cannotgo.indexOf(own_user_id) !== -1)
        ) {
          have_join_qualification = true;
        }
        return Object.assign(event_item, { is_event_owner }, { have_join_qualification }, { own_user_id: own_user_id }, { assigned });
      } else {
        return Object.assign(
          event_item,
          { is_event_owner },
          { have_join_qualification: false },
          { own_user_id: own_user_id },
          { assigned },
        );
      }
      break;
    case EVENT_APPLICATION_QUALIFICATION.SCHOOL_MEMBER:
      const event_lecture_item = event_item as EventDataWithLecture;
      if (event_lecture_item.school_id && own_user_id) {
        if (my_school_list) {
          const my_schoollist_ids = my_school_list.map((school) => {
            return school.id;
          });
          if (my_schoollist_ids.includes(event_lecture_item.school_id)) {
            have_join_qualification = true;
          }
        }
        if (
          (event_item.participants_invited && event_item.participants_invited.indexOf(own_user_id) !== -1) ||
          event_item.created_by === own_user_id ||
          (event_item.participants_going && event_item.participants_going.indexOf(own_user_id) !== -1) ||
          (event_item.participants_cannotgo && event_item.participants_cannotgo.indexOf(own_user_id) !== -1)
        ) {
          have_join_qualification = true;
        }
        return Object.assign(event_item, { is_event_owner }, { have_join_qualification }, { own_user_id: own_user_id }, { assigned });
      }
      return Object.assign(event_item, { is_event_owner }, { have_join_qualification: false }, { own_user_id: own_user_id }, { assigned });
      break;
  }
  return Object.assign(event_item, { is_event_owner }, { have_join_qualification }, { own_user_id: own_user_id }, { assigned });
}
