import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LoggerService, LogParam, LOG_LEVEL, LOG_TECH, LOG_MODULE, LOG_TYPE } from '@mixidea-client/logger';

import { environment } from './../../../environments/environment';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';

const timeerror_correction_url_ec2 = environment.timeerror_correction_url_ec2;
const timeerror_correction_url_cloudfunction = environment.timeerror_correction_url_cloudfunction;

@Injectable()
export class TimeerrorCorrectionService {
  timeerror; // msec

  constructor(private http: HttpClient, private logger: LoggerService) {
    window.addEventListener('focus', () => {
      this.update_timeerror();
    });
  }

  update_timeerror() {
    this.http.get(timeerror_correction_url_ec2).subscribe(
      (response) => {
        this.timeerror = Number(response) - Date.now();

        const log_obj: LogParam = {
          message: `timeerror: ${this.timeerror}  servertime:  ${response}`,
          level: LOG_LEVEL.INFO,
          type: LOG_TYPE.Keypoint,
          file_name: 'timeerror-correction.service',
          module: LOG_MODULE.LiveVideoDebate,
          tech: LOG_TECH.RXJS,
        };
        this.logger.write(log_obj);
      },
      () => {
        this.retry_update_timeerror();
      },
    );
  }

  retry_update_timeerror() {
    this.http.get(timeerror_correction_url_cloudfunction).subscribe((response) => {
      this.timeerror = Number(response) - Date.now();

      const log_obj: LogParam = {
        message: `timeerror-retry: ${this.timeerror}  servertime:  ${response}`,
        level: LOG_LEVEL.INFO,
        type: LOG_TYPE.Keypoint,
        file_name: 'timeerror-correction.service',
        module: LOG_MODULE.LiveVideoDebate,
        tech: LOG_TECH.RXJS,
      };
      this.logger.write(log_obj);
    });
  }

  get_current_time$(): Observable<number> {
    // return Date.now() + this.timeerror;

    if (this.timeerror) {
      return of(Date.now() + this.timeerror);
    }

    return this.http.get(timeerror_correction_url_cloudfunction).pipe(
      map((server_time: number) => {
        this.timeerror = Number(server_time) - Date.now();
        console.log('%ctime error', 'color:blue');
        console.log(this.timeerror);
        return Date.now() + this.timeerror;
      }),
    );
  }

  get_current_time() {
    if (this.timeerror) {
      return Date.now() + this.timeerror;
    }
    return Date.now();
  }
}
