import { TEAM_NAME } from '../constant/team-enum';
import { DEBATE_STYLE } from './../constant/deb-style';
import { SIDE } from '../constant/side';
import { LAYOUT_TYPE_IN_AUDITORIUM_ID } from './firebase-room-user';
import { LAYOUT_TYPE } from '../constant/layout';

/*   under event_related/auditrium   data is used in the main auditrium window */
export interface AuditriumInfo {
  // allocation_open?: boolean;
  allocation_rounds_infos?: AllocationRoundInfoS;
  motion_only_by_organizer?: boolean;
  allocation_only_by_organizer?: boolean;
  remove_previous_round_data?: boolean;
  // exist_organizer?: boolean;
  organizers?: string[];
  presentation_control_only_organizer?: boolean;
  use_preparation_ready_status?: boolean;
  additional_organizer_room?: boolean;
  // presentation_room_in_auditorium?: boolean;
  presentation_availabilty?: { [room_num_in_auditrium: string]: boolean };
}

export interface AllocationRoundInfoS {
  [latyout_type_id: string]: AllocationRoundInfo;
}

export interface AllocationRoundInfo {
  allocated_rooms_in_round: AllocationRoomInfoS;
  room_num: number;
}
// room_id: `${layout_type_id}_${room_num_in_auditrium}`
export interface AllocationRoomInfoS {
  [room_id: string]: AllocationRoomInfo;
}

export interface AllocationRoomInfo {
  room_team_users?: AuditriumAllocatedUserInTeamDb;
  room_num_in_auditrium?: number;
  layout_type_id?: LAYOUT_TYPE_IN_AUDITORIUM_ID;
  room_id?: string;
  deb_style: DEBATE_STYLE;
}

export interface AllocationRoomInfoVm extends AllocationRoomInfo {
  room_team_users_vm: AuditriumAllocatedUserInTeamVm;
  is_own_room?: boolean;
  own_allocation_team: ALLOCATION_TEAM_NAME;
}

export interface AllocationRoomInfoVmArrAndAdmininfo {
  allocated_room_info_arr: AllocationRoomInfoVm[];
  presentation_info_arr: PresenRoomInfo[];
  motion_only_by_organizer: boolean;
  allocation_only_by_organizer: boolean;
  remove_previous_round_data?: boolean;
  is_organizer_yourself: boolean;
  exist_organizer?: boolean;
  presentation_control_only_organizer?: boolean;
  use_preparation_ready_status?: boolean;
  additional_organizer_room?: boolean;
  // presentation_room_in_auditorium?: boolean;
}

export interface PreviousRoundDataForRemove {
  layout_type_id: LAYOUT_TYPE_IN_AUDITORIUM_ID;
  room_num: number;
  time: number;
}

export interface MoveParticipantsData {
  from: { layout_type_id: LAYOUT_TYPE_IN_AUDITORIUM_ID; room_num: number };
  to: { layout_type: LAYOUT_TYPE };
  time: number;
}

export interface AuditriumAllocatedUserInTeamVm {
  [team_name: string]: AllocatedUserInTeamVm;
}

export interface AllocatedUserInTeamVm {
  users: string[];
  team_showname: string;
  registered_team_name?: string;
}

export interface AuditriumAllocatedUserInTeamDb {
  [team_name: string]: {
    user: UserDb;
    registered_team_name?: string;
  };
}

/*   under event_related/auditrium_allocation_temporal */

export interface AuditriumAllocationTemporalInfo {
  all_participants?: string[];
  default_deb_style?: DEBATE_STYLE;
  eachroom_debstyle?: { [room_id: string]: DEBATE_STYLE };
  layout_type_id?: LAYOUT_TYPE_IN_AUDITORIUM_ID;
  max_number_room_num_in_auditrium?: number;
  process?: ALLOCATION_PROCESS;
}

export enum ALLOCATION_PROCESS {
  ROOM_DEB_STYLE = 'ROOM_DEB_STYLE',
  USER_ROOM = 'USER_ROOM',
}

/*   under event_related/auditrium_allocation_temporal_room_users */

export interface AllAuditriumAllocateUserDb {
  [layout_type_id: string]: RoundAuditriumAllocateUserDb;
}

export interface RoundAuditriumAllocateUserDb {
  [room_id: string]: AuditriumAllocatedUserInRoomTeamDb;
}

// room_id: `${layout_type_id}_${room_num_in_auditrium}`
export interface RoundAuditriumAllocateUserVm {
  [room_id: string]: AuditriumAllocatedUserInRoomTeamVm;
}
export interface RoundAuditriumAllocateUserVmWithExistence {
  room_users: RoundAuditriumAllocateUserVm;
  exist_user: boolean;
}

export interface AuditriumAllocatedUserInRoomTeamDb {
  [allocation_team_name: string]: {
    allocated_user?: UserDb;
    allocated_team?: RegisteredTeamDb;
  };
}

type userid = string;
export interface AuditriumAllocatedUserInRoomTeamVm {
  [allocation_team_name: string]: AllocationElemenet[];
}

export interface AllAuditriumAllocatationTemporalVm {
  not_allocated: AllocationElemenet[];
  allocated_room_users_vm: { [room_id: string]: AuditriumAllocatedUserInRoomTeamVm };
  exist_user: boolean;
}

export interface OrgnizerMessageSource {
  set_by: string;
  text: string;
  event_id_list: string[];
}

export interface OrganizerMessage {
  html_text: string;
  source: OrgnizerMessageSource;
}

export enum ALLOCATION_TEAM_NAME {
  PROPOSITION = 'PROPOSITION',
  OPPOSITION = 'OPPOSITION',
  OG = 'OG',
  OO = 'OO',
  CG = 'CG',
  CO = 'CO',
  AUDOENCE = 'AUDOENCE',
  JUDGE = 'JUDGE',
}

export const ALLOCATION_TEAM_NAME_SHOWNAME = {
  [DEBATE_STYLE.NA]: {
    [ALLOCATION_TEAM_NAME.PROPOSITION]: 'Gov',
    [ALLOCATION_TEAM_NAME.OPPOSITION]: 'Opp',
    [ALLOCATION_TEAM_NAME.JUDGE]: 'Judge',
  },
  [DEBATE_STYLE.ASIAN]: {
    [ALLOCATION_TEAM_NAME.PROPOSITION]: 'Proposition',
    [ALLOCATION_TEAM_NAME.OPPOSITION]: 'Opposition',
    [ALLOCATION_TEAM_NAME.JUDGE]: 'Judge',
  },
  [DEBATE_STYLE.WSDC]: {
    [ALLOCATION_TEAM_NAME.PROPOSITION]: 'Proposition',
    [ALLOCATION_TEAM_NAME.OPPOSITION]: 'Opposition',
    [ALLOCATION_TEAM_NAME.JUDGE]: 'Judge',
  },
  [DEBATE_STYLE.WSDC_SHORT]: {
    [ALLOCATION_TEAM_NAME.PROPOSITION]: 'Proposition',
    [ALLOCATION_TEAM_NAME.OPPOSITION]: 'Opposition',
    [ALLOCATION_TEAM_NAME.JUDGE]: 'Judge',
  },
  [DEBATE_STYLE.PDA]: {
    [ALLOCATION_TEAM_NAME.PROPOSITION]: 'Gov',
    [ALLOCATION_TEAM_NAME.OPPOSITION]: 'Opp',
    [ALLOCATION_TEAM_NAME.JUDGE]: 'Judge',
  },
  [DEBATE_STYLE.WSC]: {
    [ALLOCATION_TEAM_NAME.PROPOSITION]: 'Aff',
    [ALLOCATION_TEAM_NAME.OPPOSITION]: 'Neg',
    [ALLOCATION_TEAM_NAME.JUDGE]: 'Judge',
  },
  [DEBATE_STYLE.BP]: {
    [ALLOCATION_TEAM_NAME.OG]: 'OG',
    [ALLOCATION_TEAM_NAME.OO]: 'OO',
    [ALLOCATION_TEAM_NAME.CG]: 'CG',
    [ALLOCATION_TEAM_NAME.CO]: 'CO',
    [ALLOCATION_TEAM_NAME.JUDGE]: 'Judge',
  },
  [DEBATE_STYLE.PHS]: {
    [ALLOCATION_TEAM_NAME.PROPOSITION]: 'Proposition',
    [ALLOCATION_TEAM_NAME.OPPOSITION]: 'Opposition',
    [ALLOCATION_TEAM_NAME.JUDGE]: 'Judge',
  },
};

export const MAPPING_ALLOCATION_TEAM_NAME_TEAMNAME_TEMPORAL = {
  [ALLOCATION_TEAM_NAME.PROPOSITION]: 'Gov',
  [ALLOCATION_TEAM_NAME.OPPOSITION]: 'Opp',
  [ALLOCATION_TEAM_NAME.OG]: 'OG',
  [ALLOCATION_TEAM_NAME.OO]: 'OO',
  [ALLOCATION_TEAM_NAME.CG]: 'CG',
  [ALLOCATION_TEAM_NAME.CO]: 'CO',
  [ALLOCATION_TEAM_NAME.PROPOSITION]: 'Prop',
  [ALLOCATION_TEAM_NAME.OPPOSITION]: 'Opp',
  [ALLOCATION_TEAM_NAME.JUDGE]: 'Judge',
};

export const MAPPING_ALLOCATION_TEAM_NAME_SIDE = {
  [ALLOCATION_TEAM_NAME.PROPOSITION]: SIDE.LEFT,
  [ALLOCATION_TEAM_NAME.OPPOSITION]: SIDE.RIGHT,
  [ALLOCATION_TEAM_NAME.OG]: SIDE.LEFT,
  [ALLOCATION_TEAM_NAME.OO]: SIDE.RIGHT,
  [ALLOCATION_TEAM_NAME.CG]: SIDE.LEFT,
  [ALLOCATION_TEAM_NAME.CO]: SIDE.RIGHT,
  // [ALLOCATION_TEAM_NAME.PROPOSITION]: SIDE.LEFT,
  // [ALLOCATION_TEAM_NAME.OPPOSITION]: SIDE.RIGHT,
  [ALLOCATION_TEAM_NAME.JUDGE]: SIDE.MIDDLE,
};

export const MAPPINT_ALLOCATION_TEAM_NAME_TEAMNAME: { [style: string]: { [allocation_team_name: string]: TEAM_NAME } } = {
  [DEBATE_STYLE.NA]: {
    [ALLOCATION_TEAM_NAME.PROPOSITION]: TEAM_NAME.NA_GOV,
    [ALLOCATION_TEAM_NAME.OPPOSITION]: TEAM_NAME.NA_OPP,
    [ALLOCATION_TEAM_NAME.JUDGE]: null,
  },
  [DEBATE_STYLE.ASIAN]: {
    [ALLOCATION_TEAM_NAME.PROPOSITION]: TEAM_NAME.ASIAN_PROPOSITION,
    [ALLOCATION_TEAM_NAME.OPPOSITION]: TEAM_NAME.ASIAN_OPPOSITION,
    [ALLOCATION_TEAM_NAME.JUDGE]: null,
  },
  [DEBATE_STYLE.WSDC]: {
    [ALLOCATION_TEAM_NAME.PROPOSITION]: TEAM_NAME.WSDC_PROPOSITION,
    [ALLOCATION_TEAM_NAME.OPPOSITION]: TEAM_NAME.WSDC_OPPOSITION,
    [ALLOCATION_TEAM_NAME.JUDGE]: null,
  },
  [DEBATE_STYLE.WSDC_SHORT]: {
    [ALLOCATION_TEAM_NAME.PROPOSITION]: TEAM_NAME.WSDC_SHORT_PROPOSITION,
    [ALLOCATION_TEAM_NAME.OPPOSITION]: TEAM_NAME.WSDC_SHORT_OPPOSITION,
    [ALLOCATION_TEAM_NAME.JUDGE]: null,
  },
  [DEBATE_STYLE.PDA]: {
    [ALLOCATION_TEAM_NAME.PROPOSITION]: TEAM_NAME.PDA_GOV,
    [ALLOCATION_TEAM_NAME.OPPOSITION]: TEAM_NAME.PDA_OPP,
    [ALLOCATION_TEAM_NAME.JUDGE]: null,
  },
  [DEBATE_STYLE.WSC]: {
    [ALLOCATION_TEAM_NAME.PROPOSITION]: TEAM_NAME.WSC_GOV,
    [ALLOCATION_TEAM_NAME.OPPOSITION]: TEAM_NAME.WSC_OPP,
    [ALLOCATION_TEAM_NAME.JUDGE]: null,
  },
  [DEBATE_STYLE.BP]: {
    [ALLOCATION_TEAM_NAME.OG]: TEAM_NAME.BP_OG,
    [ALLOCATION_TEAM_NAME.OO]: TEAM_NAME.BP_OO,
    [ALLOCATION_TEAM_NAME.CG]: TEAM_NAME.BP_CG,
    [ALLOCATION_TEAM_NAME.CO]: TEAM_NAME.BP_CO,
    [ALLOCATION_TEAM_NAME.JUDGE]: null,
  },
  [DEBATE_STYLE.PHS]: {
    [ALLOCATION_TEAM_NAME.PROPOSITION]: TEAM_NAME.PHS_PROPOSITION,
    [ALLOCATION_TEAM_NAME.OPPOSITION]: TEAM_NAME.PHS_OPPOSITION,
    [ALLOCATION_TEAM_NAME.JUDGE]: null,
  },
};

export const ALLOCATION_TEAM_IN_DEBSTYLE = {
  [DEBATE_STYLE.NA]: [ALLOCATION_TEAM_NAME.PROPOSITION, ALLOCATION_TEAM_NAME.OPPOSITION, ALLOCATION_TEAM_NAME.JUDGE],
  [DEBATE_STYLE.ASIAN]: [ALLOCATION_TEAM_NAME.PROPOSITION, ALLOCATION_TEAM_NAME.OPPOSITION, ALLOCATION_TEAM_NAME.JUDGE],
  [DEBATE_STYLE.WSDC]: [ALLOCATION_TEAM_NAME.PROPOSITION, ALLOCATION_TEAM_NAME.OPPOSITION, ALLOCATION_TEAM_NAME.JUDGE],
  [DEBATE_STYLE.WSDC_SHORT]: [ALLOCATION_TEAM_NAME.PROPOSITION, ALLOCATION_TEAM_NAME.OPPOSITION, ALLOCATION_TEAM_NAME.JUDGE],
  [DEBATE_STYLE.BP]: [
    ALLOCATION_TEAM_NAME.OG,
    ALLOCATION_TEAM_NAME.OO,
    ALLOCATION_TEAM_NAME.CG,
    ALLOCATION_TEAM_NAME.CO,
    ALLOCATION_TEAM_NAME.JUDGE,
  ],
  [DEBATE_STYLE.PDA]: [ALLOCATION_TEAM_NAME.PROPOSITION, ALLOCATION_TEAM_NAME.OPPOSITION, ALLOCATION_TEAM_NAME.JUDGE],
  [DEBATE_STYLE.WSC]: [ALLOCATION_TEAM_NAME.PROPOSITION, ALLOCATION_TEAM_NAME.OPPOSITION, ALLOCATION_TEAM_NAME.JUDGE],
  [DEBATE_STYLE.PHS]: [ALLOCATION_TEAM_NAME.PROPOSITION, ALLOCATION_TEAM_NAME.OPPOSITION, ALLOCATION_TEAM_NAME.JUDGE],
};

export interface RoomInfo {
  layout_type_id?: LAYOUT_TYPE_IN_AUDITORIUM_ID;
  room_num_in_auditrium?: number;
  room_id?: string; // `${layout_type_id}_${room_num_in_auditrium}`
  deb_style?: DEBATE_STYLE;
}

export interface PresenRoomInfo {
  room_id?: string;
  pdf_ready?: boolean;
  room_num_in_auditrium?: number;
}

export enum ALLOCATION_ELEMENT_TYPE {
  REGISTERED_TEAM = 'REGISTERED_TEAM',
  INDIVIDUAL = 'INDIVIDUAL',
}

export interface AllocationContainerInfo {
  not_allocated?: boolean;
  allocation_room_team?: ALLOCATION_TEAM_NAME;
  room_info?: RoomInfo;
}

/* base type */

export interface UserDb {
  [userid: string]: boolean;
}

export interface RegisteredTeamDb {
  [registeredTeamid: string]: boolean;
}

export enum REGISTERED_ROLE {
  DEBATER = 'DEBATER',
  AUDIENCE = 'AUDIENCE',
  JUDGE = 'JUDGE',
  NO_ROLE = 'NO_ROLE',
}

export interface RegisteredUser {
  userid: string;
  registeredRole: REGISTERED_ROLE;
}

export interface RegisteredTeam {
  teamid: string;
  registeredTeam_name: string;
  registeredMembers: RegisteredUser[];
}

export interface AllocationElemenet {
  element_type?: ALLOCATION_ELEMENT_TYPE;
  registeredTeam?: RegisteredTeam;
  individual_user?: RegisteredUser;
}
