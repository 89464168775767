import { Component, OnInit, Input } from '@angular/core';

import { UserShowBaseComponent } from '../user-show-base/user-show-base.component';

import { USERSHOW_IMAGESHAPE } from '@mixidea-client/model';

@Component({
  selector: 'app-user-show-icon',
  templateUrl: './user-show-icon.component.html',
  styleUrls: ['./user-show-icon.component.scss'],
})
export class UserShowIconComponent extends UserShowBaseComponent {
  @Input()
  set imageshape(imageshape: string) {
    if (imageshape !== USERSHOW_IMAGESHAPE.CIRCLE) {
      this.is_circle = false;
    }
  }
  @Input()
  width;
  @Input()
  height;
  @Input()
  border_color: string;

  box_shadow: string;

  is_circle = true;

  percent100 = false;

  extended_init() {
    if (!this.width && !this.height) {
      this.percent100 = true;
    } else if (!this.width && this.height) {
      this.width = 'auto';
    } else if (this.width && !this.height) {
      this.height = 'auto';
    }

    const borderColor = this.border_color || 'transparent';
    this.box_shadow = `${borderColor} 0 0 0 1px`;
  }
}
