import { TEAM_NAME } from './team-enum';

export enum ROOM_NAME_BASE {
  MAIN = 'MAIN',
  PREP_AUDIENCE = 'PREP_AUDIENCE',
  REFLEC_JUDGE = 'REFLEC_JUDGE',
  GO_AUDITRIUM = 'GO_AUDITRIUM',
  FORCE_GOBACK_AUDITRIUM = 'FORCE_GOBACK_AUDITRIUM',
  AUDITRIUM = 'AUDITRIUM',
  GO_PRESENTATION = 'GO_PRESENTATION',
  PRESENTATION = 'PRESENTATION',
  JISTI = 'JISTI',
}

export type ROOM_NAME_TYPE = TEAM_NAME | ROOM_NAME_BASE;

export const ROOM_NAME: { [room_name: string]: ROOM_NAME_TYPE } = {
  [ROOM_NAME_BASE.MAIN]: ROOM_NAME_BASE.MAIN,
  [ROOM_NAME_BASE.PREP_AUDIENCE]: ROOM_NAME_BASE.PREP_AUDIENCE,
  [ROOM_NAME_BASE.REFLEC_JUDGE]: ROOM_NAME_BASE.REFLEC_JUDGE,
  [ROOM_NAME_BASE.GO_AUDITRIUM]: ROOM_NAME_BASE.GO_AUDITRIUM,
  [ROOM_NAME_BASE.AUDITRIUM]: ROOM_NAME_BASE.AUDITRIUM,
  [ROOM_NAME_BASE.FORCE_GOBACK_AUDITRIUM]: ROOM_NAME_BASE.FORCE_GOBACK_AUDITRIUM,
  [ROOM_NAME_BASE.GO_PRESENTATION]: ROOM_NAME_BASE.GO_PRESENTATION,
  [ROOM_NAME_BASE.PRESENTATION]: ROOM_NAME_BASE.PRESENTATION,
  [ROOM_NAME_BASE.JISTI]: ROOM_NAME_BASE.JISTI,
  [TEAM_NAME.NA_GOV]: TEAM_NAME.NA_GOV,
  [TEAM_NAME.NA_OPP]: TEAM_NAME.NA_OPP,
  [TEAM_NAME.PDA_GOV]: TEAM_NAME.PDA_GOV,
  [TEAM_NAME.PDA_OPP]: TEAM_NAME.PDA_OPP,
  [TEAM_NAME.WSC_GOV]: TEAM_NAME.WSC_GOV,
  [TEAM_NAME.WSC_OPP]: TEAM_NAME.WSC_OPP,
  [TEAM_NAME.ASIAN_PROPOSITION]: TEAM_NAME.ASIAN_PROPOSITION,
  [TEAM_NAME.ASIAN_OPPOSITION]: TEAM_NAME.ASIAN_OPPOSITION,
  [TEAM_NAME.WSDC_PROPOSITION]: TEAM_NAME.WSDC_PROPOSITION,
  [TEAM_NAME.WSDC_OPPOSITION]: TEAM_NAME.WSDC_OPPOSITION,
  [TEAM_NAME.WSDC_SHORT_PROPOSITION]: TEAM_NAME.WSDC_SHORT_PROPOSITION,
  [TEAM_NAME.WSDC_SHORT_OPPOSITION]: TEAM_NAME.WSDC_SHORT_OPPOSITION,
  [TEAM_NAME.BP_OG]: TEAM_NAME.BP_OG,
  [TEAM_NAME.BP_OO]: TEAM_NAME.BP_OO,
  [TEAM_NAME.BP_CG]: TEAM_NAME.BP_CG,
  [TEAM_NAME.BP_CO]: TEAM_NAME.BP_CO,
  [TEAM_NAME.PHS_PROPOSITION]: TEAM_NAME.PHS_PROPOSITION,
  [TEAM_NAME.PHS_OPPOSITION]: TEAM_NAME.PHS_OPPOSITION,
};

export const TEAM_ROOMNAMESUFFIX_MAPPING = {
  [ROOM_NAME_BASE.MAIN]: 'm',
  [ROOM_NAME_BASE.PRESENTATION]: 'p',
  [ROOM_NAME_BASE.PREP_AUDIENCE]: 'pa',
  [ROOM_NAME_BASE.REFLEC_JUDGE]: 'rj',
  [ROOM_NAME_BASE.AUDITRIUM]: 'au',
  [TEAM_NAME.NA_GOV]: 'a',
  [TEAM_NAME.NA_OPP]: 'b',
  [TEAM_NAME.PDA_GOV]: 'a',
  [TEAM_NAME.PDA_OPP]: 'b',
  [TEAM_NAME.WSC_GOV]: 'a',
  [TEAM_NAME.WSC_OPP]: 'b',
  [TEAM_NAME.ASIAN_PROPOSITION]: 'a',
  [TEAM_NAME.ASIAN_OPPOSITION]: 'b',
  [TEAM_NAME.WSDC_PROPOSITION]: 'a',
  [TEAM_NAME.WSDC_OPPOSITION]: 'b',
  [TEAM_NAME.WSDC_SHORT_PROPOSITION]: 'a',
  [TEAM_NAME.WSDC_SHORT_OPPOSITION]: 'b',
  [TEAM_NAME.BP_OG]: 'a',
  [TEAM_NAME.BP_OO]: 'b',
  [TEAM_NAME.BP_CG]: 'c',
  [TEAM_NAME.BP_CO]: 'd',
  [TEAM_NAME.PHS_PROPOSITION]: 'a',
  [TEAM_NAME.PHS_OPPOSITION]: 'b',
};

export const ROOM_LIST: ROOM_NAME_TYPE[] = [
  ROOM_NAME_BASE.MAIN,
  ROOM_NAME_BASE.PRESENTATION,
  ROOM_NAME_BASE.JISTI,
  ROOM_NAME_BASE.PREP_AUDIENCE,
  ROOM_NAME_BASE.REFLEC_JUDGE,
  ROOM_NAME_BASE.AUDITRIUM,
  TEAM_NAME.NA_GOV,
  TEAM_NAME.NA_OPP,
  TEAM_NAME.PDA_GOV,
  TEAM_NAME.PDA_OPP,
  TEAM_NAME.WSC_GOV,
  TEAM_NAME.WSC_OPP,
  TEAM_NAME.ASIAN_OPPOSITION,
  TEAM_NAME.ASIAN_PROPOSITION,
  TEAM_NAME.WSDC_OPPOSITION,
  TEAM_NAME.WSDC_PROPOSITION,
  TEAM_NAME.WSDC_SHORT_OPPOSITION,
  TEAM_NAME.WSDC_SHORT_PROPOSITION,
  TEAM_NAME.BP_OG,
  TEAM_NAME.BP_OO,
  TEAM_NAME.BP_CG,
  TEAM_NAME.BP_CO,
  TEAM_NAME.PHS_OPPOSITION,
  TEAM_NAME.PHS_PROPOSITION,
];

export function getRoomName(name: ROOM_NAME_TYPE): ROOM_NAME_TYPE {
  if (ROOM_NAME[name]) {
    return ROOM_NAME[name];
  }
  return ROOM_NAME_BASE.MAIN;
}

export function getSkywayRoomName(name: ROOM_NAME_TYPE): ROOM_NAME_TYPE {
  return getRoomName(name);
}

export function getFirebaseRoomName(name: ROOM_NAME_TYPE): ROOM_NAME_TYPE {
  return getRoomName(name);
}

export function getSkywayRoomnameSuffix(name: ROOM_NAME_TYPE): string {
  if (TEAM_ROOMNAMESUFFIX_MAPPING[name]) {
    return TEAM_ROOMNAMESUFFIX_MAPPING[name];
  }
  return ' ';
}

export function getShortenedEventIdForRoomName(event_id: string) {
  return event_id.replace('/-/', '').substr(-10);
}
