import { ROLE_NAME } from './../constant/role';

export enum COMMENT_TYPE {
  OWN_LOCAL_DEBATE = 'OWN_LOCAL_DEBATE',
  OWN_LOCAL_REFLECTION = 'OWN_LOCAL_REFLECTION',
  SHARED_LOCAL = 'SHARED_LOCAL',
  SHARED_SERVER = 'SHARED_SERVER',
}

export interface CommentData {
  text: string;
  role?: ROLE_NAME;
}

export interface CommentDataDb extends CommentData {
  user_id?: string;
}
export interface CommentDataVm extends CommentDataDb {
  key?: string;
}

export interface CommentDataVmWithOwner extends CommentDataVm {
  my_comment?: boolean;
}

export interface CommentDataSharedLS extends CommentData {
  firebase_key?: string;
}

export const BLANK_COMMENTDATA: CommentDataSharedLS = {
  text: '',
  firebase_key: '',
};

export type CommentDataOwn = CommentData;

export type FirebaseKey = string;
