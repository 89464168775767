import { NgModule, ModuleWithProviders, Optional, SkipSelf, ErrorHandler } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UserauthService } from './service/userauth.service';
import { ModelUserService } from './service/model-user.service';
// import { LoggerService } from './service/logger.service';
import { GroupService } from './service/group.service';
// import { CloudMessagingService } from './service/cloud-messaging.service';

import { provideFirebaseApp, getApp, initializeApp } from '@angular/fire/app';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { SETTINGS as AUTH_SETTINGS } from '@angular/fire/compat/auth';
import { AngularFireStorageModule, BUCKET } from '@angular/fire/compat/storage';
import { environment } from './../../environments/environment';

import { SelectivePreloadStrategyService } from './service/selective-preload-strategy.service';

import { GlobalErrorHandlerService } from './service/global-error-handler.service';

// import { NotificationWebService } from './service/notification-web.service';
import { CanDeactivateGuardService } from './service/can-deactivate-guard.service';

import { FirebaseRtdLibModule } from '@mixidea-client/firebase-rtd-lib';
import { ChatService } from './service/chat.service';
import { IndexedDbLibModule } from '@mixidea-client/indexed-db-lib';
import { LoggerModule } from '@mixidea-client/logger';

// import { FirebaseStorageLibModule } from '@mixidea-client/firebase-storage-lib';
import { FirestoreLibModule } from '@mixidea-client/firestore-lib';
import { TimeerrorCorrectionService } from './service/timeerror-correction.service';
import { SoundPlayService } from './service/sound-play.service';
@NgModule({
  imports: [
    CommonModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideFirestore(() => getFirestore()),
    AngularFireDatabaseModule,
    AngularFireStorageModule,
    FirebaseRtdLibModule,
    FirestoreLibModule,
    IndexedDbLibModule,
    LoggerModule,
  ],
  declarations: [],
  providers: [
    UserauthService,
    ModelUserService,
    // LoggerService,
    SelectivePreloadStrategyService,
    GroupService,
    { provide: ErrorHandler, useClass: GlobalErrorHandlerService },
    { provide: BUCKET, useValue: environment.firebase.storageBucket },
    { provide: AUTH_SETTINGS, useValue: { appVerificationDisabledForTesting: true } },

    CanDeactivateGuardService,
    ChatService,
    TimeerrorCorrectionService,
    SoundPlayService,
  ],
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      /*,providers: []*/
    };
  }

  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule,
  ) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
