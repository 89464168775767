import { TEAM_NAME } from './team-enum';

export enum CHAT_TYPE {
  MESSAGE = 'message',
  OGP = 'ogp',
  ATTACHMENT = 'attachment',
}

export enum CHAT_TEAM {
  ALL = 'All',
}

export type CHAT_TEAM_TYPE = TEAM_NAME | CHAT_TEAM;
