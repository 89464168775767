import { Component, OnInit } from '@angular/core';

import { Subscription } from 'rxjs';
import { LoggerService, LogParam, LOG_LEVEL, LOG_MODULE, LOG_TECH, LOG_TYPE } from '@mixidea-client/logger';

import { LS_KEY_LINE_PREVIOUS_URL } from '@mixidea-client/model';

import { environment } from './../../../environments/environment';

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-line-login-link',
  templateUrl: './line-login-link.component.html',
  styleUrls: ['./line-login-link.component.scss'],
})
export class LineLoginLinkComponent implements OnInit {
  close_subscription: Subscription;

  constructor(public dialogRef: MatDialogRef<LineLoginLinkComponent>, private logger: LoggerService) {}

  ngOnInit() {
    this.send_log('line login modal opened');

    this.close_subscription = this.dialogRef.beforeClosed().subscribe(() => {
      this.send_log('line login modal closed without login');
    });
  }

  select_line_login() {
    const current_url = location.href;
    localStorage.setItem(LS_KEY_LINE_PREVIOUS_URL, current_url);
    location.href = environment.line_auth;
  }

  hide_line_modal() {
    this.dialogRef.close();
  }

  send_log(message) {
    const log_obj: LogParam = {
      message,
      level: LOG_LEVEL.LOG,
      tech: LOG_TECH.authenticate,
      type: LOG_TYPE.Keypoint,
      file_name: 'line-login-link.component',
    };
    this.logger.write(log_obj);
  }

  ngOnDestroy() {
    if (this.close_subscription) {
      this.close_subscription.unsubscribe();
    }
  }
}
