import { RolePoiAvailability } from './../interface/poi-availability';

import { DEBATE_STYLE } from './deb-style';
import { ROLE_NAME } from './role';

export const ONE_BELL = 'ONE_BELL';
export const TWO_BELL = 'TWO_BELL';
export const THREE_BELL = 'THREE_BELL';
const SEVEN_MINUTES_SPEECH_BELL_demo = { 14: ONE_BELL, 29: ONE_BELL, 44: TWO_BELL, 59: THREE_BELL };
export const SIX_MINUTES_SPEECH_BELL = { 59: ONE_BELL, 299: ONE_BELL, 359: TWO_BELL, 374: THREE_BELL };
export const FIVE_MINUTES_SPEECH_BELL = { 59: ONE_BELL, 239: ONE_BELL, 299: TWO_BELL, 314: THREE_BELL };
export const FIVE_MINUTES_SPEECH_BELL_30SEEC_SECURED = { 29: ONE_BELL, 269: ONE_BELL, 299: TWO_BELL, 314: THREE_BELL };
export const FOUR_MINUTES_SPEECH_BELL_30SEEC_SECURED = { 29: ONE_BELL, 209: ONE_BELL, 239: TWO_BELL, 254: THREE_BELL };
export const SEVEN_MINUTES_SPEECH_BELL = { 59: ONE_BELL, 359: ONE_BELL, 419: TWO_BELL, 434: THREE_BELL };
export const SEVEN_MINUTES_SPEECH_BELL_30SEEC_SECURED = { 29: ONE_BELL, 389: ONE_BELL, 419: TWO_BELL, 434: THREE_BELL };
export const EIGHT_MINUTES_SPEECH_BELL = { 59: ONE_BELL, 419: ONE_BELL, 479: TWO_BELL, 494: THREE_BELL };
export const FOUR_MINUTES_SPEECH_BELL = { 59: ONE_BELL, 179: ONE_BELL, 239: TWO_BELL, 254: THREE_BELL };
export const THREE_MINUTES_SPEECH_BELL = { 59: ONE_BELL, 119: ONE_BELL, 179: TWO_BELL, 194: THREE_BELL };
export const THREE_MINUTES_SPEECH_BELL_PDA = { 149: ONE_BELL, 179: TWO_BELL, 209: THREE_BELL };
export const TWO_MINUTES_SPEECH_BELL_PDA = { 89: ONE_BELL, 119: TWO_BELL, 149: THREE_BELL };
export const FOUR_MINUTES_SPEECH_BELL_WSC = { 179: ONE_BELL, 239: TWO_BELL };

export const ROLE_TIMER_BELL = {
  [ROLE_NAME.NA_PM]: SEVEN_MINUTES_SPEECH_BELL,
  [ROLE_NAME.NA_LO]: SEVEN_MINUTES_SPEECH_BELL,
  [ROLE_NAME.NA_MG]: SEVEN_MINUTES_SPEECH_BELL,
  [ROLE_NAME.NA_MO]: SEVEN_MINUTES_SPEECH_BELL,
  [ROLE_NAME.NA_PMR]: FOUR_MINUTES_SPEECH_BELL,
  [ROLE_NAME.NA_LOR]: FOUR_MINUTES_SPEECH_BELL,
  [ROLE_NAME.ASIAN_PM]: SEVEN_MINUTES_SPEECH_BELL_30SEEC_SECURED,
  [ROLE_NAME.ASIAN_LO]: SEVEN_MINUTES_SPEECH_BELL_30SEEC_SECURED,
  [ROLE_NAME.ASIAN_DPM]: SEVEN_MINUTES_SPEECH_BELL_30SEEC_SECURED,
  [ROLE_NAME.ASIAN_DLO]: SEVEN_MINUTES_SPEECH_BELL_30SEEC_SECURED,
  [ROLE_NAME.ASIAN_WG]: SEVEN_MINUTES_SPEECH_BELL_30SEEC_SECURED,
  [ROLE_NAME.ASIAN_WO]: SEVEN_MINUTES_SPEECH_BELL_30SEEC_SECURED,
  [ROLE_NAME.ASIAN_PMR]: FOUR_MINUTES_SPEECH_BELL,
  [ROLE_NAME.ASIAN_LOR]: FOUR_MINUTES_SPEECH_BELL,
  [ROLE_NAME.WSDC_PM]: EIGHT_MINUTES_SPEECH_BELL,
  [ROLE_NAME.WSDC_LO]: EIGHT_MINUTES_SPEECH_BELL,
  [ROLE_NAME.WSDC_DPM]: EIGHT_MINUTES_SPEECH_BELL,
  [ROLE_NAME.WSDC_DLO]: EIGHT_MINUTES_SPEECH_BELL,
  [ROLE_NAME.WSDC_WG]: EIGHT_MINUTES_SPEECH_BELL,
  [ROLE_NAME.WSDC_WO]: EIGHT_MINUTES_SPEECH_BELL,
  [ROLE_NAME.WSDC_PMR]: FOUR_MINUTES_SPEECH_BELL,
  [ROLE_NAME.WSDC_LOR]: FOUR_MINUTES_SPEECH_BELL,
  [ROLE_NAME.WSDC_SHORT_PM]: FIVE_MINUTES_SPEECH_BELL_30SEEC_SECURED,
  [ROLE_NAME.WSDC_SHORT_LO]: FIVE_MINUTES_SPEECH_BELL_30SEEC_SECURED,
  [ROLE_NAME.WSDC_SHORT_DPM]: FIVE_MINUTES_SPEECH_BELL_30SEEC_SECURED,
  [ROLE_NAME.WSDC_SHORT_DLO]: FIVE_MINUTES_SPEECH_BELL_30SEEC_SECURED,
  [ROLE_NAME.WSDC_SHORT_WG]: FIVE_MINUTES_SPEECH_BELL_30SEEC_SECURED,
  [ROLE_NAME.WSDC_SHORT_WO]: FIVE_MINUTES_SPEECH_BELL_30SEEC_SECURED,
  [ROLE_NAME.WSDC_SHORT_PMR]: FOUR_MINUTES_SPEECH_BELL,
  [ROLE_NAME.WSDC_SHORT_LOR]: FOUR_MINUTES_SPEECH_BELL,
  [ROLE_NAME.BP_PM]: SEVEN_MINUTES_SPEECH_BELL,
  [ROLE_NAME.BP_LO]: SEVEN_MINUTES_SPEECH_BELL,
  [ROLE_NAME.BP_DPM]: SEVEN_MINUTES_SPEECH_BELL,
  [ROLE_NAME.BP_DLO]: SEVEN_MINUTES_SPEECH_BELL,
  [ROLE_NAME.BP_MG]: SEVEN_MINUTES_SPEECH_BELL,
  [ROLE_NAME.BP_MO]: SEVEN_MINUTES_SPEECH_BELL,
  [ROLE_NAME.BP_GW]: SEVEN_MINUTES_SPEECH_BELL,
  [ROLE_NAME.BP_OW]: SEVEN_MINUTES_SPEECH_BELL,
  [ROLE_NAME.PDA_PM]: THREE_MINUTES_SPEECH_BELL_PDA,
  [ROLE_NAME.PDA_LO]: THREE_MINUTES_SPEECH_BELL_PDA,
  [ROLE_NAME.PDA_MG]: THREE_MINUTES_SPEECH_BELL_PDA,
  [ROLE_NAME.PDA_MO]: THREE_MINUTES_SPEECH_BELL_PDA,
  [ROLE_NAME.PDA_PMR]: TWO_MINUTES_SPEECH_BELL_PDA,
  [ROLE_NAME.PDA_LOR]: TWO_MINUTES_SPEECH_BELL_PDA,
  [ROLE_NAME.WSC_PM]: FOUR_MINUTES_SPEECH_BELL_WSC,
  [ROLE_NAME.WSC_LO]: FOUR_MINUTES_SPEECH_BELL_WSC,
  [ROLE_NAME.WSC_MG]: FOUR_MINUTES_SPEECH_BELL_WSC,
  [ROLE_NAME.WSC_MO]: FOUR_MINUTES_SPEECH_BELL_WSC,
  [ROLE_NAME.WSC_PMR]: FOUR_MINUTES_SPEECH_BELL_WSC,
  [ROLE_NAME.WSC_LOR]: FOUR_MINUTES_SPEECH_BELL_WSC,
  [ROLE_NAME.PHS_PM]: FIVE_MINUTES_SPEECH_BELL_30SEEC_SECURED,
  [ROLE_NAME.PHS_LO]: FIVE_MINUTES_SPEECH_BELL_30SEEC_SECURED,
  [ROLE_NAME.PHS_DPM]: FIVE_MINUTES_SPEECH_BELL_30SEEC_SECURED,
  [ROLE_NAME.PHS_DLO]: FIVE_MINUTES_SPEECH_BELL_30SEEC_SECURED,
  [ROLE_NAME.PHS_MG]: FIVE_MINUTES_SPEECH_BELL_30SEEC_SECURED,
  [ROLE_NAME.PHS_MO]: FIVE_MINUTES_SPEECH_BELL_30SEEC_SECURED,
  [ROLE_NAME.PHS_GW]: FIVE_MINUTES_SPEECH_BELL_30SEEC_SECURED,
  [ROLE_NAME.PHS_OW]: FIVE_MINUTES_SPEECH_BELL_30SEEC_SECURED,
};

export const SEVEN_MINUTES_WITH_POI_demo: RolePoiAvailability = { start: 9, finish: 29, message: 'POI is from 10 sec' };
export const FIVE_MINUTES_WITH_POI: RolePoiAvailability = { start: 59, finish: 239, message: 'POI is from 1 minute' };
export const FIVE_MINUTES_WITH_POI_30SEC_SECURED: RolePoiAvailability = { start: 29, finish: 269, message: 'POI is from 30 sec' };
export const FOUR_MINUTES_WITH_POI_30SEC_SECURED: RolePoiAvailability = { start: 29, finish: 209, message: 'POI is from 30 sec' };
export const SIX_MINUTES_WITH_POI: RolePoiAvailability = { start: 59, finish: 299, message: 'POI is from 1 minute' };
export const SEVEN_MINUTES_WITH_POI: RolePoiAvailability = { start: 59, finish: 359, message: 'POI is from 1 minute' };
export const SEVEN_MINUTES_WITH_POI_30SEC_SECURED: RolePoiAvailability = { start: 29, finish: 389, message: 'POI is from 30 sec' };
export const EIGHT_MINUTES_WITH_POI: RolePoiAvailability = { start: 59, finish: 419, message: 'POI is from 1 minute' };
export const FOUR_MINUTES_WITHOUT_POI: RolePoiAvailability = { start: -1, finish: -1, message: '' };
export const THREE_MINUTES_WITHOUT_POI: RolePoiAvailability = { start: -1, finish: -1, message: '' };
export const ANYTIME_POI: RolePoiAvailability = { start: -1, finish: 9999, message: '' };
export const THREE_MINUTES_POI_PDA: RolePoiAvailability = { start: -1, finish: 210, message: '' };
export const TWO_MINUTES_POI_PDA: RolePoiAvailability = { start: -1, finish: 150, message: '' };
// export const SIX_MINUTES_NO_POI: RolePoiAvailability = { start: -1, finish: -1, message: '' };
// export const FINISH_AVAILABLE_TIME = 3;

export const POI_AVAILABLE_TIME: { [key: string]: RolePoiAvailability } = {
  [ROLE_NAME.NA_PM]: SEVEN_MINUTES_WITH_POI,
  [ROLE_NAME.NA_LO]: SEVEN_MINUTES_WITH_POI,
  [ROLE_NAME.NA_MG]: SEVEN_MINUTES_WITH_POI,
  [ROLE_NAME.NA_MO]: SEVEN_MINUTES_WITH_POI,
  [ROLE_NAME.NA_PMR]: FOUR_MINUTES_WITHOUT_POI,
  [ROLE_NAME.NA_LOR]: FOUR_MINUTES_WITHOUT_POI,
  [ROLE_NAME.ASIAN_PM]: SEVEN_MINUTES_WITH_POI_30SEC_SECURED,
  [ROLE_NAME.ASIAN_LO]: SEVEN_MINUTES_WITH_POI_30SEC_SECURED,
  [ROLE_NAME.ASIAN_DPM]: SEVEN_MINUTES_WITH_POI_30SEC_SECURED,
  [ROLE_NAME.ASIAN_DLO]: SEVEN_MINUTES_WITH_POI_30SEC_SECURED,
  [ROLE_NAME.ASIAN_WG]: SEVEN_MINUTES_WITH_POI_30SEC_SECURED,
  [ROLE_NAME.ASIAN_WO]: SEVEN_MINUTES_WITH_POI_30SEC_SECURED,
  [ROLE_NAME.ASIAN_PMR]: FOUR_MINUTES_WITHOUT_POI,
  [ROLE_NAME.ASIAN_LOR]: FOUR_MINUTES_WITHOUT_POI,
  [ROLE_NAME.WSDC_PM]: EIGHT_MINUTES_WITH_POI,
  [ROLE_NAME.WSDC_LO]: EIGHT_MINUTES_WITH_POI,
  [ROLE_NAME.WSDC_DPM]: EIGHT_MINUTES_WITH_POI,
  [ROLE_NAME.WSDC_DLO]: EIGHT_MINUTES_WITH_POI,
  [ROLE_NAME.WSDC_WG]: EIGHT_MINUTES_WITH_POI,
  [ROLE_NAME.WSDC_WO]: EIGHT_MINUTES_WITH_POI,
  [ROLE_NAME.WSDC_PMR]: FOUR_MINUTES_WITHOUT_POI,
  [ROLE_NAME.WSDC_LOR]: FOUR_MINUTES_WITHOUT_POI,
  [ROLE_NAME.WSDC_SHORT_PM]: FIVE_MINUTES_WITH_POI_30SEC_SECURED,
  [ROLE_NAME.WSDC_SHORT_LO]: FIVE_MINUTES_WITH_POI_30SEC_SECURED,
  [ROLE_NAME.WSDC_SHORT_DPM]: FIVE_MINUTES_WITH_POI_30SEC_SECURED,
  [ROLE_NAME.WSDC_SHORT_DLO]: FIVE_MINUTES_WITH_POI_30SEC_SECURED,
  [ROLE_NAME.WSDC_SHORT_WG]: FIVE_MINUTES_WITH_POI_30SEC_SECURED,
  [ROLE_NAME.WSDC_SHORT_WO]: FIVE_MINUTES_WITH_POI_30SEC_SECURED,
  [ROLE_NAME.WSDC_SHORT_PMR]: FOUR_MINUTES_WITHOUT_POI,
  [ROLE_NAME.WSDC_SHORT_LOR]: FOUR_MINUTES_WITHOUT_POI,
  [ROLE_NAME.BP_PM]: SEVEN_MINUTES_WITH_POI,
  [ROLE_NAME.BP_LO]: SEVEN_MINUTES_WITH_POI,
  [ROLE_NAME.BP_DPM]: SEVEN_MINUTES_WITH_POI,
  [ROLE_NAME.BP_DLO]: SEVEN_MINUTES_WITH_POI,
  [ROLE_NAME.BP_MG]: SEVEN_MINUTES_WITH_POI,
  [ROLE_NAME.BP_MO]: SEVEN_MINUTES_WITH_POI,
  [ROLE_NAME.BP_GW]: SEVEN_MINUTES_WITH_POI,
  [ROLE_NAME.BP_OW]: SEVEN_MINUTES_WITH_POI,
  [ROLE_NAME.PDA_PM]: THREE_MINUTES_POI_PDA,
  [ROLE_NAME.PDA_LO]: THREE_MINUTES_POI_PDA,
  [ROLE_NAME.PDA_MG]: THREE_MINUTES_POI_PDA,
  [ROLE_NAME.PDA_MO]: THREE_MINUTES_POI_PDA,
  [ROLE_NAME.PDA_PMR]: TWO_MINUTES_POI_PDA,
  [ROLE_NAME.PDA_LOR]: TWO_MINUTES_POI_PDA,
  [ROLE_NAME.WSC_PM]: FOUR_MINUTES_WITHOUT_POI,
  [ROLE_NAME.WSC_LO]: FOUR_MINUTES_WITHOUT_POI,
  [ROLE_NAME.WSC_MG]: FOUR_MINUTES_WITHOUT_POI,
  [ROLE_NAME.WSC_MO]: FOUR_MINUTES_WITHOUT_POI,
  [ROLE_NAME.WSC_PMR]: FOUR_MINUTES_WITHOUT_POI,
  [ROLE_NAME.WSC_LOR]: FOUR_MINUTES_WITHOUT_POI,
  [ROLE_NAME.PHS_PM]: FIVE_MINUTES_WITH_POI_30SEC_SECURED,
  [ROLE_NAME.PHS_LO]: FIVE_MINUTES_WITH_POI_30SEC_SECURED,
  [ROLE_NAME.PHS_DPM]: FIVE_MINUTES_WITH_POI_30SEC_SECURED,
  [ROLE_NAME.PHS_DLO]: FIVE_MINUTES_WITH_POI_30SEC_SECURED,
  [ROLE_NAME.PHS_MG]: FIVE_MINUTES_WITH_POI_30SEC_SECURED,
  [ROLE_NAME.PHS_MO]: FIVE_MINUTES_WITH_POI_30SEC_SECURED,
  [ROLE_NAME.PHS_GW]: FIVE_MINUTES_WITH_POI_30SEC_SECURED,
  [ROLE_NAME.PHS_OW]: FIVE_MINUTES_WITH_POI_30SEC_SECURED,
};

export interface SpeechDuration {
  duration_string?: string;
  duration_number?: number;
  timing_warning?: number;
  timing_force_finish?: number;
}

// export interface SpeechDurationRole {
//   [key: string]: SpeechDuration;
// }

export const SPEECH_DURATION_SPEECHFINISH_TEST: SpeechDuration = {
  duration_string: '0:10',
  duration_number: 9,
  timing_warning: 14,
  timing_force_finish: 29,
};
export const SPEECH_DURATION_WARNING_TEST: SpeechDuration = {
  duration_string: '0:10',
  duration_number: 9,
  timing_warning: 14,
  timing_force_finish: 7 * 60 + 29,
};
export const SPEECH_DURATION_FORCEFINISH_TEST: SpeechDuration = {
  duration_string: '0:10',
  duration_number: 9,
  timing_warning: 10 + 4,
  timing_force_finish: 10 + 5 + 4,
};
export const SPEECH_DURATION_EIGHT: SpeechDuration = {
  duration_string: '8:15',
  duration_number: 8 * 60 - 1,
  timing_warning: 8 * 60 + 14,
  timing_force_finish: 8 * 60 + 29,
};
export const SPEECH_DURATION_SEVEN: SpeechDuration = {
  duration_string: '7:15',
  duration_number: 7 * 60 - 1,
  timing_warning: 7 * 60 + 14,
  timing_force_finish: 7 * 60 + 29,
};
export const SPEECH_DURATION_SIX: SpeechDuration = {
  duration_string: '6:15',
  duration_number: 6 * 60 - 1,
  timing_warning: 6 * 60 + 14,
  timing_force_finish: 6 * 60 + 29,
};

export const SPEECH_DURATION_FIVE: SpeechDuration = {
  duration_string: '5:15',
  duration_number: 5 * 60 - 1,
  timing_warning: 5 * 60 + 14,
  timing_force_finish: 5 * 60 + 29,
};

export const SPEECH_DURATION_FOUR: SpeechDuration = {
  duration_string: '4:15',
  duration_number: 4 * 60 - 1,
  timing_warning: 4 * 60 + 14,
  timing_force_finish: 4 * 60 + 29,
};
export const SPEECH_DURATION_THREE: SpeechDuration = {
  duration_string: '3:15',
  duration_number: 3 * 60 - 1,
  timing_warning: 3 * 60 + 14,
  timing_force_finish: 3 * 60 + 29,
};

export const SPEECH_DURATION_THREE_PDA: SpeechDuration = {
  duration_string: '3:30',
  duration_number: 3 * 60 - 1,
  timing_warning: 3 * 60 + 29,
  timing_force_finish: 3 * 60 + 44,
};
export const SPEECH_DURATION_TWO_PDA: SpeechDuration = {
  duration_string: '2:30',
  duration_number: 2 * 60 - 1,
  timing_warning: 2 * 60 + 29,
  timing_force_finish: 2 * 60 + 44,
};
export const SPEECH_DURATION_FOUR_WSC: SpeechDuration = {
  duration_string: '4:00',
  duration_number: 4 * 60 - 1,
  timing_warning: 4 * 60 - 1,
  timing_force_finish: 4 * 60 - 1,
};
export const SPEECH_DURATION_DEMO: SpeechDuration = {
  duration_string: '7:15',
  duration_number: 0.25 * 60 - 1,
  timing_warning: 0.25 * 60 + 14,
  timing_force_finish: 0.25 * 60 + 29,
};

export const ROLE_SPEECHDURATION: { [key: string]: SpeechDuration } = {
  [ROLE_NAME.NA_PM]: SPEECH_DURATION_SEVEN,
  [ROLE_NAME.NA_LO]: SPEECH_DURATION_SEVEN,
  [ROLE_NAME.NA_MG]: SPEECH_DURATION_SEVEN,
  [ROLE_NAME.NA_MO]: SPEECH_DURATION_SEVEN,
  [ROLE_NAME.NA_PMR]: SPEECH_DURATION_FOUR,
  [ROLE_NAME.NA_LOR]: SPEECH_DURATION_FOUR,
  [ROLE_NAME.ASIAN_PM]: SPEECH_DURATION_SEVEN,
  [ROLE_NAME.ASIAN_LO]: SPEECH_DURATION_SEVEN,
  [ROLE_NAME.ASIAN_DPM]: SPEECH_DURATION_SEVEN,
  [ROLE_NAME.ASIAN_DLO]: SPEECH_DURATION_SEVEN,
  [ROLE_NAME.ASIAN_WG]: SPEECH_DURATION_SEVEN,
  [ROLE_NAME.ASIAN_WO]: SPEECH_DURATION_SEVEN,
  [ROLE_NAME.ASIAN_PMR]: SPEECH_DURATION_FOUR,
  [ROLE_NAME.ASIAN_LOR]: SPEECH_DURATION_FOUR,
  [ROLE_NAME.WSDC_PM]: SPEECH_DURATION_EIGHT,
  [ROLE_NAME.WSDC_LO]: SPEECH_DURATION_EIGHT,
  [ROLE_NAME.WSDC_DPM]: SPEECH_DURATION_EIGHT,
  [ROLE_NAME.WSDC_DLO]: SPEECH_DURATION_EIGHT,
  [ROLE_NAME.WSDC_WG]: SPEECH_DURATION_EIGHT,
  [ROLE_NAME.WSDC_WO]: SPEECH_DURATION_EIGHT,
  [ROLE_NAME.WSDC_PMR]: SPEECH_DURATION_FOUR,
  [ROLE_NAME.WSDC_LOR]: SPEECH_DURATION_FOUR,
  [ROLE_NAME.WSDC_SHORT_PM]: SPEECH_DURATION_FIVE,
  [ROLE_NAME.WSDC_SHORT_LO]: SPEECH_DURATION_FIVE,
  [ROLE_NAME.WSDC_SHORT_DPM]: SPEECH_DURATION_FIVE,
  [ROLE_NAME.WSDC_SHORT_DLO]: SPEECH_DURATION_FIVE,
  [ROLE_NAME.WSDC_SHORT_WG]: SPEECH_DURATION_FIVE,
  [ROLE_NAME.WSDC_SHORT_WO]: SPEECH_DURATION_FIVE,
  [ROLE_NAME.WSDC_SHORT_PMR]: SPEECH_DURATION_FOUR,
  [ROLE_NAME.WSDC_SHORT_LOR]: SPEECH_DURATION_FOUR,
  [ROLE_NAME.BP_PM]: SPEECH_DURATION_SEVEN,
  [ROLE_NAME.BP_LO]: SPEECH_DURATION_SEVEN,
  [ROLE_NAME.BP_DPM]: SPEECH_DURATION_SEVEN,
  [ROLE_NAME.BP_DLO]: SPEECH_DURATION_SEVEN,
  [ROLE_NAME.BP_MG]: SPEECH_DURATION_SEVEN,
  [ROLE_NAME.BP_MO]: SPEECH_DURATION_SEVEN,
  [ROLE_NAME.BP_GW]: SPEECH_DURATION_SEVEN,
  [ROLE_NAME.BP_OW]: SPEECH_DURATION_SEVEN,
  [ROLE_NAME.PDA_PM]: SPEECH_DURATION_THREE_PDA,
  [ROLE_NAME.PDA_LO]: SPEECH_DURATION_THREE_PDA,
  [ROLE_NAME.PDA_MG]: SPEECH_DURATION_THREE_PDA,
  [ROLE_NAME.PDA_MO]: SPEECH_DURATION_THREE_PDA,
  [ROLE_NAME.PDA_PMR]: SPEECH_DURATION_TWO_PDA,
  [ROLE_NAME.PDA_LOR]: SPEECH_DURATION_TWO_PDA,
  [ROLE_NAME.WSC_PM]: SPEECH_DURATION_FOUR_WSC,
  [ROLE_NAME.WSC_LO]: SPEECH_DURATION_FOUR_WSC,
  [ROLE_NAME.WSC_MG]: SPEECH_DURATION_FOUR_WSC,
  [ROLE_NAME.WSC_MO]: SPEECH_DURATION_FOUR_WSC,
  [ROLE_NAME.WSC_PMR]: SPEECH_DURATION_FOUR_WSC,
  [ROLE_NAME.WSC_LOR]: SPEECH_DURATION_FOUR_WSC,
  [ROLE_NAME.PHS_PM]: SPEECH_DURATION_FIVE,
  [ROLE_NAME.PHS_LO]: SPEECH_DURATION_FIVE,
  [ROLE_NAME.PHS_DPM]: SPEECH_DURATION_FIVE,
  [ROLE_NAME.PHS_DLO]: SPEECH_DURATION_FIVE,
  [ROLE_NAME.PHS_MG]: SPEECH_DURATION_FIVE,
  [ROLE_NAME.PHS_MO]: SPEECH_DURATION_FIVE,
  [ROLE_NAME.PHS_GW]: SPEECH_DURATION_FIVE,
  [ROLE_NAME.PHS_OW]: SPEECH_DURATION_FIVE,
};

export const ROLE_DURATION_MAPPING = {
  [ROLE_NAME.NA_PM]: '7min',
  [ROLE_NAME.NA_LO]: '7min',
  [ROLE_NAME.NA_MG]: '7min',
  [ROLE_NAME.NA_MO]: '7min',
  [ROLE_NAME.NA_PMR]: '4min',
  [ROLE_NAME.NA_LOR]: '4min',
  [ROLE_NAME.PDA_PM]: '3min',
  [ROLE_NAME.PDA_LO]: '3min',
  [ROLE_NAME.PDA_MG]: '3min',
  [ROLE_NAME.PDA_MO]: '3min',
  [ROLE_NAME.PDA_PMR]: '2min',
  [ROLE_NAME.PDA_LOR]: '2min',
  [ROLE_NAME.WSC_PM]: '4min',
  [ROLE_NAME.WSC_LO]: '4min',
  [ROLE_NAME.WSC_MG]: '4min',
  [ROLE_NAME.WSC_MO]: '4min',
  [ROLE_NAME.WSC_PMR]: '4min',
  [ROLE_NAME.WSC_LOR]: '4min',
  [ROLE_NAME.ASIAN_PM]: '7min',
  [ROLE_NAME.ASIAN_LO]: '7min',
  [ROLE_NAME.ASIAN_DPM]: '7min',
  [ROLE_NAME.ASIAN_DLO]: '7min',
  [ROLE_NAME.ASIAN_WG]: '7min',
  [ROLE_NAME.ASIAN_WO]: '7min',
  [ROLE_NAME.ASIAN_PMR]: '4min',
  [ROLE_NAME.ASIAN_LOR]: '4min',
  [ROLE_NAME.WSDC_PM]: '8min',
  [ROLE_NAME.WSDC_LO]: '8min',
  [ROLE_NAME.WSDC_DPM]: '8min',
  [ROLE_NAME.WSDC_DLO]: '8min',
  [ROLE_NAME.WSDC_WG]: '8min',
  [ROLE_NAME.WSDC_WO]: '8min',
  [ROLE_NAME.WSDC_PMR]: '4min',
  [ROLE_NAME.WSDC_LOR]: '4min',
  [ROLE_NAME.WSDC_SHORT_PM]: '5min',
  [ROLE_NAME.WSDC_SHORT_LO]: '5min',
  [ROLE_NAME.WSDC_SHORT_DPM]: '5min',
  [ROLE_NAME.WSDC_SHORT_DLO]: '5min',
  [ROLE_NAME.WSDC_SHORT_WG]: '5min',
  [ROLE_NAME.WSDC_SHORT_WO]: '5min',
  [ROLE_NAME.WSDC_SHORT_PMR]: '4min',
  [ROLE_NAME.WSDC_SHORT_LOR]: '4min',
  [ROLE_NAME.BP_PM]: '7min',
  [ROLE_NAME.BP_LO]: '7min',
  [ROLE_NAME.BP_DPM]: '7min',
  [ROLE_NAME.BP_DLO]: '7min',
  [ROLE_NAME.BP_MG]: '7min',
  [ROLE_NAME.BP_MO]: '7min',
  [ROLE_NAME.BP_GW]: '7min',
  [ROLE_NAME.BP_OW]: '7min',
  [ROLE_NAME.PHS_PM]: '5min',
  [ROLE_NAME.PHS_LO]: '5min',
  [ROLE_NAME.PHS_DPM]: '5min',
  [ROLE_NAME.PHS_DLO]: '5min',
  [ROLE_NAME.PHS_MG]: '5min',
  [ROLE_NAME.PHS_MO]: '5min',
  [ROLE_NAME.PHS_GW]: '5min',
  [ROLE_NAME.PHS_OW]: '5min',
};
