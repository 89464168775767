import firebase from 'firebase/compat/app';

export enum TIMELINE_TYPE {
  CommentGroup = 'CommentGroup',
  CommentEvent = 'CommentEvent',
  EventAll = 'EventAll',
  EventGroup = 'EventGroup',
  EventUser = 'EventUser',
  TranscriptArticle = 'TranscriptArticle',
}

export interface TimelineBaseDb {
  type?: TIMELINE_TYPE;
  time_added_timestamp?: firebase.firestore.Timestamp;
  time_updated_timestamp?: firebase.firestore.Timestamp;
}

export interface TimelineBaseVm extends TimelineBaseDb {
  time_added_date?: firebase.firestore.Timestamp;
  time_updated_date?: firebase.firestore.Timestamp;
}
