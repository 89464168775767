export enum LESSON_COMMENT_TYPE {
  OPINION = 'OPINION',
  FEEDBACK = 'FEEDBACK',
}

export interface CommentForStudentInJisti {
  feedback: CommentEach;
  opinion: CommentEach;
}

export interface CommentEach {
  text: string;
  editors: { [key: string]: boolean };
}
export const DEFAULT_COMMENT_EACH: CommentEach = {
  text: '',
  editors: {},
};

export const DEFAULT_COMMENT_FOR_STUDENT: CommentForStudentInJisti = {
  feedback: DEFAULT_COMMENT_EACH,
  opinion: DEFAULT_COMMENT_EACH,
};
