import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { FirebaseRtdService } from './service/firebase-rtd.service';

import { CommentForStudentInJisti, LESSON_COMMENT_TYPE } from '@mixidea-client/model';

@Injectable({
  providedIn: 'root',
})
export class JistiFirebaseService {
  constructor(private firebaseRtdService: FirebaseRtdService) {}

  get_comment$(event_id: string, user_id: string): Observable<CommentForStudentInJisti[]> {
    const path = `/event_related/jisti-lesson-comment/${event_id}/user/${user_id}/comments`;
    return this.firebaseRtdService.getObjectValue(path);
  }

  set_comment_start_edit(comment_type: LESSON_COMMENT_TYPE, event_id: string, comment_num: number, user_id: string): Promise<void> {
    let type_path = '';
    if (comment_type === LESSON_COMMENT_TYPE.OPINION) {
      type_path = 'opinion';
    } else if (comment_type === LESSON_COMMENT_TYPE.FEEDBACK) {
      type_path = 'feedback';
    } else {
      return;
    }
    const path = `/event_related/jisti-lesson-comment/${event_id}/user/${user_id}/comments/${String(
      comment_num,
    )}/${type_path}/editors/${user_id}`;
    return this.firebaseRtdService.setObject(path, true);
  }

  set_comment_finish_edit(comment_type: LESSON_COMMENT_TYPE, event_id: string, comment_num: number, user_id: string): Promise<void> {
    let type_path = '';
    if (comment_type === LESSON_COMMENT_TYPE.OPINION) {
      type_path = 'opinion';
    } else if (comment_type === LESSON_COMMENT_TYPE.FEEDBACK) {
      type_path = 'feedback';
    } else {
      return;
    }
    const path = `/event_related/jisti-lesson-comment/${event_id}/user/${user_id}/comments/${String(
      comment_num,
    )}/${type_path}/editors/${user_id}`;
    return this.firebaseRtdService.removeObject(path);
  }

  save_comment_context(
    comment_type: LESSON_COMMENT_TYPE,
    event_id: string,
    user_id: string,
    comment_num: number,
    context: string,
  ): Promise<void> {
    let type_path = '';
    if (comment_type === LESSON_COMMENT_TYPE.OPINION) {
      type_path = 'opinion';
    } else if (comment_type === LESSON_COMMENT_TYPE.FEEDBACK) {
      type_path = 'feedback';
    } else {
      return;
    }

    const path = `/event_related/jisti-lesson-comment/${event_id}/user/${user_id}/comments/${String(comment_num)}/${type_path}/text`;
    return this.firebaseRtdService.setObject(path, context);
  }
}
