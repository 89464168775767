import { Injectable } from '@angular/core';

import { AngularFireStorage } from '@angular/fire/compat/storage';

@Injectable()
export class FirebaseStorageService {
  constructor(private storage: AngularFireStorage) {}

  store_file(path: string, file: File): Promise<string> {
    const fileRef = this.storage.ref(path);
    return fileRef
      .put(file)
      .then((result) => {
        return result.ref.getDownloadURL();
      })
      .then((url: string) => {
        return url;
      });

    // return new Promise((resolve, reject )=>{

    //   const fileRef = this.storage.ref(path);
    //   this.storage.upload(path, file);
    //   console.log('upload start');

    //   fileRef.getDownloadURL().subscribe(
    //     (downloadURL)=>{
    //       resolve(downloadURL);
    //     },
    //     (err)=>{
    //       console.log('getDownloadURL error', err)
    //     },
    //     ()=>{
    //       console.log('complete')
    //     }
    //   )
    // })
    // return task.snapshotChanges().pipe(
    // finalize(()=>{
    //   console.log('store finalize');
    // }),
    // switchMap( ()=>{
    // return fileRef.getDownloadURL();
    // })
    //  )
  }

  put_img(path: string, file_name: string, img_blob: Blob, metadata): Promise<string> {
    const fileRef = this.storage.ref(path);
    return fileRef
      .put(img_blob, metadata)
      .then((result) => {
        return result.ref.getDownloadURL();
      })
      .then((url: string) => {
        return url;
      });

    // return new Promise(function (resolve, reject) {
    //   const fileRef = this.storage.ref(path);
    //   const task = this.storage.upload(path, img_blob, metadata);
    //   task.snapshotChanges().pipe(
    //     finalize( () => {
    //       const downloadURL = fileRef.getDownloadURL();
    //       resolve( downloadURL );
    //     })
    //   )
    // });
  }

  dataURLtoBlob(dataURI) {
    const arr = dataURI.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }
}
