import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { OwnUser, EMPTY_OWN_USER, getBlankNavigationExtra } from '@mixidea-client/model';

import { UserauthService } from './../../core/service/userauth.service';

import { environment } from './../../../environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit, OnDestroy {
  own_user: OwnUser = EMPTY_OWN_USER;
  own_user_subscription;

  constructor(private user_auth: UserauthService, private router: Router) {}

  ngOnInit() {
    this.own_user_subscription = this.user_auth.get_own_user$().subscribe((user_data: OwnUser) => {
      this.own_user = user_data;
    });
  }

  get privacy_policy_url() {
    return environment.privacy_policy_url;
  }

  goto_school_overview() {
    const navigationExtra2 = getBlankNavigationExtra();
    this.router.navigate(['announcement/overview'], navigationExtra2);
  }

  goto_study() {
    const navigationExtra2 = getBlankNavigationExtra();
    this.router.navigate(['/study/top'], navigationExtra2);
  }

  logout() {
    this.user_auth.logout();
    const navigationExtra2 = getBlankNavigationExtra();
    this.router.navigate([''], navigationExtra2);
  }

  ngOnDestroy() {
    if (this.own_user_subscription) {
      this.own_user_subscription.unsubscribe();
    }
  }
}
