import { Component, OnInit, Input } from '@angular/core';

import { UserShowBaseComponent } from '../user-show-base/user-show-base.component';

import { USERSHOW_ORDER_ICON_NAME } from '@mixidea-client/model';

@Component({
  selector: 'app-user-show-icon-name',
  templateUrl: './user-show-icon-name.component.html',
  styleUrls: ['./user-show-icon-name.component.scss'],
})
export class UserShowIconNameComponent extends UserShowBaseComponent {
  USERSHOW_ORDER_ICON_NAME = USERSHOW_ORDER_ICON_NAME;
  @Input()
  width: string;
  @Input()
  height: string;
  @Input()
  font_size: string;
  @Input()
  vertical_align: string;
  @Input()
  usershow_order: USERSHOW_ORDER_ICON_NAME;
}
