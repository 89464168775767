import { Component, OnInit } from '@angular/core';

import { getBlankNavigationExtra } from '@mixidea-client/model';
import { Router, NavigationExtras } from '@angular/router';

@Component({
  selector: 'app-link-to-study-service',
  templateUrl: './link-to-study-service.component.html',
  styleUrls: ['./link-to-study-service.component.scss'],
})
export class LinkToStudyServiceComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit() {}

  goto_study_service() {
    const navigationExtra2 = getBlankNavigationExtra();
    this.router.navigate(['/study/top/'], navigationExtra2);
  }
}
