import { SIDE } from './side';

/* role name must be unique for each role within style */

export enum ROLE_NAME {
  NA_PM = 'NA_PM',
  NA_LO = 'NA_LO',
  NA_MG = 'NA_MG',
  NA_MO = 'NA_MO',
  NA_LOR = 'NA_LOR',
  NA_PMR = 'NA_PMR',
  PDA_PM = 'PDA_PM',
  PDA_LO = 'PDA_LO',
  PDA_MG = 'PDA_MG',
  PDA_MO = 'PDA_MO',
  PDA_LOR = 'PDA_LOR',
  PDA_PMR = 'PDA_PMR',
  WSC_PM = 'WSC_PM',
  WSC_LO = 'WSC_LO',
  WSC_MG = 'WSC_MG',
  WSC_MO = 'WSC_MO',
  WSC_LOR = 'WSC_LOR',
  WSC_PMR = 'WSC_PMR',
  ASIAN_PM = 'ASIAN_PM',
  ASIAN_LO = 'ASIAN_LO',
  ASIAN_DPM = 'ASIAN_DPM',
  ASIAN_DLO = 'ASIAN_DLO',
  ASIAN_WG = 'ASIAN_WG',
  ASIAN_WO = 'ASIAN_WO',
  ASIAN_LOR = 'ASIAN_LOR',
  ASIAN_PMR = 'ASIAN_PMR',
  WSDC_PM = 'WSDC_PM',
  WSDC_LO = 'WSDC_LO',
  WSDC_DPM = 'WSDC_DPM',
  WSDC_DLO = 'WSDC_DLO',
  WSDC_WG = 'WSDC_WG',
  WSDC_WO = 'WSDC_WO',
  WSDC_LOR = 'WSDC_LOR',
  WSDC_PMR = 'WSDC_PMR',
  WSDC_SHORT_PM = 'WSDC_SHORT_PM',
  WSDC_SHORT_LO = 'WSDC_SHORT_LO',
  WSDC_SHORT_DPM = 'WSDC_SHORT_DPM',
  WSDC_SHORT_DLO = 'WSDC_SHORT_DLO',
  WSDC_SHORT_WG = 'WSDC_SHORT_WG',
  WSDC_SHORT_WO = 'WSDC_SHORT_WO',
  WSDC_SHORT_LOR = 'WSDC_SHORT_LOR',
  WSDC_SHORT_PMR = 'WSDC_SHORT_PMR',
  BP_PM = 'BP_PM',
  BP_LO = 'BP_LO',
  BP_DPM = 'BP_DPM',
  BP_DLO = 'BP_DLO',
  BP_MG = 'BP_MG',
  BP_MO = 'BP_MO',
  BP_GW = 'BP_GW',
  BP_OW = 'BP_OW',
  PHS_PM = 'PHS_PM',
  PHS_LO = 'PHS_LO',
  PHS_DPM = 'PHS_DPM',
  PHS_DLO = 'PHS_DLO',
  PHS_MG = 'PHS_MG',
  PHS_MO = 'PHS_MO',
  PHS_GW = 'PHS_GW',
  PHS_OW = 'PHS_OW',
  COMPLETE = 'COMPLETE',
  GENERAL = 'GENERAL',
}

export interface RoleInfo {
  name?: ROLE_NAME;
  side?: SIDE;
  num?: number;
  show_name_short?: string;
  show_name_long?: string;
}

export interface RoleInfoWithUser extends RoleInfo {
  role_user_id?: string;
  own_role?: boolean;
}

export interface RoleStatus extends RoleInfo {
  completed?: boolean;
  main_speaker?: boolean;
  next_speaker?: boolean;
}

export const NA_ROLE_ORDER: { [key: number]: ROLE_NAME } = {
  1: ROLE_NAME.NA_PM,
  2: ROLE_NAME.NA_LO,
  3: ROLE_NAME.NA_MG,
  4: ROLE_NAME.NA_MO,
  5: ROLE_NAME.NA_LOR,
  6: ROLE_NAME.NA_PMR,
};

export const NA_ROLE_ARRAY: RoleInfo[] = [
  { name: ROLE_NAME.NA_PM, side: SIDE.LEFT, num: 1, show_name_short: 'PM', show_name_long: 'Prime Minister' },
  { name: ROLE_NAME.NA_LO, side: SIDE.RIGHT, num: 2, show_name_short: 'LO', show_name_long: 'Leader Opposition' },
  { name: ROLE_NAME.NA_MG, side: SIDE.LEFT, num: 3, show_name_short: 'MG', show_name_long: 'Member Government' },
  { name: ROLE_NAME.NA_MO, side: SIDE.RIGHT, num: 4, show_name_short: 'MO', show_name_long: 'Member Opposition' },
  { name: ROLE_NAME.NA_LOR, side: SIDE.RIGHT, num: 5, show_name_short: 'LOR', show_name_long: 'Leader Opposition Reply' },
  { name: ROLE_NAME.NA_PMR, side: SIDE.LEFT, num: 6, show_name_short: 'PMR', show_name_long: 'Prime Minister Reply' },
];

export const PDA_ROLE_ORDER: { [key: number]: ROLE_NAME } = {
  1: ROLE_NAME.PDA_PM,
  2: ROLE_NAME.PDA_LO,
  3: ROLE_NAME.PDA_MG,
  4: ROLE_NAME.PDA_MO,
  5: ROLE_NAME.PDA_LOR,
  6: ROLE_NAME.PDA_PMR,
};

export const PDA_ROLE_ARRAY: RoleInfo[] = [
  { name: ROLE_NAME.PDA_PM, side: SIDE.LEFT, num: 1, show_name_short: 'PM', show_name_long: 'Prime Minister' },
  { name: ROLE_NAME.PDA_LO, side: SIDE.RIGHT, num: 2, show_name_short: 'LO', show_name_long: 'Leader Opposition' },
  { name: ROLE_NAME.PDA_MG, side: SIDE.LEFT, num: 3, show_name_short: 'MG', show_name_long: 'Member Government' },
  { name: ROLE_NAME.PDA_MO, side: SIDE.RIGHT, num: 4, show_name_short: 'MO', show_name_long: 'Member Opposition' },
  { name: ROLE_NAME.PDA_LOR, side: SIDE.RIGHT, num: 5, show_name_short: 'LOR', show_name_long: 'Leader Opposition Reply' },
  { name: ROLE_NAME.PDA_PMR, side: SIDE.LEFT, num: 6, show_name_short: 'PMR', show_name_long: 'Prime Minister Reply' },
];

export const WSC_ROLE_ORDER: { [key: number]: ROLE_NAME } = {
  1: ROLE_NAME.WSC_PM,
  2: ROLE_NAME.WSC_LO,
  3: ROLE_NAME.WSC_MG,
  4: ROLE_NAME.WSC_MO,
  5: ROLE_NAME.WSC_PMR,
  6: ROLE_NAME.WSC_LOR,
};

export const WSC_ROLE_ARRAY: RoleInfo[] = [
  { name: ROLE_NAME.WSC_PM, side: SIDE.LEFT, num: 1, show_name_short: 'PM', show_name_long: 'Prime Minister' },
  { name: ROLE_NAME.WSC_LO, side: SIDE.RIGHT, num: 2, show_name_short: 'LO', show_name_long: 'Leader Opposition' },
  { name: ROLE_NAME.WSC_MG, side: SIDE.LEFT, num: 3, show_name_short: 'MG', show_name_long: 'Member Government' },
  { name: ROLE_NAME.WSC_MO, side: SIDE.RIGHT, num: 4, show_name_short: 'MO', show_name_long: 'Member Opposition' },
  { name: ROLE_NAME.WSC_PMR, side: SIDE.LEFT, num: 6, show_name_short: 'PMR', show_name_long: 'Prime Minister Reply' },
  { name: ROLE_NAME.WSC_LOR, side: SIDE.RIGHT, num: 5, show_name_short: 'LOR', show_name_long: 'Leader Opposition Reply' },
];

export const ASIAN_ROLE_ORDER: { [key: number]: ROLE_NAME } = {
  1: ROLE_NAME.ASIAN_PM,
  2: ROLE_NAME.ASIAN_LO,
  3: ROLE_NAME.ASIAN_DPM,
  4: ROLE_NAME.ASIAN_DLO,
  5: ROLE_NAME.ASIAN_WG,
  6: ROLE_NAME.ASIAN_WO,
  7: ROLE_NAME.ASIAN_LOR,
  8: ROLE_NAME.ASIAN_PMR,
};

export const ASIAN_ROLE_ARRAY: RoleInfo[] = [
  { name: ROLE_NAME.ASIAN_PM, side: SIDE.LEFT, num: 1, show_name_short: 'PM', show_name_long: 'Prime Minister' },
  { name: ROLE_NAME.ASIAN_LO, side: SIDE.RIGHT, num: 2, show_name_short: 'LO', show_name_long: 'Leader Opposition' },
  { name: ROLE_NAME.ASIAN_DPM, side: SIDE.LEFT, num: 3, show_name_short: 'DPM', show_name_long: 'Depty Prime Minister' },
  { name: ROLE_NAME.ASIAN_DLO, side: SIDE.RIGHT, num: 4, show_name_short: 'DLO', show_name_long: 'Depty Leader Opposition' },
  { name: ROLE_NAME.ASIAN_WG, side: SIDE.LEFT, num: 5, show_name_short: 'WG', show_name_long: 'Whip Government' },
  { name: ROLE_NAME.ASIAN_WO, side: SIDE.RIGHT, num: 6, show_name_short: 'WO', show_name_long: 'Whip Opposition' },
  { name: ROLE_NAME.ASIAN_LOR, side: SIDE.RIGHT, num: 7, show_name_short: 'LOR', show_name_long: 'Leader Opposition Reply' },
  { name: ROLE_NAME.ASIAN_PMR, side: SIDE.LEFT, num: 8, show_name_short: 'PMR', show_name_long: 'Prime Minister Reply' },
];

export const WSDC_ROLE_ORDER: { [key: number]: ROLE_NAME } = {
  1: ROLE_NAME.WSDC_PM,
  2: ROLE_NAME.WSDC_LO,
  3: ROLE_NAME.WSDC_DPM,
  4: ROLE_NAME.WSDC_DLO,
  5: ROLE_NAME.WSDC_WG,
  6: ROLE_NAME.WSDC_WO,
  7: ROLE_NAME.WSDC_LOR,
  8: ROLE_NAME.WSDC_PMR,
};

export const WSDC_ROLE_ARRAY: RoleInfo[] = [
  { name: ROLE_NAME.WSDC_PM, side: SIDE.LEFT, num: 1, show_name_short: 'PM', show_name_long: 'Prime Minister' },
  { name: ROLE_NAME.WSDC_LO, side: SIDE.RIGHT, num: 2, show_name_short: 'LO', show_name_long: 'Leader Opposition' },
  { name: ROLE_NAME.WSDC_DPM, side: SIDE.LEFT, num: 3, show_name_short: 'DPM', show_name_long: 'Depty Prime Minister' },
  { name: ROLE_NAME.WSDC_DLO, side: SIDE.RIGHT, num: 4, show_name_short: 'DLO', show_name_long: 'Depty Leader Opposition' },
  { name: ROLE_NAME.WSDC_WG, side: SIDE.LEFT, num: 5, show_name_short: 'WG', show_name_long: 'Whip Government' },
  { name: ROLE_NAME.WSDC_WO, side: SIDE.RIGHT, num: 6, show_name_short: 'WO', show_name_long: 'Whip Opposition' },
  { name: ROLE_NAME.WSDC_LOR, side: SIDE.RIGHT, num: 7, show_name_short: 'LOR', show_name_long: 'Leader Opposition Reply' },
  { name: ROLE_NAME.WSDC_PMR, side: SIDE.LEFT, num: 8, show_name_short: 'PMR', show_name_long: 'Prime Minister Reply' },
];

export const WSDC_SHORT_ROLE_ORDER: { [key: number]: ROLE_NAME } = {
  1: ROLE_NAME.WSDC_SHORT_PM,
  2: ROLE_NAME.WSDC_SHORT_LO,
  3: ROLE_NAME.WSDC_SHORT_DPM,
  4: ROLE_NAME.WSDC_SHORT_DLO,
  5: ROLE_NAME.WSDC_SHORT_WG,
  6: ROLE_NAME.WSDC_SHORT_WO,
  7: ROLE_NAME.WSDC_SHORT_LOR,
  8: ROLE_NAME.WSDC_SHORT_PMR,
};

export const WSDC_SHORT_ROLE_ARRAY: RoleInfo[] = [
  { name: ROLE_NAME.WSDC_SHORT_PM, side: SIDE.LEFT, num: 1, show_name_short: 'PM', show_name_long: 'Prime Minister' },
  { name: ROLE_NAME.WSDC_SHORT_LO, side: SIDE.RIGHT, num: 2, show_name_short: 'LO', show_name_long: 'Leader Opposition' },
  { name: ROLE_NAME.WSDC_SHORT_DPM, side: SIDE.LEFT, num: 3, show_name_short: 'DPM', show_name_long: 'Depty Prime Minister' },
  { name: ROLE_NAME.WSDC_SHORT_DLO, side: SIDE.RIGHT, num: 4, show_name_short: 'DLO', show_name_long: 'Depty Leader Opposition' },
  { name: ROLE_NAME.WSDC_SHORT_WG, side: SIDE.LEFT, num: 5, show_name_short: 'WG', show_name_long: 'Whip Government' },
  { name: ROLE_NAME.WSDC_SHORT_WO, side: SIDE.RIGHT, num: 6, show_name_short: 'WO', show_name_long: 'Whip Opposition' },
  { name: ROLE_NAME.WSDC_SHORT_LOR, side: SIDE.RIGHT, num: 7, show_name_short: 'LOR', show_name_long: 'Leader Opposition Reply' },
  { name: ROLE_NAME.WSDC_SHORT_PMR, side: SIDE.LEFT, num: 8, show_name_short: 'PMR', show_name_long: 'Prime Minister Reply' },
];

export const BP_ROLE_ORDER: { [key: number]: ROLE_NAME } = {
  1: ROLE_NAME.BP_PM,
  2: ROLE_NAME.BP_LO,
  3: ROLE_NAME.BP_DPM,
  4: ROLE_NAME.BP_DLO,
  5: ROLE_NAME.BP_MG,
  6: ROLE_NAME.BP_MO,
  7: ROLE_NAME.BP_GW,
  8: ROLE_NAME.BP_OW,
};

export const BP_ROLE_ARRAY: RoleInfo[] = [
  { name: ROLE_NAME.BP_PM, side: SIDE.LEFT, num: 1, show_name_short: 'PM', show_name_long: 'Prime Minister' },
  { name: ROLE_NAME.BP_LO, side: SIDE.RIGHT, num: 2, show_name_short: 'LO', show_name_long: 'Leader Opposition' },
  { name: ROLE_NAME.BP_DPM, side: SIDE.LEFT, num: 3, show_name_short: 'DPM', show_name_long: 'Depty Prime Minister' },
  { name: ROLE_NAME.BP_DLO, side: SIDE.RIGHT, num: 4, show_name_short: 'DLO', show_name_long: 'Depty Leader Opposition' },
  { name: ROLE_NAME.BP_MG, side: SIDE.LEFT, num: 5, show_name_short: 'MG', show_name_long: 'Member Government' },
  { name: ROLE_NAME.BP_MO, side: SIDE.RIGHT, num: 6, show_name_short: 'MO', show_name_long: 'Member Opposition' },
  { name: ROLE_NAME.BP_GW, side: SIDE.LEFT, num: 7, show_name_short: 'GW', show_name_long: 'Government Whip' },
  { name: ROLE_NAME.BP_OW, side: SIDE.RIGHT, num: 8, show_name_short: 'OW', show_name_long: 'Opposition Whip' },
];

export const PHS_ROLE_ORDER: { [key: number]: ROLE_NAME } = {
  1: ROLE_NAME.PHS_PM,
  2: ROLE_NAME.PHS_LO,
  3: ROLE_NAME.PHS_DPM,
  4: ROLE_NAME.PHS_DLO,
  5: ROLE_NAME.PHS_MG,
  6: ROLE_NAME.PHS_MO,
  7: ROLE_NAME.PHS_GW,
  8: ROLE_NAME.PHS_OW,
};

export const PHS_ROLE_ARRAY: RoleInfo[] = [
  { name: ROLE_NAME.PHS_PM, side: SIDE.LEFT, num: 1, show_name_short: 'PM', show_name_long: 'Prime Minister' },
  { name: ROLE_NAME.PHS_LO, side: SIDE.RIGHT, num: 2, show_name_short: 'LO', show_name_long: 'Leader Opposition' },
  { name: ROLE_NAME.PHS_DPM, side: SIDE.LEFT, num: 3, show_name_short: 'DPM', show_name_long: 'Depty Prime Minister' },
  { name: ROLE_NAME.PHS_DLO, side: SIDE.RIGHT, num: 4, show_name_short: 'DLO', show_name_long: 'Depty Leader Opposition' },
  { name: ROLE_NAME.PHS_MG, side: SIDE.LEFT, num: 5, show_name_short: 'MG', show_name_long: 'Member Government' },
  { name: ROLE_NAME.PHS_MO, side: SIDE.RIGHT, num: 6, show_name_short: 'MO', show_name_long: 'Member Opposition' },
  { name: ROLE_NAME.PHS_GW, side: SIDE.LEFT, num: 7, show_name_short: 'GW', show_name_long: 'Government Whip' },
  { name: ROLE_NAME.PHS_OW, side: SIDE.RIGHT, num: 8, show_name_short: 'OW', show_name_long: 'Opposition Whip' },
];

export const ROLE_LONG_SHOWNAME_MAPPING_ALL = {
  [ROLE_NAME.NA_PM]: 'Prime Minister',
  [ROLE_NAME.NA_LO]: 'Leader Opposition',
  [ROLE_NAME.NA_MG]: 'Member Government',
  [ROLE_NAME.NA_MO]: 'Member Opposition',
  [ROLE_NAME.NA_PMR]: 'Prime Minister Reply',
  [ROLE_NAME.NA_LOR]: 'Leader Opposition Reply',
  [ROLE_NAME.PDA_PM]: 'Prime Minister',
  [ROLE_NAME.PDA_LO]: 'Leader Opposition',
  [ROLE_NAME.PDA_MG]: 'Member Government',
  [ROLE_NAME.PDA_MO]: 'Member Opposition',
  [ROLE_NAME.PDA_PMR]: 'Prime Minister Reply',
  [ROLE_NAME.PDA_LOR]: 'Leader Opposition Reply',
  [ROLE_NAME.WSC_PM]: 'Prime Minister',
  [ROLE_NAME.WSC_LO]: 'Leader Opposition',
  [ROLE_NAME.WSC_MG]: 'Member Government',
  [ROLE_NAME.WSC_MO]: 'Member Opposition',
  [ROLE_NAME.WSC_PMR]: 'Prime Minister Reply',
  [ROLE_NAME.WSC_LOR]: 'Leader Opposition Reply',
  [ROLE_NAME.ASIAN_PM]: 'Prime Minister',
  [ROLE_NAME.ASIAN_LO]: 'Leader Opposition',
  [ROLE_NAME.ASIAN_DPM]: 'Depty Prime Minister',
  [ROLE_NAME.ASIAN_DLO]: 'Depty Leader Opposition',
  [ROLE_NAME.ASIAN_WG]: 'Whip Government',
  [ROLE_NAME.ASIAN_WO]: 'Whip Opposition',
  [ROLE_NAME.ASIAN_PMR]: 'Prime Minister Reply',
  [ROLE_NAME.ASIAN_LOR]: 'Leader Opposition Reply',
  [ROLE_NAME.WSDC_PM]: 'Prime Minister',
  [ROLE_NAME.WSDC_LO]: 'Leader Opposition',
  [ROLE_NAME.WSDC_DPM]: 'Depty Prime Minister',
  [ROLE_NAME.WSDC_DLO]: 'Depty Leader Opposition',
  [ROLE_NAME.WSDC_WG]: 'Whip Government',
  [ROLE_NAME.WSDC_WO]: 'Whip Opposition',
  [ROLE_NAME.WSDC_PMR]: 'Prime Minister Reply',
  [ROLE_NAME.WSDC_LOR]: 'Leader Opposition Reply',
  [ROLE_NAME.WSDC_SHORT_PM]: 'Prime Minister',
  [ROLE_NAME.WSDC_SHORT_LO]: 'Leader Opposition',
  [ROLE_NAME.WSDC_SHORT_DPM]: 'Depty Prime Minister',
  [ROLE_NAME.WSDC_SHORT_DLO]: 'Depty Leader Opposition',
  [ROLE_NAME.WSDC_SHORT_WG]: 'Whip Government',
  [ROLE_NAME.WSDC_SHORT_WO]: 'Whip Opposition',
  [ROLE_NAME.WSDC_SHORT_PMR]: 'Prime Minister Reply',
  [ROLE_NAME.WSDC_SHORT_LOR]: 'Leader Opposition Reply',
  [ROLE_NAME.BP_PM]: 'Prime Minister',
  [ROLE_NAME.BP_LO]: 'Leader Opposition',
  [ROLE_NAME.BP_DPM]: 'Depty Prime Minister',
  [ROLE_NAME.BP_DLO]: 'Depty Leader Opposition',
  [ROLE_NAME.BP_MG]: 'Member Government',
  [ROLE_NAME.BP_MO]: 'Member Opposition',
  [ROLE_NAME.BP_GW]: 'Government Whip',
  [ROLE_NAME.BP_OW]: 'Opposition Whip',
  [ROLE_NAME.PHS_PM]: 'Prime Minister',
  [ROLE_NAME.PHS_LO]: 'Leader Opposition',
  [ROLE_NAME.PHS_DPM]: 'Depty Prime Minister',
  [ROLE_NAME.PHS_DLO]: 'Depty Leader Opposition',
  [ROLE_NAME.PHS_MG]: 'Member Government',
  [ROLE_NAME.PHS_MO]: 'Member Opposition',
  [ROLE_NAME.PHS_GW]: 'Government Whip',
  [ROLE_NAME.PHS_OW]: 'Opposition Whip',
  [ROLE_NAME.COMPLETE]: 'COMPLETE',
  [ROLE_NAME.GENERAL]: 'General Comment / Reason For Decision',
};

export const ROLE_SHORT_SHOWNAME_MAPPING_ALL = {
  [ROLE_NAME.NA_PM]: 'PM',
  [ROLE_NAME.NA_LO]: 'LO',
  [ROLE_NAME.NA_MG]: 'MG',
  [ROLE_NAME.NA_MO]: 'MO',
  [ROLE_NAME.NA_LOR]: 'LOR',
  [ROLE_NAME.NA_PMR]: 'PMR',
  [ROLE_NAME.PDA_PM]: 'PM',
  [ROLE_NAME.PDA_LO]: 'LO',
  [ROLE_NAME.PDA_MG]: 'MG',
  [ROLE_NAME.PDA_MO]: 'MO',
  [ROLE_NAME.PDA_LOR]: 'LOR',
  [ROLE_NAME.PDA_PMR]: 'PMR',
  [ROLE_NAME.WSC_PM]: 'Aff1',
  [ROLE_NAME.WSC_LO]: 'Neg1',
  [ROLE_NAME.WSC_MG]: 'Aff2',
  [ROLE_NAME.WSC_MO]: 'Neg2',
  [ROLE_NAME.WSC_LOR]: 'Neg3',
  [ROLE_NAME.WSC_PMR]: 'Aff3',
  [ROLE_NAME.ASIAN_PM]: 'PM',
  [ROLE_NAME.ASIAN_LO]: 'LO',
  [ROLE_NAME.ASIAN_DPM]: 'DPM',
  [ROLE_NAME.ASIAN_DLO]: 'DLO',
  [ROLE_NAME.ASIAN_WG]: 'WG',
  [ROLE_NAME.ASIAN_WO]: 'WO',
  [ROLE_NAME.ASIAN_LOR]: 'LOR',
  [ROLE_NAME.ASIAN_PMR]: 'PMR',
  [ROLE_NAME.WSDC_PM]: 'PM',
  [ROLE_NAME.WSDC_LO]: 'LO',
  [ROLE_NAME.WSDC_DPM]: 'DPM',
  [ROLE_NAME.WSDC_DLO]: 'DLO',
  [ROLE_NAME.WSDC_WG]: 'WG',
  [ROLE_NAME.WSDC_WO]: 'WO',
  [ROLE_NAME.WSDC_LOR]: 'LOR',
  [ROLE_NAME.WSDC_PMR]: 'PMR',
  [ROLE_NAME.WSDC_SHORT_PM]: 'PM',
  [ROLE_NAME.WSDC_SHORT_LO]: 'LO',
  [ROLE_NAME.WSDC_SHORT_DPM]: 'DPM',
  [ROLE_NAME.WSDC_SHORT_DLO]: 'DLO',
  [ROLE_NAME.WSDC_SHORT_WG]: 'WG',
  [ROLE_NAME.WSDC_SHORT_WO]: 'WO',
  [ROLE_NAME.WSDC_SHORT_LOR]: 'LOR',
  [ROLE_NAME.WSDC_SHORT_PMR]: 'PMR',
  [ROLE_NAME.BP_PM]: 'PM',
  [ROLE_NAME.BP_LO]: 'LO',
  [ROLE_NAME.BP_DPM]: 'DPM',
  [ROLE_NAME.BP_DLO]: 'DLO',
  [ROLE_NAME.BP_MG]: 'MG',
  [ROLE_NAME.BP_MO]: 'MO',
  [ROLE_NAME.BP_GW]: 'GW',
  [ROLE_NAME.BP_OW]: 'OW',
  [ROLE_NAME.PHS_PM]: 'PM',
  [ROLE_NAME.PHS_LO]: 'LO',
  [ROLE_NAME.PHS_DPM]: 'DPM',
  [ROLE_NAME.PHS_DLO]: 'DLO',
  [ROLE_NAME.PHS_MG]: 'MG',
  [ROLE_NAME.PHS_MO]: 'MO',
  [ROLE_NAME.PHS_GW]: 'GW',
  [ROLE_NAME.PHS_OW]: 'OW',
  [ROLE_NAME.COMPLETE]: 'COMPLETE',
  [ROLE_NAME.GENERAL]: 'General Comment / Reason For Decision',
};

export const ROLE_SIDE_MAPPING = {
  [ROLE_NAME.NA_PM]: SIDE.LEFT,
  [ROLE_NAME.NA_LO]: SIDE.RIGHT,
  [ROLE_NAME.NA_MG]: SIDE.LEFT,
  [ROLE_NAME.NA_MO]: SIDE.RIGHT,
  [ROLE_NAME.NA_PMR]: SIDE.LEFT,
  [ROLE_NAME.NA_LOR]: SIDE.RIGHT,
  [ROLE_NAME.PDA_PM]: SIDE.LEFT,
  [ROLE_NAME.PDA_LO]: SIDE.RIGHT,
  [ROLE_NAME.PDA_MG]: SIDE.LEFT,
  [ROLE_NAME.PDA_MO]: SIDE.RIGHT,
  [ROLE_NAME.PDA_PMR]: SIDE.LEFT,
  [ROLE_NAME.PDA_LOR]: SIDE.RIGHT,
  [ROLE_NAME.WSC_PM]: SIDE.LEFT,
  [ROLE_NAME.WSC_LO]: SIDE.RIGHT,
  [ROLE_NAME.WSC_MG]: SIDE.LEFT,
  [ROLE_NAME.WSC_MO]: SIDE.RIGHT,
  [ROLE_NAME.WSC_PMR]: SIDE.LEFT,
  [ROLE_NAME.WSC_LOR]: SIDE.RIGHT,
  [ROLE_NAME.ASIAN_PM]: SIDE.LEFT,
  [ROLE_NAME.ASIAN_LO]: SIDE.RIGHT,
  [ROLE_NAME.ASIAN_DPM]: SIDE.LEFT,
  [ROLE_NAME.ASIAN_DLO]: SIDE.RIGHT,
  [ROLE_NAME.ASIAN_WG]: SIDE.LEFT,
  [ROLE_NAME.ASIAN_WO]: SIDE.RIGHT,
  [ROLE_NAME.ASIAN_PMR]: SIDE.LEFT,
  [ROLE_NAME.ASIAN_LOR]: SIDE.RIGHT,
  [ROLE_NAME.WSDC_PM]: SIDE.LEFT,
  [ROLE_NAME.WSDC_LO]: SIDE.RIGHT,
  [ROLE_NAME.WSDC_DPM]: SIDE.LEFT,
  [ROLE_NAME.WSDC_DLO]: SIDE.RIGHT,
  [ROLE_NAME.WSDC_WG]: SIDE.LEFT,
  [ROLE_NAME.WSDC_WO]: SIDE.RIGHT,
  [ROLE_NAME.WSDC_PMR]: SIDE.LEFT,
  [ROLE_NAME.WSDC_LOR]: SIDE.RIGHT,
  [ROLE_NAME.WSDC_SHORT_PM]: SIDE.LEFT,
  [ROLE_NAME.WSDC_SHORT_LO]: SIDE.RIGHT,
  [ROLE_NAME.WSDC_SHORT_DPM]: SIDE.LEFT,
  [ROLE_NAME.WSDC_SHORT_DLO]: SIDE.RIGHT,
  [ROLE_NAME.WSDC_SHORT_WG]: SIDE.LEFT,
  [ROLE_NAME.WSDC_SHORT_WO]: SIDE.RIGHT,
  [ROLE_NAME.WSDC_SHORT_PMR]: SIDE.LEFT,
  [ROLE_NAME.WSDC_SHORT_LOR]: SIDE.RIGHT,
  [ROLE_NAME.BP_PM]: SIDE.LEFT,
  [ROLE_NAME.BP_LO]: SIDE.RIGHT,
  [ROLE_NAME.BP_DPM]: SIDE.LEFT,
  [ROLE_NAME.BP_DLO]: SIDE.RIGHT,
  [ROLE_NAME.BP_MG]: SIDE.LEFT,
  [ROLE_NAME.BP_MO]: SIDE.RIGHT,
  [ROLE_NAME.BP_GW]: SIDE.LEFT,
  [ROLE_NAME.BP_OW]: SIDE.RIGHT,
  [ROLE_NAME.PHS_PM]: SIDE.LEFT,
  [ROLE_NAME.PHS_LO]: SIDE.RIGHT,
  [ROLE_NAME.PHS_DPM]: SIDE.LEFT,
  [ROLE_NAME.PHS_DLO]: SIDE.RIGHT,
  [ROLE_NAME.PHS_MG]: SIDE.LEFT,
  [ROLE_NAME.PHS_MO]: SIDE.RIGHT,
  [ROLE_NAME.PHS_GW]: SIDE.LEFT,
  [ROLE_NAME.PHS_OW]: SIDE.RIGHT,
};
