import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-btn-circle',
  templateUrl: './btn-circle.component.html',
  styleUrls: ['./btn-circle.component.scss'],
})
export class BtnCircleComponent implements OnInit {
  @Input()
  size: 'small' | 'medium' = 'medium';
  @Input()
  color: '' | 'white';
  @Output() onClick = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  get modifier_class() {
    return ['small' === this.size ? '-small' : '', this.color ? `-${this.color}` : ''];
  }

  on_click() {
    console.log('onClick');
    this.onClick.emit();
  }
}
