<div style="margin: 24px">
  <button type="button" class="close manual-close" (click)="hide_login_modal()">
    <span aria-hidden="true">&times;</span>
  </button>
  <h2 class="h4">{{ title }}</h2>

  <ng-container *ngIf="google_slide_url">
    <iframe
      [src]="google_slide_url"
      frameborder="0"
      width="{{ width }}"
      height="{{ height }}"
      allowfullscreen="true"
      mozallowfullscreen="true"
      webkitallowfullscreen="true"
    >
    </iframe>
  </ng-container>
</div>
