export const USER_AUDIO_STATUS_UNMUTE = 'USER_AUDIO_STATUS_UNMUTE';
export const USER_AUDIO_STATUS_MUTE = 'USER_AUDIO_STATUS_MUTE';

export enum USER_AUDIO_STATUS {
  MUTE = 'MUTE',
  UNDER_TRANSITION = 'UNDER_TRANSITION',
  UNMUTE = 'UNMUTE',
}

export enum USER_VIDEO_STATUS {
  MUTE = 'MUTE',
  UNDER_TRANSITION = 'UNDER_TRANSITION',
  UNMUTE = 'UNMUTE',
  HIDDEN_DEACTIVATED = 'HIDDEN_DEACTIVATED',
}
