export enum USERSHOW_IMAGESHAPE {
  CIRCLE = 'CIRCLE',
  RECTANGLE = 'RECTANGLE',
}

export enum USERSHOW_NAME_TYPE {
  FULL = 'FULL',
  SHORT = 'SHORT',
}

export enum USERSHOW_ORDER_ICON_NAME {
  LEFTICON_RIGHTNAME = 'LEFTICON_RIGHTNAME',
  LEFTNAME_RIGHTICON = 'LEFTNAME_RIGHTICON',
}
