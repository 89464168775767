import { GROUP_MEMBER_TYPE } from '../constant/group-member-data';
import { GROUP_MEMBERADD_PRIVILEGE } from '../constant/group-member-data';
import { EventData } from './event-data';
import { EventDataWithLectureTimeDateConvertedWithId } from './lecture';
import firebase from 'firebase/compat/app';

export interface GroupType {
  main: string;
  sub?: string;
}

export interface GoogleStorageImageRef {
  bucket: string;
  file_path: string;
}

type url_src = string;

type user_id = string;

export interface GroupData {
  group_name?: string;
  group_type?: GroupType;
  group_sub_type?: string;
  img_url_cover?: url_src;
  img_url_cover_icon?: url_src;
  img_url_cover_pc?: url_src;
  img_url_cover_sp?: url_src;
  img_url_emblem?: url_src;
  img_url_emblem_icon?: url_src;
  img_url_emblem_pc?: url_src;
  img_url_emblem_sp?: url_src;
  description?: string;
  country?: string;
  group_memberadd_privilege?: GROUP_MEMBERADD_PRIVILEGE;
  icon_src?: string;
  member_arr?: user_id[];
  administrator_arr?: user_id[];
  member_requested_arr?: user_id[];
  number_of_members?: number;
  member_requested_number?: number;
  creator_arr?: user_id[]; //assume it has only one element in array

  eventlist?: EventDataWithLectureTimeDateConvertedWithId[]; // possibly cyclic injection

  group_id?: string; // only for backward compatibility for realtime db group
  event_last_updated?: firebase.firestore.Timestamp;
}

export interface GroupDataVm extends GroupData {
  is_creator: boolean;
  is_administrator: boolean;
  is_member: boolean;
  is_member_requested: boolean;
}

export interface MemberData {
  type: GROUP_MEMBER_TYPE;
  status: boolean; // this data is only for query
}

export interface GroupDataWithId extends GroupData {
  show_member_requested?: boolean;
  id?: string;
}

export interface GroupDataMineAndOthers {
  my_group: GroupDataWithId[];
  other_group: GroupDataWithId[];
}

export interface GroupMember {
  added_by?: string; // member
  assigned_by?: string; // administrator
  join_timestamp?: firebase.firestore.Timestamp;
  request_timestamp?: firebase.firestore.Timestamp;
}

/*

subcollection
 administrator
 　作成
    administratorかcreator

  削除
   追加した人かクリエイター
　      allow delete : if get(/databases/$(database)/documents/group/$(groupId).creator) == request.auth.uid
      	|| resource.data.added_by == request.auth.uid

  変数をobjectの内部データにアクセスするのに用いることができなそう
  　get(/databases/$(database)/documents/group/$(groupId).creator.($request.auth.uid)) はできない

 member
  requested
 　
*/

export interface GroupMemberWithId extends GroupMember {
  id?: string;
}

export enum GROUP_CONTEXT_TYPE {
  POST = 'CONTEXT_TYPE_POST',
  EVENT = 'CONTEXT_TYPE_EVENT',
  MEMBERS = 'CONTEXT_TYPE_MEMBERS',
  ARTICLE = 'CONTEXT_TYPE_ARTICLE',
}
