<div class="link-area" (click)="goto_study_service()">
  <div class="ctnr">
    <div class="text-ctnr">
      ディベート<br />
      学習サイト
    </div>
    <div class="arrow-ctnr">
      <i class="ml-2 fas fa-caret-right"></i>
    </div>
  </div>
</div>
