import { TEAM_NAME } from './team-enum';

import { ROLE_NAME } from './role';

export const ROLE_TEAM_MAPPING_ALL: { [key: string]: TEAM_NAME } = {
  [ROLE_NAME.NA_PM]: TEAM_NAME.NA_GOV,
  [ROLE_NAME.NA_LO]: TEAM_NAME.NA_OPP,
  [ROLE_NAME.NA_MG]: TEAM_NAME.NA_GOV,
  [ROLE_NAME.NA_MO]: TEAM_NAME.NA_OPP,
  [ROLE_NAME.NA_PMR]: TEAM_NAME.NA_GOV,
  [ROLE_NAME.NA_LOR]: TEAM_NAME.NA_OPP,
  [ROLE_NAME.PDA_PM]: TEAM_NAME.PDA_GOV,
  [ROLE_NAME.PDA_LO]: TEAM_NAME.PDA_OPP,
  [ROLE_NAME.PDA_MG]: TEAM_NAME.PDA_GOV,
  [ROLE_NAME.PDA_MO]: TEAM_NAME.PDA_OPP,
  [ROLE_NAME.PDA_PMR]: TEAM_NAME.PDA_GOV,
  [ROLE_NAME.PDA_LOR]: TEAM_NAME.PDA_OPP,
  [ROLE_NAME.WSC_PM]: TEAM_NAME.WSC_GOV,
  [ROLE_NAME.WSC_LO]: TEAM_NAME.WSC_OPP,
  [ROLE_NAME.WSC_MG]: TEAM_NAME.WSC_GOV,
  [ROLE_NAME.WSC_MO]: TEAM_NAME.WSC_OPP,
  [ROLE_NAME.WSC_PMR]: TEAM_NAME.WSC_GOV,
  [ROLE_NAME.WSC_LOR]: TEAM_NAME.WSC_OPP,
  [ROLE_NAME.ASIAN_PM]: TEAM_NAME.ASIAN_PROPOSITION,
  [ROLE_NAME.ASIAN_LO]: TEAM_NAME.ASIAN_OPPOSITION,
  [ROLE_NAME.ASIAN_DPM]: TEAM_NAME.ASIAN_PROPOSITION,
  [ROLE_NAME.ASIAN_DLO]: TEAM_NAME.ASIAN_OPPOSITION,
  [ROLE_NAME.ASIAN_WG]: TEAM_NAME.ASIAN_PROPOSITION,
  [ROLE_NAME.ASIAN_WO]: TEAM_NAME.ASIAN_OPPOSITION,
  [ROLE_NAME.ASIAN_PMR]: TEAM_NAME.ASIAN_PROPOSITION,
  [ROLE_NAME.ASIAN_LOR]: TEAM_NAME.ASIAN_OPPOSITION,
  [ROLE_NAME.WSDC_PM]: TEAM_NAME.WSDC_PROPOSITION,
  [ROLE_NAME.WSDC_LO]: TEAM_NAME.WSDC_OPPOSITION,
  [ROLE_NAME.WSDC_DPM]: TEAM_NAME.WSDC_PROPOSITION,
  [ROLE_NAME.WSDC_DLO]: TEAM_NAME.WSDC_OPPOSITION,
  [ROLE_NAME.WSDC_WG]: TEAM_NAME.WSDC_PROPOSITION,
  [ROLE_NAME.WSDC_WO]: TEAM_NAME.WSDC_OPPOSITION,
  [ROLE_NAME.WSDC_PMR]: TEAM_NAME.WSDC_PROPOSITION,
  [ROLE_NAME.WSDC_LOR]: TEAM_NAME.WSDC_OPPOSITION,
  [ROLE_NAME.WSDC_SHORT_PM]: TEAM_NAME.WSDC_SHORT_PROPOSITION,
  [ROLE_NAME.WSDC_SHORT_LO]: TEAM_NAME.WSDC_SHORT_OPPOSITION,
  [ROLE_NAME.WSDC_SHORT_DPM]: TEAM_NAME.WSDC_SHORT_PROPOSITION,
  [ROLE_NAME.WSDC_SHORT_DLO]: TEAM_NAME.WSDC_SHORT_OPPOSITION,
  [ROLE_NAME.WSDC_SHORT_WG]: TEAM_NAME.WSDC_SHORT_PROPOSITION,
  [ROLE_NAME.WSDC_SHORT_WO]: TEAM_NAME.WSDC_SHORT_OPPOSITION,
  [ROLE_NAME.WSDC_SHORT_PMR]: TEAM_NAME.WSDC_SHORT_PROPOSITION,
  [ROLE_NAME.WSDC_SHORT_LOR]: TEAM_NAME.WSDC_SHORT_OPPOSITION,
  [ROLE_NAME.BP_PM]: TEAM_NAME.BP_OG,
  [ROLE_NAME.BP_LO]: TEAM_NAME.BP_OO,
  [ROLE_NAME.BP_DPM]: TEAM_NAME.BP_OG,
  [ROLE_NAME.BP_DLO]: TEAM_NAME.BP_OO,
  [ROLE_NAME.BP_MG]: TEAM_NAME.BP_CG,
  [ROLE_NAME.BP_MO]: TEAM_NAME.BP_CO,
  [ROLE_NAME.BP_GW]: TEAM_NAME.BP_CG,
  [ROLE_NAME.BP_OW]: TEAM_NAME.BP_CO,
  [ROLE_NAME.PHS_PM]: TEAM_NAME.PHS_PROPOSITION,
  [ROLE_NAME.PHS_LO]: TEAM_NAME.PHS_OPPOSITION,
  [ROLE_NAME.PHS_DPM]: TEAM_NAME.PHS_PROPOSITION,
  [ROLE_NAME.PHS_DLO]: TEAM_NAME.PHS_OPPOSITION,
  [ROLE_NAME.PHS_MG]: TEAM_NAME.PHS_PROPOSITION,
  [ROLE_NAME.PHS_MO]: TEAM_NAME.PHS_OPPOSITION,
  [ROLE_NAME.PHS_GW]: TEAM_NAME.PHS_PROPOSITION,
  [ROLE_NAME.PHS_OW]: TEAM_NAME.PHS_OPPOSITION,
};
