import { Component, OnInit, Inject } from '@angular/core';

import { LS_KEY_USER_NAME_SET, UserNameInputData } from '@mixidea-client/model';

import { FirebaseRtdApi } from '@mixidea-client/firebase-rtd-lib';

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { get_local_namelabel } from '@mixidea-client/model';

@Component({
  selector: 'app-set-username-modal',
  templateUrl: './set-username-modal.component.html',
  styleUrls: ['./set-username-modal.component.scss'],
})
export class SetUsernameModalComponent implements OnInit {
  firstName = '';
  lastName = '';
  firstNameLocalLabel = '';
  firstNameLocal = '';
  lastNameLocalLabel = '';
  lastNameLocal = '';
  own_userid = '';

  // https://www.metamodpro.com/browser-language-codes

  constructor(
    public dialogRef: MatDialogRef<SetUsernameModalComponent>,
    private firebaseRtdApi: FirebaseRtdApi,
    @Inject(MAT_DIALOG_DATA) public data: UserNameInputData,
  ) {
    this.own_userid = data.own_userid;
  }

  ngOnInit() {
    const local_name = get_local_namelabel();
    this.firstNameLocalLabel = local_name.firstNameLocalLabel;
    this.lastNameLocalLabel = local_name.lastNameLocalLabel;
  }

  save() {
    if (!this.firstName || !this.lastName) {
      alert('please input both first name and last name');
      return;
    }

    this.firebaseRtdApi
      .setUserInputName(this.own_userid, this.firstName, this.lastName, this.firstNameLocal, this.lastNameLocal)
      .then(() => {
        this.dialogRef.close();
        localStorage.setItem(LS_KEY_USER_NAME_SET, 'true');
      })
      .catch((err) => {
        alert('saving data failed, please try again');
      });
  }
}
