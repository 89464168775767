import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IndexedDbService } from './service/indexed-db.service';
import { IndexedDbApi } from './indexed-db-api';

@NgModule({
  imports: [CommonModule],
  providers: [IndexedDbService, IndexedDbApi],
})
export class IndexedDbLibModule {}
