import { TEAM_NAME } from '@mixidea-client/model';

// export const ParticipateGoing = 'ParticipateGoing';
// export const ParticipateMaybe = 'ParticipateMaybe';
// export const ParticipateCannotgo = 'ParticipateCannotgo';
// export const ParticipateInvited = 'ParticipateInvited';
// export const ParticipateNoInfo = 'ParticipateNoInfo';
// export const ParticipateProposition = TEAM_NAME.PROPOSITION;
// export const ParticipateOpposition = TEAM_NAME.OPPOSITION;

export enum Participate {
  Going = 'ParticipateGoing',
  Maybe = 'ParticipateMaybe',
  Cannotgo = 'ParticipateCannotgo',
  Invited = 'ParticipateInvited',
  NoInfo = 'ParticipateNoInfo',
  Proposition = 'proposition',
  Opposition = 'opposition',
}
