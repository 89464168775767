import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { from } from 'rxjs';
import { ReplaySubject } from 'rxjs';
import { map, tap, take } from 'rxjs/operators';
import { of } from 'rxjs';
// import 'rxjs/add/operator/scan';

import { FirebaseRtdApi } from '@mixidea-client/firebase-rtd-lib';
import { UserBasicVm, BLANK_USER_DATA, BLANK_USER_DATA_RECORDER } from '@mixidea-client/model';
import { IndexedDbApi } from '@mixidea-client/indexed-db-lib';

export const USER_MODEL_DELETED_ONCE = 'USER_MODEL_DELETED_ONCE';

@Injectable()
export class ModelUserService {
  private user_store: { [key: string]: UserBasicVm } = {};
  private user_under_retrieve: { [key: string]: boolean } = {};
  private user_temporl_subject: { [key: string]: ReplaySubject<UserBasicVm> } = {};
  private timeout: { [key: string]: any } = {};

  // private source : Subject<any>

  constructor(private firebaseRtdApi: FirebaseRtdApi, private indexedDb: IndexedDbApi) {
    const deleted_once = sessionStorage.getItem(USER_MODEL_DELETED_ONCE);
    if (!deleted_once) {
      this.remove_all_users();
      sessionStorage.setItem(USER_MODEL_DELETED_ONCE, 'true');
    }
  }

  get_user$(userid: string): Observable<UserBasicVm> {
    if (!userid) {
      return of(BLANK_USER_DATA);
    } else if (this.user_store[userid]) {
      // console.log(`%c get_user$: stored data used ${userid}`,'color: green' )
      return of(this.user_store[userid]);
    } else if (this.user_under_retrieve[userid]) {
      // console.log('get_user$: temporal subject used', userid)
      if (!this.timeout[userid]) {
        this.timeout[userid] = setTimeout(() => {
          // console.log('data retrieval timeout error');
          if (this.user_temporl_subject[userid] && !this.user_temporl_subject[userid].isStopped) {
            this.user_temporl_subject[userid].next(BLANK_USER_DATA);
            this.user_temporl_subject[userid].complete();
          }
          if (this.user_under_retrieve[userid]) {
            this.user_under_retrieve[userid] = false;
          }
          delete this.timeout[userid];
        }, 20 * 1000);
      }
      if (this.user_temporl_subject[userid] && !this.user_temporl_subject[userid].isStopped) {
        return this.user_temporl_subject[userid];
      } else {
        this.user_temporl_subject[userid] = new ReplaySubject(1);

        // console.log(`%c temporal user subject has set ${userid}`, 'color:green');
        return this.user_temporl_subject[userid];
      }
    } else {
      this.user_under_retrieve[userid] = true;
      // console.log('get_user$: retrieve data from server', userid);
      return from(
        this.indexedDb.retrieve_users(userid).then((user_data_idb: UserBasicVm) => {
          if (user_data_idb) {
            // console.log(`%c indexedDb.retrieve_users: YES ${JSON.stringify(user_data_idb)}`, 'color:green');
            this.store_and_clear_timeout(userid, user_data_idb);
            return user_data_idb;
          } else {
            // console.log(`%c indexedDb.retrieve_users: NO`,  'color:green');
            return this.firebaseRtdApi
              .getUserBasic(userid)
              .pipe(
                take(1),
                map((user_data_vm: UserBasicVm) => {
                  // console.log(user_data_vm);
                  if (user_data_vm.full_name && user_data_vm.short_name && user_data_vm.pict_src) {
                    // console.log(`%c indexedDb.store_users, ${user_data_vm}`);
                    this.indexedDb.store_users(userid, user_data_vm);
                  }
                  let user_data: UserBasicVm = {
                    full_name: user_data_vm.full_name || user_data_vm.twitter_name || BLANK_USER_DATA.full_name,
                    short_name: user_data_vm.short_name || user_data_vm.twitter_name || BLANK_USER_DATA.short_name,
                    pict_src: user_data_vm.pict_src || BLANK_USER_DATA.pict_src,
                    fb_id: user_data_vm.fb_id || BLANK_USER_DATA.pict_src,
                  };
                  if (Object.keys(user_data_vm).length === 0) {
                    user_data = BLANK_USER_DATA_RECORDER;
                  }
                  this.store_and_clear_timeout(userid, user_data);
                  return user_data;
                }),
              )
              .toPromise();
          }
        }),
      );
    }
  }

  private store_and_clear_timeout(userid: string, user_data_vm: UserBasicVm) {
    // console.log(`%c get_user$: data retrieval succeed`, 'color: green');
    this.user_store[userid] = user_data_vm;
    if (this.user_temporl_subject[userid] && !this.user_temporl_subject[userid].isStopped) {
      this.user_temporl_subject[userid].next(user_data_vm);
      this.user_temporl_subject[userid].complete();
      // console.log(`%c user_temporl_subject completed ${userid}`, 'color: green');
      // console.log('temporla subject completed with success', userid);
      // console.log('get_user$: temporal subject respod');
    }
    if (this.user_under_retrieve[userid]) {
      this.user_under_retrieve[userid] = false;
    }
    if (this.timeout[userid]) {
      clearTimeout(this.timeout[userid]);
      delete this.timeout[userid];
    }
  }

  get_user_stored(userid: string) {
    if (this.user_store[userid]) {
      return this.user_store[userid];
    }
    return BLANK_USER_DATA;
  }

  remove_all_users() {
    this.indexedDb.remove_all_users();
  }
}
