import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FirebaseRtdApi } from './firebase-rtd-api';
import { FirebaseRtdService } from './service/firebase-rtd.service';
import { LiveDebateFirebaseService } from './live-debate-firebase.service';
import { FirebaseRtdPureService } from './service/firebase-rtd-pure.service';
import { AuditriumFirebaseService } from './auditrium-firebase.service';
import { JistiFirebaseService } from './jisti-firebase.service';
import { FirebaseStorageService } from './service/firebase-storage.service';
import { FirebaseStoragedApi } from './firebase-storage-api';

@NgModule({
  imports: [CommonModule],
  providers: [
    FirebaseRtdApi,
    FirebaseRtdService,
    LiveDebateFirebaseService,
    FirebaseRtdPureService,
    FirebaseStorageService,
    FirebaseStoragedApi,
    AuditriumFirebaseService,
    JistiFirebaseService,
  ],
})
export class FirebaseRtdLibModule {}
