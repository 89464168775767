import { ARTICLETYPE } from '../constant/article-type';
import { ARTICLE_SHARED_RANGE } from './event-data';

import firebase from 'firebase/compat/app';

type userid = string;

export interface ArticleDb {
  title?: string;
  motion?: string;
  article_type?: ARTICLETYPE;
  timestamp?: firebase.firestore.Timestamp;
  participant?: userid[];
  article_shared_range?: ARTICLE_SHARED_RANGE;
}

export interface ArticleVm extends ArticleDb {
  date?: Date;
}

/*  this is for realtime db */
export interface ArticleDataWithKey extends ArticleVm {
  key: string;
}

/* this is for firestore */
export interface ArticleDbWithId extends ArticleDb {
  id: string;
}

export interface ArticleDataVmWithId extends ArticleVm {
  id: string;
}

// interface DateAndTimestamp extends firebase.firestore.Timestamp, Date {
// }
