const FIRST_NAME_CONVERT = {
  ja: '名前(任意)',
  ko: '이름(어떤)', // korea
  zh: '名字(任何)', // chinese
  vi: 'tên đầu tiên(Bất kỳ)', // vietnam
  // id: 'nama depan', // indonesia
  th: 'ชื่อจริง(ใด)', // thailand
  ar: 'الاسم الاول', // Arabic
};

const LAST_NAME_CONVERT = {
  ja: '苗字(任意)',
  ko: '성(어떤)', // korea
  zh: '姓(任何)', // chinese
  vi: 'họ(Bất kỳ)', // vietnam
  // id: '', // indonesia
  th: 'นามสกุล(ใด)', // thailand
  ar: 'الكنية', // Arabic
};

export interface LocalName {
  firstNameLocalLabel: string;
  lastNameLocalLabel: string;
}

export function get_local_namelabel(): LocalName {
  const language = (window.navigator.languages && window.navigator.languages[0]) || window.navigator.language || '';
  const language_two_words = language.slice(0, 2);

  return {
    firstNameLocalLabel: FIRST_NAME_CONVERT[language_two_words] || 'First name in Local Language (optional)',
    lastNameLocalLabel: LAST_NAME_CONVERT[language_two_words] || 'First name in Local Language (optional)',
  };
}
